import styled from 'styled-components';

export const CardsButtonGrid = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 25px -4px 0 -4px;
  position: relative;

  &.CardsButtonGridTurnOnDiscount {
    flex-wrap: wrap;

    & > div {
      max-width: 300px;
      flex: 0 0 calc(50% - 8px);
      &::before {
        border-color: #fca600;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
      &.isActive {
        svg {
          path {
            fill: #ff8412;
          }
        }
        span {
          color: #ff8412;
        }
      }
    }
  }

  &.CardsButtonGridNewDiscount {
    flex-wrap: wrap;

    & > div {
      max-width: 230px;
      flex: 0 0 calc(50% - 8px);
      &::before {
        border-color: #ff2d38;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
      &.isActive {
        svg {
          path {
            fill: #ff2d38;
          }
        }
        span {
          color: #ff2d38;
        }
      }
    }
  }

  &.CardsButtonGridSelectDiscount {
    flex-wrap: wrap;

    & > div {
      max-width: inherit;
      max-height: inherit;
      padding: 15px 15px 20px;
      flex: 0 0 calc(50% - 8px);
      @media (max-width: 599px) {
        min-width: 220px;
        flex: 0 0 calc(100% - 8px);
      }
      span {
        font-weight: bold;
        margin: 25px 0 20px;
        text-align: left;
        width: 100%;
      }
      &::before {
        border-color: #ff2d38;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
      &.isActive {
        svg {
          path {
            fill: #ff2d38;
          }
        }
        span {
          color: rgba(23, 201, 129, 1);
        }
      }
      .CardsButton {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        width: 100%;
        text-align: center;
      }
      .FlexContainerDiscountNew {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #929cb9;
        padding: 35px 6px;
      }

      .FlexContainerDiscount {
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 18px 0;
        position: relative;
        z-index: 10;

        &:nth-child(2) {
          border-top: 1px solid rgba(146, 156, 185, 0.2);
        }

        .FlexItemIcon {
          padding: 16px 20px 0 20px;
          flex: 0 0 auto;
        }
        .FlexItemTitle {
          flex: 0 0 auto;
          padding: 12px 18px 0 0;
          max-width: 89px;
        }
        input {
          flex: 1 1 auto;
          max-width: 130px;

          @media (max-width: 767px) {
            max-width: 100px;
          }
        }
        .FlexItem {
          position: relative;
          .ErrorSign {
            position: absolute;
            top: calc(100% + 1px);
            white-space: nowrap;
            text-align: right;
          }
        }
      }
    }
  }

  &.CardsButtonGridSelectDiscountV2 {
    flex-wrap: wrap;
    // gap: 16px;
    & > div {
      max-width: inherit;
      max-height: inherit;
      padding: 15px 15px 20px;
      flex: 1 1 calc(25% - 8px);
      align-items: stretch;
      justify-content: stretch;
      @media (max-width: 599px) {
        min-width: 220px;
        flex: 0 0 calc(100% - 8px);
      }
      span {
        font-weight: bold;
        margin: 25px 0 20px;
        text-align: left;
        width: 100%;
      }
      &::before {
        border-color: #ff2d38;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
      &.isActive {
        svg {
          path {
            fill: #ff2d38;
          }
        }
        span {
          color: rgba(23, 201, 129, 1);
        }
      }
      .CardsButton {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        width: 100%;
        text-align: center;
      }
      .FlexContainerDiscountBogo {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #929cb9;
        padding: 35px 6px;
      }
      .FlexContainerDiscount {
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 18px 0;
        position: relative;
        z-index: 10;

        &:nth-child(2) {
          border-top: 1px solid rgba(146, 156, 185, 0.2);
        }

        .FlexItemIcon {
          padding: 16px 20px 0 20px;
          flex: 0 0 auto;
        }
        .FlexItemTitle {
          flex: 0 0 auto;
          padding: 12px 18px 0 0;
          max-width: 89px;
        }
        input {
          flex: 1 1 auto;
          max-width: 130px;

          @media (max-width: 767px) {
            max-width: 100px;
          }
        }
        .FlexItem {
          position: relative;
          .ErrorSign {
            position: absolute;
            top: calc(100% + 1px);
            white-space: nowrap;
            text-align: right;
          }
        }
      }
    }
  }

  &.CardsButtonGridSoldOut {
    margin-top: 35px;

    & > div {
      min-width: inherit;
      max-width: inherit;
      flex: 0 0 calc(33.3333333% - 8px);
      min-height: 75px;
      padding: 18px;
      justify-content: center;

      &::before {
        border-color: #fca600;
      }

      span {
        margin-top: 0;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }

    &.mTop5 {
      margin-top: 5px;
    }

    &.mTop0 {
      margin-top: 0;
    }
  }
  &.CardsButtonGridOrange {
    & > div {
      min-width: inherit;
      max-width: inherit;
      flex: 0 0 calc(33.3333333% - 8px);
      min-height: 110px;
      padding: 18px;
      justify-content: center;
      @media (max-width: 600px) {
        min-height: 80px;
      }

      &::before {
        border-color: #fcd200;
      }

      span {
        margin-top: 0;
        font-weight: bold;
        font-size: 16px;
        color: #929cb9;
        line-height: 1.2;
        @media (max-width: 600px) {
          font-size: 14px;
        }
        @media (max-width: 500px) {
          font-size: 13px;
        }
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
      &.isActive {
        span {
          color: #fcd200;
        }
      }
    }

    &.mTop5 {
      margin-top: 5px;
    }

    &.mTop0 {
      margin-top: 0;
    }
  }

  &.CardsButtonGridRed {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;

    & > div {
      margin: 0;
      max-width: 234px;
      flex: 1 1 auto;
      &:nth-child(2) {
        margin: 0 18px;
      }
      span {
        margin-top: 10px;
      }
      &::before {
        border-color: rgba(255, 45, 56, 1);
      }

      &.isActive {
        span {
          color: rgba(255, 45, 56, 1);
        }
      }
    }
    .btnWithLogo {
      background: black;
      svg {
        path {
          fill: #f6f0ea;
        }
        path:first-child {
          fill: #06c167;
        }
      }
    }
  }
  &.flexStart {
    justify-content: flex-start;
  }

  &.CardsButtonGridSoldOutModifier {
    & > div {
      max-width: 160px;
    }
  }
  &.CardsButtonGridActivate {
    margin: 20px -10px 0 -10px;
    & > div {
      min-width: inherit;
      max-width: inherit;
      flex: 0 0 calc(33.3333333% - 20px);
      min-height: 110px;
      padding: 18px;
      justify-content: center;
      margin: 10px;
      &::before {
        border-color: #ff2d38;
      }
      span {
        margin-top: 0;
        font-weight: 700;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
      }
      &.isActive {
        span {
          color: #ff2d38;
        }
      }
    }
  }
  &.CardsButtonGridWelcome {
    margin: 25px -9px 35px -9px;
    & > div {
      min-width: inherit;
      max-width: inherit;
      flex: 0 0 calc(33.3333333% - 18px);
      min-height: 110px;
      max-height: inherit;
      padding: 14px;
      justify-content: center;
      margin: 9px;
      cursor: inherit;
      @media (max-width: 500px) {
        padding: 12px;
      }
      &::before {
        border: 3px solid #ff2d38;
        opacity: 1;
      }
      span {
        margin-top: 0;
        color: #2f3d48;
        font-size: 14px;
        line-height: 1.2;
        @media (max-width: 500px) {
          font-size: 13px;
        }
      }
    }
  }
  &.CardsButtonGridUberEats {
    margin: 25px auto 35px auto;
    max-width: 620px;
    & > div {
      min-width: inherit;
      max-width: inherit;
      flex: 0 0 calc(33.3333333% - 18px);
      min-height: 110px;
      padding: 18px;
      justify-content: center;
      margin: 9px;
      cursor: inherit;
      &::before {
        border: 3px solid #fcd200;
        opacity: 1;
      }
      span {
        margin-top: 0;
        color: #2f3d48;
        font-size: 16px;
        line-height: 1.2;

        @media (max-width: 767px) {
          font-size: 14px;
        }
        @media (max-width: 500px) {
          font-size: 13px;
        }
      }
    }
  }
  &.CardsButtonGridDelivery {
    max-width: 610px;
    margin-top: 0;
    & > div {
      min-width: inherit;
      max-width: inherit;
      flex: 0 0 calc(50% - 10px);
      min-height: 110px;
      padding: 18px;
      justify-content: center;
      margin: 5px;

      &:hover {
        span {
          color: #929cb9;
        }
      }

      &.isActive {
        span {
          font-weight: 700;
          color: #ff2d38;
        }
      }
      &.isDisable {
        pointer-events: none;
        &:hover {
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
`;

export default CardsButtonGrid;
