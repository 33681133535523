const replaceZ = (value) => {
  return value.replace('Z', '');
};

export const discountsV2ReplaceZFromDates = (discount) => {
  const newDiscount = {...discount};

  if (
    newDiscount.availability?.activatesAtLocal &&
    newDiscount.availability.activatesAtLocal.endsWith('Z')
  ) {
    newDiscount.availability.activatesAtLocal = replaceZ(
      newDiscount.availability.activatesAtLocal
    );
  }
  if (
    newDiscount.availability?.deactivatesAtLocal &&
    newDiscount.availability.deactivatesAtLocal.endsWith('Z')
  ) {
    newDiscount.availability.deactivatesAtLocal = replaceZ(
      newDiscount.availability.deactivatesAtLocal
    );
  }

  if (
    newDiscount.promoCode?.usageLimit?.activatesAtLocal &&
    newDiscount.promoCode.usageLimit.activatesAtLocal.endsWith('Z')
  ) {
    newDiscount.promoCode.usageLimit.activatesAtLocal = replaceZ(
      newDiscount.promoCode.usageLimit.activatesAtLocal
    );
  }

  return newDiscount;
};

export const discountsV2AddZToDates = (discount) => {
  const newDiscount = {...discount};

  if (
    newDiscount.availability?.activatesAtLocal &&
    !newDiscount.availability.activatesAtLocal.endsWith('Z')
  ) {
    newDiscount.availability.activatesAtLocal = `${newDiscount.availability.activatesAtLocal}Z`;
  }
  if (
    newDiscount.availability?.deactivatesAtLocal &&
    !newDiscount.availability.deactivatesAtLocal.endsWith('Z')
  ) {
    newDiscount.availability.deactivatesAtLocal = `${newDiscount.availability.deactivatesAtLocal}Z`;
  }

  if (
    newDiscount.promoCode?.usageLimit?.activatesAtLocal &&
    !newDiscount.promoCode.usageLimit.activatesAtLocal.endsWith('Z')
  ) {
    newDiscount.promoCode.usageLimit.activatesAtLocal = `${newDiscount.promoCode.usageLimit.activatesAtLocal}Z`;
  }

  return newDiscount;
};
