import {POST, PUT, GET, DELETE} from 'helpers/services/constants/API_CONSTANTS';
import {
  API_ARCHIVE_DISCOUNT_V2,
  API_CREATE_DISCOUNT_V2,
  API_DELETE_DISCOUNT_V2,
  API_GET_BOGO_ITEMS,
  API_GET_DISCOUNT_BY_ID_V2,
  API_GET_DISCOUNTS_FOR_ITEMS,
  API_GET_DISCOUNTS_V2,
  API_UPDATE_DISCOUNT_V2
} from 'helpers/services/constants/API_ENDPOINTS';
import {request} from 'helpers/services/utils/request';
import {buildUrl} from 'helpers/functionUtils/DiscountsV2BuildUrl';

export const getDiscountsApiV2 = async (payload, locationId) => {
  return request(GET, buildUrl(API_GET_DISCOUNTS_V2(locationId), payload));
};

export const getDiscountByIdApiV2 = async (discountId) => {
  return request(GET, API_GET_DISCOUNT_BY_ID_V2(discountId));
};

export const createDiscountApiV2 = async (data) => {
  return request(POST, API_CREATE_DISCOUNT_V2(), {data});
};

export const deleteDiscountApiV2 = async (discountId) => {
  return request(DELETE, API_DELETE_DISCOUNT_V2(discountId));
};

export const updateDiscountApiV2 = async (discountId, data) => {
  return request(PUT, API_UPDATE_DISCOUNT_V2(discountId), {data});
};

export const archiveDiscountApiV2 = async (discountId) => {
  return request(POST, API_ARCHIVE_DISCOUNT_V2(discountId));
};

export const getBogoItemsApi = async (catalogId) => {
  return request(GET, API_GET_BOGO_ITEMS(catalogId));
};

export const getDiscountsForItemsApi = async (locationId) => {
  return request(GET, API_GET_DISCOUNTS_FOR_ITEMS(locationId));
};
