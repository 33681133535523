import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import Modal from 'components/Modals/Modal';
import {DialogAction} from 'routes/App/dashboard/styles';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import {ARCHIVE_DISCOUNT, DELETE_DISCOUNT} from 'store/discountsV2/action';
import {ButtonOutline} from 'components/buttons';
import EmailMarketingDeleteDiscountModal from 'routes/App/discountsV2/DiscountsList/Modals/EmailMarketingDeleteDiscountModal';

const ModalConfirm = ({onCloseAction, actionType, discount}) => {
  const dispatch = useDispatch();

  const [
    isDeleteDisableEmailMarketingDiscountModal,
    setIsDeleteDisableEmailMarketingDiscountModal
  ] = useState(false);

  const deleteDiscount = () => {
    dispatch(
      DELETE_DISCOUNT({
        payload: discount.id
      })
    );
    onCloseAction();
  };

  const disableDiscount = () => {
    dispatch(
      ARCHIVE_DISCOUNT({
        payload: {
          id: discount.id,
          discount
        }
      })
    );
    onCloseAction();
  };

  const handleSubmit = () => {
    if (discount?.marketing?.email?.isEnabled) {
      setIsDeleteDisableEmailMarketingDiscountModal(true);
    } else if (actionType === 'Delete') {
      deleteDiscount();
    } else {
      disableDiscount();
    }
  };

  return (
    <>
      {isDeleteDisableEmailMarketingDiscountModal ? (
        <EmailMarketingDeleteDiscountModal
          isOpen={isDeleteDisableEmailMarketingDiscountModal}
          onCloseAction={onCloseAction}
          discountAction={
            actionType === 'Delete' ? deleteDiscount : disableDiscount
          }
          actionType={actionType}
        />
      ) : (
        <Modal
          onCloseAction={onCloseAction}
          width='500px'
          title={
            <Box
              lineHeight={1.2}
              fontWeight='bold'
              color='#172751'
              textAlign='center'
              fontSize={24}
            >
              Are you sure you want <br /> to{' '}
              {actionType === 'Delete' ? 'delete' : 'disable'} {discount?.name}{' '}
              discount?
            </Box>
          }
          body={
            <DialogAction className='marginTop50'>
              <ButtonOutline onClick={onCloseAction} className='marginRightSm'>
                <span>Cancel</span>
              </ButtonOutline>
              <ButtonPrimary onClick={handleSubmit}>
                {actionType === 'Delete' ? (
                  <span>Delete</span>
                ) : (
                  <span>Disable</span>
                )}
              </ButtonPrimary>
            </DialogAction>
          }
        />
      )}
    </>
  );
};

ModalConfirm.propTypes = {
  onCloseAction: PropTypes.array.isRequired,
  discount: PropTypes.object.isRequired,
  actionType: PropTypes.string.isRequired
};

export default ModalConfirm;
