import React, {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import DiscountsTable from 'routes/App/discountsV2/DiscountsList/DiscountsTable/DiscountsTable';
import {
  getDiscounts,
  getDiscountsLoading,
  getDiscountsPage,
  getHasNextPage,
  getLocationId,
  getOrderBySorting,
  getSortDirection,
  getEmailMarketingTrialDate
} from 'store/discountsV2/selectors';
import {
  CLEAR_DISCOUNTS,
  GET_DISCOUNTS,
  PUSH_MODALS_STACK,
  SET_DISCOUNT,
  SET_SORTING
} from 'store/discountsV2/action';
import {DISCOUNT_MODALS_V2} from 'helpers/constants/discountsV2';
import {OrdersContainer} from 'routes/App/orders/OrdersList/styles';
import ButtonScrollTop from 'components/buttons/ButtonScrollTop';

const DiscountsView = () => {
  const dispatch = useDispatch();
  const {
    push,
    location: {pathname}
  } = useHistory();
  const locationId = getLocationId();
  const discounts = getDiscounts();
  const hasNextPage = getHasNextPage();
  const discountsPage = getDiscountsPage();
  const loading = getDiscountsLoading();
  const status = pathname === '/discounts-v2/active' ? 'Active' : 'Archived';
  const orderBy = getOrderBySorting();
  const discountDirection = getSortDirection();
  const emailMarketingTrialDate = getEmailMarketingTrialDate();
  const {AllowedServiceFeatures, Timezone} = useSelector(
    (state) => state.user.activeLocation
  );

  useEffect(() => {
    dispatch(
      GET_DISCOUNTS({
        page: 0,
        sort: {sortBy: orderBy, sort: discountDirection},
        sortDirection: discountDirection,
        status,
        limit: 50
      })
    );
    return () => dispatch(CLEAR_DISCOUNTS());
  }, [locationId, status]);

  const onSort = (sort) => {
    dispatch(SET_SORTING(sort));
    dispatch(CLEAR_DISCOUNTS());
    dispatch(GET_DISCOUNTS({sort, status, limit: 50, page: 0}));
  };

  const popperActions = {
    editAction: (discount) => {
      dispatch(SET_DISCOUNT(discount));
      push(`/discounts-v2/wizard/${discount.id}`);
    },
    duplicateAction: (discount) => {
      const duplicate = {...discount};
      delete duplicate.id;
      delete duplicate.status;
      duplicate.name = `Duplicate ${duplicate.name}`;
      dispatch(SET_DISCOUNT(duplicate));
      push('/discounts-v2/wizard/create');
    },
    disableAction: (discount) =>
      dispatch(PUSH_MODALS_STACK({type: DISCOUNT_MODALS_V2.DISABLE, discount})),
    deleteAction: (discount) =>
      dispatch(PUSH_MODALS_STACK({type: DISCOUNT_MODALS_V2.DELETE, discount}))
  };

  const onScrollChange = () => {
    dispatch(
      GET_DISCOUNTS({
        status,
        page: discountsPage,
        sort: {sortBy: orderBy, sort: discountDirection},
        limit: 50
      })
    );
  };

  return (
    <InfiniteScroll
      dataLength={discounts.length}
      next={onScrollChange}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        overflow: 'inherit'
      }}
      hasMore={hasNextPage}
    >
      <OrdersContainer>
        <DiscountsTable
          discounts={discounts}
          status={status}
          loading={loading}
          onSort={onSort}
          orderBy={orderBy}
          discountDirection={discountDirection}
          popperActions={popperActions}
          allowedServiceFeatures={AllowedServiceFeatures}
          emailMarketingTrialDate={emailMarketingTrialDate}
          timezone={Timezone}
        />
      </OrdersContainer>
      <ButtonScrollTop />
    </InfiniteScroll>
  );
};

export default memo(DiscountsView);
