import React, {memo} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import DiscountsList from 'routes/App/discountsV2/DiscountsList';
import DiscountWizardContainer from 'routes/App/discountsV2/DiscountWizard/DiscountWizardContainer';

const DiscountsRoutesV2 = () => {
  const {path} = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/wizard/create`}
        component={DiscountWizardContainer}
      />
      <Route path={`${path}/wizard/:id`} component={DiscountWizardContainer} />
      <Route path={`${path}/`} component={DiscountsList} />
    </Switch>
  );
};

export default memo(DiscountsRoutesV2);
