import {DISCOUNT_TYPE} from 'helpers/constants/discountsV2';

export const setPopperActions = (
  editAction,
  duplicateAction,
  disableAction,
  deleteAction,
  status
) => {
  if (status === 'Active') {
    return [
      {
        title: 'Edit',
        action: (row) => editAction(row)
      },
      {
        title: 'Duplicate',
        action: (row) => duplicateAction(row)
      },
      {
        title: 'Disable',
        action: (row) => disableAction(row)
      },
      {
        title: 'Delete',
        action: (row) => deleteAction(row)
      }
    ];
  }

  return [
    {
      title: 'Duplicate',
      action: (row) => duplicateAction(row)
    },
    {
      title: 'Delete',
      action: (row) => deleteAction(row)
    }
  ];
};

export const getDiscountName = (discountObject) => {
  return discountObject?.subtotal?.min
    ? `${discountObject?.rate?.type === DISCOUNT_TYPE.FIXED ? '$' : ''}${
        discountObject?.rate?.value
      }${
        discountObject?.rate?.type === DISCOUNT_TYPE.PERCENTAGE ? '%' : ''
      } off $${discountObject?.subtotal?.min} order`
    : `${discountObject?.rate?.type === DISCOUNT_TYPE.FIXED ? '$' : ''}${
        discountObject?.rate?.value
      }${discountObject?.rate?.type === DISCOUNT_TYPE.PERCENTAGE ? '%' : ''}`;
};
