import {createGlobalStyle} from 'styled-components';
import IconCalendar from './assets/icon-calendar.svg';
import IconSelect from './assets/icon-arrow-down-red.svg';
import IconSelectDark from './assets/icon-dropdown-dark.svg';
import IconDropMenu from './assets/icon-menu-green.svg';
import IconClock from './assets/icon-clock.svg';
import IconClockOrange from './assets/icon-clock-orange.svg';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    #root[aria-hidden="true"] {
      right: -17px;

      header {
        padding-right: 65px;

        .select-formcontrol {
          right: -17px;
        }

        @media (max-width: 1250px) {
          padding-right: 97px;
        }
        @media (max-width: 1000px) {
          padding-right: 57px;
        }
        @media (max-width: 767px) {
          padding-right: 32px;
        }
        @media (max-width: 599px) {
          padding-right: 20px;
        }
        @media (max-width: 599px) {
          padding-right: 20px;
        }
      }
    }
    
    body {
      background-color: #F7F7FA;
    }

    html,
    body {
      height: 100%;
      width: 100%;
    }

    body {
      font-family: 'Poppins', Helvetica, Arial, sans-serif;
      font-variant-numeric: lining-nums;
      min-width: 320px;
      overflow: auto visible;
      padding-right: 0 !important;
    }

    body.fontLoaded {
      font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-variant-numeric: lining-nums;
    }

    .pac-container {
      z-index: 10000;
      margin-top: -1px;

      .pac-icon-marker {
        display: none;
      }

      .pac-item {
        cursor: pointer;
      }

      .pac-item-query {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
        color: #2F3D48;
        padding: 2px 5px;
      }

      &::after {
        display: none;
      }
    }

    .main {
      background-color: #F7F7FA;
      min-height: 100vh;
      min-width: 320px;
      position: relative;
      &.UberConnect {
        padding-top: 60px;
        @media (max-width: 767px) {
          padding-top: 40px;
        }
      }
      &.ProcessPublish {
        padding-top: 60px;
        @media (max-width: 767px) {
          padding-top: 40px;
        }
      }
      &.BannerUnPublish {
        padding-top: 60px;
        @media (max-width: 767px) {
          padding-top: 40px;
        }
      }
      &.BannerDisableStore {
        padding-top: 60px;
        @media (max-width: 767px) {
          padding-top: 40px;
        }
      } 
      &.BannerEmailMarketingExpired {
        padding-top: 60px;
        @media (max-width: 767px) {
          padding-top: 40px;
        }
      } 
        
      &.BannerEmailMarketingCountdown {
          padding-top: 100px;
      }
        
      &.withoutNavigation {
        background-color: #fff !important;
        .Header, .Sidebar {
          display: none !important;
        }
      }
    }

    .auth-main {
      background-color: #fff;
      min-height: 100%;
      min-width: 100%;
      position: relative;
    }

    .MuiCircularProgress-root {
      color: #17c981 !important;
    }

    .MuiFab-root {
      box-shadow: 0px 8px 16px rgba(255, 45, 56, 0.32) !important;
      border-radius: 50%;
      overflow: hidden;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-image: linear-gradient(105.63deg, #FF2D38 39.07%, #F0868C 96.81%);
        z-index: 2;
        border-radius: 50%;
        transition-property: opacity;
        transition-duration: 0.3s;
      }

      &::after {
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-image: linear-gradient(105.63deg, #FF2D38 39.07%, #FF2D38 96.81%);
        z-index: 1;
      }

      &:hover {
        &::before {
          opacity: 0;
        }
      }

      .MuiFab-label {
        color: #fff;
        position: relative;
        z-index: 3;
      }
    }

    .Say2EatDrop {
      width: 100%;

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiOutlinedInput-root {
        background-color: #F7F7FA;
        border: 1px solid #D5D8DE;
        border-radius: 6px;
      }

      .MuiSelect-select {
        color: #142941;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        position: relative;
        z-index: 2;

        &:focus {
          background-color: transparent;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }

      .MuiInputLabel-outlined {
        color: #2F3D48;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        &.Mui-focused {
          color: #142941;
        }
      }

      .MuiSelect-icon {
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        right: 12px;
        content: '';
        background-image: url("${IconSelectDark}");
        background-repeat: no-repeat;
        background-size: 13px;
        background-position: center;
        z-index: 1;
        @media (max-width: 767px) {
          right: 20px;
        }

        path {
          display: none;
        }
      }

      .MuiInputBase-root {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: #D5D8DE;
          }
        }
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: #E6E6E6;
        background-color: transparent;
      }

      &.DropPeriod {
        .MuiSelect-select {
          padding: 17px 50px 17px 135px !important;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #929CB9;
          z-index: 2;

          @media (max-width: 1000px) {
            padding: 14px 80px 14px 135px !important;
          }
          @media (max-width: 767px) {
            padding: 14px 80px 14px 165px !important;
          }
          @media (max-width: 599px) {
            text-indent: -999px;
          }
        }

        .MuiInputLabel-outlined {
          transform: translate(20px, 21px) scale(1);
          @media (max-width: 1000px) {
            transform: translate(20px, 18px) scale(1);
          }
          @media (max-width: 767px) {
            transform: translate(20px, 17px) scale(1);
          }
        }

        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          //transform: translate(40px, -6px) scale(0.75);

        }
      }

      &.DropMenu {
        .MuiInputBase-root {
          &::before {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            width: 20px;
            height: 20px;
            left: 22px;
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 2;
          }
        }

        .MuiSelect-select {
          padding: 17px 80px 18px 135px !important;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #929CB9;
          z-index: 2;

          @media (max-width: 1000px) {
            padding: 12px 80px 13px 135px !important;
          }
        }

        .MuiInputLabel-outlined {
          transform: translate(65px, 21px) scale(1);
          @media (max-width: 1000px) {
            transform: translate(65px, 16px) scale(1);
          }
        }

        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          //transform: translate(40px, -6px) scale(0.75); 
        }
      }

      &.DropMenu {
        .MuiInputBase-root {
          &::before {
            background-image: url("${IconDropMenu}");
          }
        }
      }
    }

    .Say2EatSelect {
      width: 100%;
      max-width: 100%;

      .MuiFormControl-root {
        width: 100%;
        max-width: 100%;
      }

      .error {
        border-radius: 6px;
        border: 1px solid #ba0302;
      }

      .MuiSelect-select {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
        color: #2F3D48;
        position: relative;
        padding: 18.7px 46px 18.7px 16px;
        border-radius: 6px;
        border: 1px solid #D5D8DE;
        background-color: #F7F7FA;
        height: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:focus {
          border-radius: 6px;
          border: 1px solid #D5D8DE;
          background-color: #F7F7FA;
        }
      }

      .MuiSelect-select.MuiSelect-select {
        padding-right: 46px;
      }

      .MuiSelect-icon {
        display: block;
        fill: transparent;
        width: 24px;
        height: 24px;
        right: 12px;
        background-image: url("${IconSelectDark}");
        background-repeat: no-repeat;
        background-size: 13px;
        background-position: center;
      }

      .MuiInputBase-root {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        &.Mui-disabled {
          opacity: 0.5;
        }
      }

      &.Say2EatSelectOrange {
        .MuiSelect-icon {
          fill: #ff8412;
        }
      }

      &.Say2EatSelectLight {
        .MuiSelect-select {
          background-color: transparent;
          border-radius: 40px;
          padding: 14.7px 35px 14.7px 17px;
        }

        .MuiSelect-icon {
          fill: none;
          width: 24px;
          height: 24px;
          right: 12px;
          background-image: url("${IconSelect}");
          background-repeat: no-repeat;
          background-size: 16px;
          background-position: center;

          &.MuiSelect-iconOpen {
            transform: rotate(180deg);
          }
        }
      }

      &.Say2EatSelectTime {
        .MuiFormControl-root {
          .MuiSelect-select {
            padding-left: 45px;
            background-image: url("${IconClock}");
            background-repeat: no-repeat;
            background-size: 22px;
            background-position: top 9px left 12px;
          }

          .MuiInputBase-root {
            input {
              padding: 14px 10px 14px 45px;
              background-image: url("${IconClock}");
              background-repeat: no-repeat;
              background-size: 22px;
              background-position: top 10px left 12px;
              border: 1px solid #E6E6E6;
              border-radius: 40px;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 1;
              color: #172751;
            }
          }
        }
      }

      &.SauceSelectTime {
        cursor: pointer;

        .MuiFormControl-root {
          .MuiSelect-select {
            padding-left: 44px;
            background-color: #F7F7FA;
            background-image: url("${IconClock}");
            background-repeat: no-repeat;
            background-size: 6px;
            background-position: center left 12px;
          }

          .MuiInputBase-root {
            position: relative;

            input {
              height: 56px;
              padding: 0 10px 0 44px;
              background-image: url("${IconClock}");
              background-repeat: no-repeat;
              background-size: 21px;
              background-position: center left 12px;
              background-color: #F7F7FA;
              border: 1px solid #D5D8DE;
              border-radius: 6px;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 1;
              color: #172751;
              cursor: pointer;
            }

            &::after {
              display: block;
              position: absolute;
              left: inherit;
              border: 0;
              top: 50%;
              margin-top: -11px;
              width: 24px;
              height: 24px;
              right: 12px;
              content: '';
              background-image: url('${IconSelectDark}');
              background-repeat: no-repeat;
              background-size: 14px;
              background-position: center;
              z-index: 1;
              transform: scale(1);
            }
          }
        }
      }

      &.Say2EatSelectTimeOrange {
        .MuiFormControl-root {
          .MuiSelect-select {
            padding-left: 45px;
            background-image: url("${IconClockOrange}");
            background-repeat: no-repeat;
            background-size: 22px;
            background-position: top 10px left 12px;
          }
        }
      }

      &.Say2EatSelectOrange {
        .MuiSelect-icon {
          background-image: url("${IconSelect}");
        }
      }
    }

    .MuiPopover-paper {
      outline: none !important;
      box-shadow: 0px 10px 40px rgba(114, 135, 150, 0.4) !important;
    }

    .MuiList-root {
      padding: 0 !important;
    }

    .DragDropIcon {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .DropDown {
      z-index: 10;
      padding: 24px;
      background-color: #fff;
      box-shadow: 0px 10px 40px rgba(114, 135, 150, 0.4);
      border-radius: 3px;
      border: 0;
      outline: 0;
      @media (max-width: 767px) {
        padding: 20px;
      }

      &.StaticRangeDatePicker {
        padding: 0;
        z-index: inherit;
        box-shadow: none;
        border-radius: 0;
        margin-top: 10px;
        @media (max-width: 767px) {
          width: 100%;
        }
        .MuiPickersStaticWrapper-root {
          @media (max-width: 650px) {
            overflow-x: auto;
          }
        }

        .MuiPickersDesktopDateRangeCalendar-root {
          justify-content: space-between;
          padding: 0 18px;
          
          @media (max-width: 650px) {
            min-width: 544px;
            padding: 0;
          }
        }
      }

      * {
        outline: none;
      }

      ul {
        padding: 0;
      }

      .DropDownList {
        min-width: 160px;
        max-width: 160px;
        flex: 0 0 auto;

        @media (max-width: 1000px) {
          min-width: 140px;
          max-width: 140px;
        }
        @media (max-width: 767px) {
          min-width: auto;
          max-width: 100%;
        }

        .MuiList-padding {
          @media (max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
          }
        }
      }

      .DropDownMenu {
        .MuiListItem-root {
          padding: 7px 0;
          transition-property: color;
          transition-duration: .3s;
          position: relative;
          margin-bottom: 10px;
          @media (max-width: 767px) {
            width: auto;
            padding: 5px;
            margin: 2px;
          }

          .MuiTypography-root {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 1;
            color: #2D3036;
          }

          &:last-child {
            margin-bottom: 0;
            @media (max-width: 767px) {
              margin-bottom: 5px;
            }
            @media (max-width: 599px) {
              margin-bottom: 2px;
            }
          }

          .MuiTouchRipple-root {
            display: none;
          }

          &:hover {
            background-color: transparent;

            .MuiTypography-root {
              font-weight: 700;
            }
          }

          &.Mui-selected {
            background-color: transparent;

            .MuiTypography-root {
              font-weight: 700;
            }
          }
        }

      }

      .DropDownHolder {
        display: flex;
        align-items: flex-start;
        @media (max-width: 767px) {
          display: block;
          position: relative;
          padding-bottom: 50px;
        }
      }

      .DropDownDatepicker {
        padding-left: 20px;
        width: 100%;
        flex: 1 1 auto;
        @media (max-width: 767px) {
          padding-left: 0;
          margin-top: 20px;
        }

        .MuiPickersStaticWrapper-root {
          @media (max-width: 599px) {
            overflow-x: auto;
          }
        }

        .MuiPickersDesktopDateRangeCalendar-root {
          .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer {
            width: 50%;
          }
          @media (max-width: 767px) {
            justify-content: center;
          }
          @media (max-width: 599px) {
            min-width: 529px;
          }
        }
      }

      .DropDownActions {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 0;
        @media (max-width: 767px) {
          position: absolute;
          margin-top: 0;
          bottom: 0;
          left: 0;
          text-align: center;
        }
      }

      .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child) {
        border: none;
      }

      .MuiPickersStaticWrapper-sta.MuiSelect-selectticWrapperRoot {
        min-width: 272px;
      }

      .MuiPickersDesktopDateRangeCalendar-calendar {
        min-width: 272px;
        min-height: 252px;
      }

      .MuiPickersArrowSwitcher-root {
        .MuiSvgIcon-root {
          fill: #142941;
        }

        h6 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 23px;
          color: #2E384D;
        }
      }

      .MuiPickersDesktopDateRangeCalendar-arrowSwitcher {
        padding: 0px 12px 8px 12px;
      }

      .MuiPickersCalendarHeader-switchHeader {
        .MuiButtonBase-root {
          width: 32px;
          height: 32px;
          border-radius: 2px;

          &:first-child {
            margin-left: 10px;
          }

          &:last-child {
            margin-right: 10px;
          }
        }
      }

      .MuiPickersCalendar-daysHeader {
        .MuiPickersCalendar-weekDayLabel {
          color: #142941;
          font-size: 14px;
          width: 32px;
          height: 32px;
          font-weight: 700;
          font-family: 'Poppins';
        }
      }

      .MuiPickersCalendar-week {
        margin: 5px 0;

        .MuiButtonBase-root {
          border-radius: 2px;
          color: #142941;
          font-size: 14px;
          font-weight: 400;
          width: 32px;
          height: 32px;
          margin: 2px;
          font-family: 'Poppins';

          &.MuiPickersDay-today {
            border: 1px solid rgba(255, 45, 56, 1);
          }

          &.MuiPickersDay-today.Mui-selected {
            background-color: #FF2D38;
          }

          &.MuiPickersDateRangeDay-dayOutsideRangeInterval:hover {
            border: 1px solid rgba(255, 45, 56, 1);
            background-color: #fff;
          }

          &.Mui-disabled {
            opacity: .4;
          }

          &.Mui-selected {
            color: #fff;
            background-color: #FF2D38;

            &:hover {
              background-color: #FF2D38;
            }
          }
        }
      }

      .MuiPickersDateRangeDay-rangeIntervalDayHighlightStart {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      .MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      .MuiPickersDateRangeDay-rangeIntervalDayHighlight:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      .MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      .MuiPickersDateRangeDay-rangeIntervalPreview {
        border: none;
      }

      .MuiPickersDateRangeDay-root {
        margin: 0;
      }

      .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
        background-color: rgba(35, 45, 58, .05);
      }

      .MuiPickersDateRangeDay-rangeIntervalDayPreview {
        background-color: rgba(35, 45, 58, .05);

        &.MuiPickersDateRangeDay-rangeIntervalDayPreviewEnd {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }

        &.MuiPickersDateRangeDay-rangeIntervalDayPreviewStart {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }

      .MuiPickersDateRangeDay-root {
        &:last-child {
          .MuiPickersDateRangeDay-rangeIntervalDayPreview {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }

        &:first-child {
          .MuiPickersDateRangeDay-rangeIntervalDayPreview {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
        }

      }

      .MuiPickersCalendar-week:last-child {
        .MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }


      .DropDownInners {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .DropDownInner {
          flex: 0 0 50%;
          padding: 0 10px;
          @media (max-width: 599px) {
            padding: 0 4px;
          }

          input {
            display: block;
            width: 100%;
            height: 40px;
            background-color: #fff;
            background-image: url("${IconCalendar}");
            background-position: center left 16px;
            background-size: 13px;
            background-repeat: no-repeat;
            border: 1px solid #E5E5E5;
            padding: 0 10px 0 40px;
            border-radius: 30px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 1;
            color: #142941;
          }
        }
      }
    }

    .recharts-tooltip-wrapper:last-child {

    }

    .ChartTooltip {
      background-color: #17c981;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 10px;

      p {
        display: block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.2;
        color: #fff;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .MuiDialog-paper {
      max-width: inherit !important;
      background-color: #fff !important;
      box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08), 10px 10px 20px rgba(108, 49, 151, 0.06), 30px 30px 30px rgba(108, 49, 151, 0.04) !important;
      border-radius: 10px !important;
      @media (max-width: 1000px) {
        margin: 15px !important;
      }
      @media (max-width: 450px) {
        width: calc(100% - 40px) !important;
        margin: 20px !important;
        max-width:  calc(100% - 40px) !important;
        max-height: calc(100% - 40px) !important;
      }
    }

    .Say2EatCheckbox {
      .MuiIconButton-root {
        padding: 0 8px;

        &:hover {
          background-color: transparent !important;
        }
      }

    }

    .MuiFormControlLabel-root {
      &.noMarginRight {
        margin-right: 0;
      }
    }

    .MuiFormControlLabel-label {
      position: relative;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.2;
      color: #2F3D48;
      font-family: 'Poppins';
    }

    &.Say2EatCheckboxSoldOut {
      .MuiFormControlLabel-label {
        color: #f46d0c;
      }
    }

    &.Center {
      display: flex;
      justify-content: center;
    }
  }

  .alignSelfStart {
    align-self: flex-start;
  }

  .MainDropDown {
    z-index: 3;

    .MuiPaper-root {
      border-radius: 0;
      width: 144px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    }

    .MuiListItem-root {
      padding: 14px 16px !important;
      text-align: left;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: #2F3D48;

      &.SoldOut {
        color: #FCA600;
      }
    }
  }

  .MuiList-root {
      .MuiListItem-root {
          &.listItemText {
              font-size: 14px;
              font-family: 'Poppins', sans-serif;
              cursor: pointer;
          }
          &.pL10 {
                padding-left: 30px;
          }
          &:hover {
              background-color: rgba(0, 0, 0, 0.04);
          }
      }
      .MuiListSubheader-root {
          font-family: 'Poppins', sans-serif;
          font-weight: bold;
      }
  }

  .MainDropDownBoard {
    z-index: 3;

    .MuiPaper-root {
      border-radius: 0;
      width: 190px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    }

    .MuiListItem-root {
      padding: 14px 16px !important;
      text-align: left;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: #2F3D48;

      &.SoldOut {
        color: #FCA600;
      }
    }
  }

  .MuiFormControl-root.CountryAutocomplete {
    background-color: #f7f7fa;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #2F3D48;
    outline: none;
    width: 100%;

    .MuiInputBase-root {
      font-size: 14px;

      input {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
        color: #2F3D48;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      .PrivateNotchedOutline-root-3 {
        border-color: #d5d8de;
      }

      fildset {
        border-color: #d5d8de;
      }
    }

    .MuiInputBase-root:hover {
      border: none;
    }

    .MuiAutocomplete-popupIndicator {
      display: none;
    }

    &.error {
      border: 1px solid #ba0302;
    }

    &.isDisabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &.FieldNumber {
      width: 110px;
    }

    &.mLeft {
      margin-left: 16px;
    }

    &.Sm {
      width: 70px;
    }

    &.widthAuto {
      flex: 1 1 auto;
    }

    &::placeholder {
      color: #929cb9;
    }
  }

  .MuiPickersPopper-root {
    .MuiPickersPopper-paper {
      padding: 30px;
      background: #fff;
      box-shadow: 0px 10px 40px rgba(114, 135, 150, 0.4);
      border-radius: 3px;
    }

    .MuiPickersBasePicker-pickerView {
      width: 100%;
    }

    .MuiPickersCalendarHeader-root {
      padding: 0;
      margin-top: 0;

      .MuiPickersCalendarHeader-monthTitleContainer {
        align-items: center;

        h6 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 23px;
          color: #2e384d;
        }
      }
    }

    .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child) {
      border: none;
    }

    .MuiPickersStaticWrapper-sta.MuiSelect-selectticWrapperRoot {
      min-width: 272px;
    }

    .MuiPickersDesktopDateRangeCalendar-calendar {
      min-width: 272px;
      max-width: 272px;
      min-height: 252px;
    }

    .MuiPickersArrowSwitcher-root {
      .MuiSvgIcon-root {
        fill: #142941;
      }

      h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: #2E384D;
      }
    }

    .MuiPickersDesktopDateRangeCalendar-arrowSwitcher {
      padding: 0px 12px 8px 12px;
    }

    .MuiPickersCalendarHeader-switchHeader {
      .MuiButtonBase-root {
        width: 32px;
        height: 32px;
        border-radius: 2px;

        &:first-child {
          margin-left: 10px;
        }

        &:last-child {
          margin-right: 10px;
        }
      }
    }

    .MuiPickersCalendar-daysHeader {
      .MuiPickersCalendar-weekDayLabel {
        color: #142941;
        font-size: 14px;
        width: 32px;
        height: 32px;
        font-weight: 700;
        font-family: 'Poppins';
      }
    }

    .MuiPickersCalendar-week {
      margin: 5px 0;

      .MuiButtonBase-root {
        border-radius: 2px;
        color: #142941;
        font-size: 14px;
        font-weight: 400;
        width: 32px;
        height: 32px;
        margin: 2px;
        font-family: 'Poppins';

        &.MuiPickersDay-today {
          border: 1px solid rgba(255, 45, 56, 1);
        }

        &.MuiPickersDay-today.Mui-selected {
          background-color: #FF2D38;
        }

        &.MuiPickersDateRangeDay-dayOutsideRangeInterval:hover {
          border: 1px solid rgba(255, 45, 56, 1);
          background-color: #fff;
        }

        &.Mui-disabled {
          opacity: 0.4;
        }

        &.Mui-selected {
          color: #fff;
          background-color: #FF2D38;

          &:hover {
            background-color: #FF2D38;
          }
        }
      }
    }

    .MuiPickersDateRangeDay-rangeIntervalDayHighlightStart {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    .MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    .MuiPickersDateRangeDay-rangeIntervalDayHighlight:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    .MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    .MuiPickersDateRangeDay-rangeIntervalPreview {
      border: none;
    }

    .MuiPickersDateRangeDay-root {
      margin: 0;
    }

    .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
      background-color: rgba(35, 45, 58, .05);
    }

    .MuiPickersDateRangeDay-rangeIntervalDayPreview {
      background-color: rgba(35, 45, 58, .05);

      &.MuiPickersDateRangeDay-rangeIntervalDayPreviewEnd {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      &.MuiPickersDateRangeDay-rangeIntervalDayPreviewStart {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    }

    .MuiPickersDateRangeDay-root {
      &:last-child {
        .MuiPickersDateRangeDay-rangeIntervalDayPreview {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }

      &:first-child {
        .MuiPickersDateRangeDay-rangeIntervalDayPreview {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }

    }

    .MuiPickersCalendar-week:last-child {
      .MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  .PopoverStatus {
    filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.3));

    .MuiPopover-paper {
      max-width: 200px;
      min-width: 172px;
      padding: 0;
      @media (max-width: 1000px) {
        min-width: 140px;
      }

      ul {
        display: block;

        li {
          cursor: pointer;
          padding: 16px 17px;
          transition-property: background-color;
          transition-duration: 0.3s;
          display: block;
          margin: 0;
          @media (max-width: 1000px) {
            padding: 10px;
          }

          &:hover {
            background-color: #EAF2F0;
          }
        }
      }
    }
  }

  .marginRightXs {

    margin-right: 8px;
  }

  .marginRightSm {

    margin-right: 12px;
  }

  .marginRightMd {

    margin-right: 16px;
    @media (max-width: 599px) {
      margin-right: 10px;
    }

  }

  .marginRightLg {

    margin-right: 20px;
  }

  .marginRight2l {

    margin-right: 24px;
  }

  .marginRight3l {

    margin-right: 32px;
  }

  .marginRight4l {

    margin-right: 42px;
  }

  .marginLeftXs {
    margin-left: 8px;
  }

  .marginLeftXsPlus {
    margin-left: 12px;
  }

  .marginLeft2l {

    margin-left: 24px;
  }

  .marginTopXs {

    margin-top: 12px;
  }

  .marginTopMd {
    margin-top: 20px;
  }

  .marginTopSm {
    margin-top: 16px;
  }

  .marginBottomXs {

    margin-bottom: 12px;
  }

  .marginBottomXs2 {

    margin-bottom: 5px;
  }

  .marginBottom2Xs {

    margin-bottom: 8px;
  }

  .marginBottomSm {

    margin-bottom: 16px;
  }

  .marginBottomMd {

    margin-bottom: 20px;
  }

  .marginBottomLg {

    margin-bottom: 24px;
    @media (max-width: 599px) {
      margin-bottom: 12px;
    }
  }

  .marginBottomXl {

    margin-bottom: 32px;
    @media (max-width: 599px) {
      margin-bottom: 16px;
    }
  }
  
  .orderDetailsRefund {
    display: flex;
    align-items: flex-start;
    
    svg, img {
      margin-right: 11px;
      flex: 0 0 auto;
      width: 21px;
    }
    div {
      word-break: break-word;
    }
  }

  .marginBottom2l {

    margin-bottom: 48px;
    @media (max-width: 599px) {
      margin-bottom: 24px;
    }
  }

  .marginBottom3l {
    margin-bottom: 65px;
  }

  .mTop2 {

    margin-top: 2px;
  }
  .mTop10 {

    margin-top: 10px;
  }

  .marginTopLg {

    margin-top: 24px;
  }
  .marginTopLg2 {

    margin-top: 28px;
  }

  .inlineBlock {

    display: inline-block;
  }

  .marginTopXl {

    margin-top: 32px;
  }
  .marginTopXXl {

    margin-top: 50px;
  }

  .block {
    display: block;
  }

  .flexAutoMain {
    flex: 0 0 auto;
  }

  .inputFieldHeight {
    height: 56px;
  }

  .flexAuto {
    flex: 1 1 auto;
  }

  .text-center {
    text-align: center;
  }

  .flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .CircularProgress {
    svg {
      circle {
        color: #FF2D38;
      }
    }
  }

  .MuiMenuItem-root {
    white-space: inherit !important;
    word-break: break-word;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 1.3 !important;
    color: #172751 !important;
    padding: 10px 16px !important;
  }

  .wordWrap {
    word-break: break-word;
  }
  
  .greenText {
    font-weight: 700;
    font-size: 14px;
    color: #17C981;
  }
  
  .redText {
    font-weight: 700;
    font-size: 14px;
    color: #FF2D38;
  }
  

  .opacity1 {
    opacity: 1;
    pointer-events: all;
  }

  &.opacity04 {
    opacity: 0.4;
    pointer-events: none;
  }

  &.whiteCheckboxLabel {
    .MuiFormControlLabel-label {
      color: #fff;
    }
  }

  .MuiDialog-container {
    .MuiPickersModalDialog-dialogRoot {
      .MuiPickersToolbar-root {
        background-color: #FF2D38;

        .MuiTypography-root {
          font-family: 'Poppins', Helvetica, Arial, sans-serif;
        }

        .MuiIconButton-root {
          display: none;
        }
      }

      .MuiPickersBasePicker-pickerView {
        .MuiPickersClock-root {
          .MuiPickersClock-amButton, .MuiPickersClock-pmButton {
            width: 43px;
            height: 43px;
            transition: .3s;
            font-family: 'Poppins', Helvetica, Arial, sans-serif;

            .MuiTypography-caption {
              line-height: 1;
              transition: .3s;
            }

            &:hover {
              background-color: rgba(255, 45, 56, .5);

              .MuiTypography-caption {
                color: #fff;
              }
            }

            &.MuiPickersClock-meridiemButtonSelected {
              background-color: #FF2D38;
            }
          }
        }

        .MuiPickersClock-clock {
          cursor: pointer;

          .MuiPickersClock-pin {
            background-color: #FF2D38;
          }

          .MuiPickersClockPointer-pointer {
            background-color: #FF2D38;
          }

          .MuiPickersClockPointer-noPoint {
            background-color: #FF2D38;
            border-color: #FF2D38;
          }

          .MuiPickersClockPointer-thumb {
            border-color: #FF2D38;
          }

          .MuiPickersClockNumber-root {
            .MuiTypography-root {
              font-family: 'Poppins', Helvetica, Arial, sans-serif;
              font-size: 14px;
            }

            &:hover {
              background-color: rgba(255, 45, 56, .04);
              color: #fff;

              .MuiTypography-root {
                color: #fff;
              }
            }
          }
        }

        .MuiPickersCalendarHeader-monthTitleContainer {
          display: flex;
          align-items: center;

          h6 {
            font-family: 'Poppins', Helvetica, Arial, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.2;
            color: #2F3D48;
          }
        }

        .MuiPickersMobileKeyboardInputView-root {
          .MuiInputBase-input {
            font-family: 'Poppins', Helvetica, Arial, sans-serif;
          }

          .MuiFormHelperText-filled {
            font-family: 'Poppins', Helvetica, Arial, sans-serif;
          }

          .MuiInput-underline:after {
            border-color: #FF2D38;
          }
        }

        .MuiPickersCalendar-daysHeader .MuiPickersCalendar-weekDayLabel {
          color: #142941;
          font-size: 14px;
          width: 32px;
          height: 32px;
          font-weight: 700;
          font-family: 'Poppins';
        }

        .MuiPickersCalendar-root {
          .MuiPickersDay-root {
            border-radius: 2px;
            color: #142941;
            font-size: 14px;
            font-weight: 400;
            width: 32px;
            height: 32px;
            margin: 2px;
            background-color: transparent;
            font-family: 'Poppins';
            border: 2px solid transparent;

            &.Mui-disabled {
              opacity: .5;
            }

            &.Mui-selected {
              background-color: #FF2D38;
              color: #fff;
            }

            &.MuiPickersDay-today, &:hover {
              border-color: #FF2D38;
            }
          }
        }
      }

      .MuiDialogActions-root {
        .MuiButtonBase-root {
          color: #FF2D38;
          background-color: rgba(255, 45, 56, .04);
          transition: .3s;

          &:hover {
            background-color: rgba(255, 45, 56, .06);
          }
        }
      }
    }
  }
  
  .textUppercase {
    text-transform: uppercase;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .fwBold {
    font-weight: 700;
  }
  
  .LinkButton {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
    color: #ff2d38;
    cursor: pointer;
    border-bottom: 1px solid #ff2d38;
    transition-property: border-bottom-color;
    transition-duration: 0.3s;
    
    &.sm {
      font-size: 14px;
    }
    &:hover {
      border-bottom-color: transparent;
    }
  }
  
  .reportTypeItem{
        &.MuiMenuItem-root{
          color: #142941;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: normal;
          font-size: 14px !important;
          position: relative;
          z-index: 2;
          margin: 0px 8px !important;
          
          &:first-child{
            margin-top: 12px !important;
          }
          &:last-child{
            margin-bottom: 12px !important;
          }

          &:hover {
            background-color: transparent !important;
            font-weight: 700 !important;
          }
          
          &.Mui-selected{
            background-color: transparent !important;
            font-weight: 700 !important;
            
          }
          &:focus {
            background-color: transparent !important;
          }
        }
  }
  .heightReset {
    font-size: 0;
  }
`;

export default GlobalStyle;
