import moment from 'moment/moment';

export const isEmailMarketingTrialExpired = (date, timezoneOffset = 0) => {
  const trialStart = moment.utc(date).utcOffset(Number(timezoneOffset) * 60);
  const trialEnd = trialStart.clone().add(90, 'days');
  const now = moment().utcOffset(Number(timezoneOffset) * 60);

  return now.isSameOrAfter(trialEnd);
};

export default isEmailMarketingTrialExpired;
