import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Cookies from 'js-cookie';
import {useDispatch} from 'react-redux';
import {
  CountdownBanner,
  CountdownTimer,
  CountdownTitle,
  ExpiredBannerClose,
  ExpiredBannerContent,
  ExpiredBannerIcon,
  ExpiredBannerUnderlineText,
  ExpiredBannerWrapper
} from 'components/EmailMarketingCountdown/styles';
import FlipNumber from 'components/EmailMarketingCountdown/FlipNumber';
import {SET_EMAIL_MARKETING_TRIAL_DATE} from 'store/emailMarketing/action';
import {
  COOKIE_NAME_EMAIL_MARKETING_COUNTDOWN,
  EMAIL_MARKETING_TRIAL_DATE_EXPIRED
} from 'helpers/constants/emailMarketing';
import BannerIcon from 'assets/icon-expired-email-marketing.svg';
import {FlexContainer, FlexItem} from 'components/layout';

const EmailMarketingCountdown = ({startDate, timezone, status}) => {
  const COOKIE_EXPIRATION_HOURS = 24;

  const dispatch = useDispatch();

  const calculateTimeLeft = useCallback(() => {
    const restStartDate = moment
      .utc(startDate)
      .utcOffset((Number(timezone) || 0) * 60);
    const endDate = restStartDate.clone().add(90, 'days');

    const now = moment().utcOffset((Number(timezone) || 0) * 60);
    const difference = moment.duration(endDate.diff(now));

    if (difference.asSeconds() <= 0) {
      return {days: 0, hours: 0, minutes: 0, seconds: 0};
    }

    return {
      days: Math.floor(difference.asDays()),
      hours: difference.hours(),
      minutes: difference.minutes(),
      seconds: difference.seconds()
    };
  }, [startDate, timezone]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);
  const [isExpired, setIsExpired] = useState(
    timeLeft.days === 0 &&
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
  );

  useEffect(() => {
    if (isExpired) {
      return () => {};
    }

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0
      ) {
        setIsExpired(true);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [calculateTimeLeft, isExpired]);

  const onClose = () => {
    dispatch(SET_EMAIL_MARKETING_TRIAL_DATE({startDate: null, status: null}));
    Cookies.set(COOKIE_NAME_EMAIL_MARKETING_COUNTDOWN, 'true', {
      expires: COOKIE_EXPIRATION_HOURS / 24
    });
  };

  return (
    <>
      {status === EMAIL_MARKETING_TRIAL_DATE_EXPIRED || isExpired ? (
        <ExpiredBannerWrapper>
          <FlexContainer>
            <FlexItem flex={1}>
              <ExpiredBannerContent>
                <ExpiredBannerIcon src={BannerIcon} alt='Expired' />
                <ExpiredBannerUnderlineText>
                  Your free Email Marketing trial has ended, contact your CSM
                  about our email marketing package
                </ExpiredBannerUnderlineText>
              </ExpiredBannerContent>
            </FlexItem>
            <FlexItem className='alignSelfCenter'>
              <ExpiredBannerClose onClick={onClose} />
            </FlexItem>
          </FlexContainer>
        </ExpiredBannerWrapper>
      ) : (
        <CountdownBanner>
          <FlexContainer>
            <FlexItem flex={1}>
              <CountdownTitle>Your exclusive access ends in:</CountdownTitle>
              <CountdownTimer>
                <FlipNumber value={timeLeft.days} label='DAYS' />
                <FlipNumber value={timeLeft.hours} label='HOURS' />
                <FlipNumber value={timeLeft.minutes} label='MINUTES' />
                <FlipNumber value={timeLeft.seconds} label='SECONDS' />
              </CountdownTimer>
            </FlexItem>
            <FlexItem className='alignSelfCenter'>
              <ExpiredBannerClose onClick={onClose} />
            </FlexItem>
          </FlexContainer>
        </CountdownBanner>
      )}
    </>
  );
};

EmailMarketingCountdown.propTypes = {
  startDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default EmailMarketingCountdown;
