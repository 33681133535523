import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  DashboardField,
  DashboardFieldDatepicker,
  DashboardFieldInner,
  DashboardFieldLabel,
  DashboardFields,
  DashboardHeader,
  DashboardHolder,
  DashboardInnerDescription,
  FieldInput,
  FieldInputDollar,
  FieldLabel
} from 'routes/App/dashboard/styles';
import DatepickerS2E from 'components/Datepicker';
import LinkBack from 'components/LinkBack/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import SwitchS2E from 'components/Switch';
import {DashboardInner} from 'components/DashboardInner/styles';
import Subtitle5 from 'components/typography/Subtitle5';
import Subtitle4 from 'components/typography/Subtitle4';
import {ButtonInfo, ButtonPrimary} from 'components/buttons';
import {twoDecimalsFormatter} from 'helpers/functionUtils/helpFunctions';
import {
  getEmailMarketingToggleError,
  getEmailMarketingTrialDate,
  getIsEmailMarketingTurnOffModalOpen
} from 'store/discountsV2/selectors';
import {
  CLEAR_DISCOUNT,
  OPEN_EMAIL_MARKETING_TURN_OFF_MODAL,
  SET_EMAIL_MARKETING_TOGGLE_ERROR
} from 'store/discountsV2/action';
import EmailMarketingTurnOffModal from 'routes/App/discountsV2/DiscountsList/Modals/EmailMarketingTurnOffModal';
import {DISCOUNT_STATUS, DISCOUNT_TYPE} from 'helpers/constants/discountsV2';
import {isEmailMarketingTrialExpired} from 'helpers/functionUtils/emailMarketing';

const Settings = (props) => {
  const {
    formik,
    discount,
    enabledDiscount,
    setEnabledDiscount,
    enabledDate,
    setEnabledDate,
    buttonStatus,
    allowedServiceFeatures
  } = props;

  const dispatch = useDispatch();
  const {activeLocation} = useSelector((state) => state.user);
  const isEmailMarketingToggleError = getEmailMarketingToggleError();
  const isEmailMarketingTurnOffModalOpen = getIsEmailMarketingTurnOffModalOpen();
  const location = useLocation();
  const history = useHistory();
  const emailMarketingTrialDate = getEmailMarketingTrialDate();
  const {Timezone} = useSelector((state) => state.user.activeLocation);

  useEffect(() => {
    if (isEmailMarketingToggleError) {
      formik.setFieldError(
        'marketing.email.isEnabled',
        'Another discount was already selected for Email Marketing, you can use only one'
      );
    }
    return () => dispatch(SET_EMAIL_MARKETING_TOGGLE_ERROR(false));
  }, [isEmailMarketingToggleError]);

  useEffect(() => {
    return () => {
      dispatch(CLEAR_DISCOUNT());
    };
  }, []);

  return (
    <>
      <DashboardHeader className='mb10'>
        <LinkBack onClick={() => history.push('/discounts-v2')}>
          Back to all Discounts
        </LinkBack>
      </DashboardHeader>
      <DashboardInner>
        <Subtitle5 className='marginBottomXs'>Discount wizard</Subtitle5>
        <Subtitle4>
          <span>{discount?.name}</span>
        </Subtitle4>
        <DashboardHolder className='marginTopLg'>
          <DashboardFields>
            <DashboardField className='w100 dFlex'>
              <DashboardFieldLabel>
                <FieldLabel
                  className={cn('FieldLabelLg marginBtNull', {
                    isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                  })}
                >
                  Discount Name
                </FieldLabel>
              </DashboardFieldLabel>
              <DashboardFieldInner className='w60FLex FieldRelative'>
                <FieldInput
                  className={cn('', {
                    error: formik.errors?.name && formik.touched?.name,
                    isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                  })}
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder='Discount Name'
                />
                <ErrorSignWrapper className='ErrorSignWrapper'>
                  {formik.errors?.name && formik.touched?.name ? (
                    <ErrorSign>{formik.errors.name}</ErrorSign>
                  ) : null}
                </ErrorSignWrapper>
              </DashboardFieldInner>
              <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                <div className='DropInfo DropInfoRight DropInfoRightMob'>
                  This name will be available for customers to see
                </div>
              </ButtonInfo>
            </DashboardField>
            <DashboardField className='w100 dFlex'>
              <DashboardFields className='marginMinus'>
                <DashboardField className='wAuto w50mob isDisabled'>
                  <FieldLabel className='FieldLabelLg'>
                    Discount Type
                  </FieldLabel>
                  <FieldInput
                    className={`${
                      formik.errors?.rate?.type && 'error'
                    } FieldNumberLg`}
                    name='rate.type'
                    type='text'
                    onChange={() => {}}
                    value={
                      formik.values?.rate?.type === DISCOUNT_TYPE.PERCENTAGE
                        ? '%'
                        : '$'
                    }
                    placeholder=''
                  />
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.rate?.type && formik.touched?.rate?.type ? (
                      <ErrorSign>{formik.errors?.rate?.type}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAuto w50mob'>
                  <FieldLabel
                    className={cn('FieldLabelLg', {
                      isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                    })}
                  >
                    Discount Value
                  </FieldLabel>
                  <FieldInput
                    className={cn('FieldNumberLg', {
                      error:
                        formik.errors?.rate?.value &&
                        formik.touched?.rate?.value,
                      isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                    })}
                    name='rate.value'
                    type='text'
                    onChange={(e) =>
                      formik.setFieldValue(
                        'rate.value',
                        twoDecimalsFormatter(
                          e.target.value,
                          formik.values?.rate?.value,
                          formik.values?.rate?.type === DISCOUNT_TYPE.PERCENTAGE
                            ? 90
                            : 9999.99
                        ) || ''
                      )
                    }
                    onBlur={(e) => {
                      const lastElement = e.target.value.slice(-1)[0];
                      if (lastElement === '.') {
                        formik.setFieldValue(
                          'rate.value',
                          e.target.value.slice(0, -1)
                        );
                      }
                    }}
                    value={formik.values?.rate?.value}
                    placeholder=''
                  />
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.rate?.value &&
                    formik.touched?.rate?.value ? (
                      <ErrorSign>{formik.errors?.rate?.value}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAuto w50mob'>
                  <FieldLabel
                    className={cn('FieldLabelLg', {
                      isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                    })}
                  >
                    Min Order
                  </FieldLabel>
                  <FieldInputDollar>
                    <FieldInput
                      className={cn('FieldNumberLg', {
                        error: formik.errors?.subtotal?.min,
                        isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                      })}
                      name='subtotal.min'
                      type='text'
                      onChange={(e) =>
                        formik.setFieldValue(
                          'subtotal.min',
                          twoDecimalsFormatter(
                            e.target.value,
                            formik.values?.subtotal?.min,
                            9999.99,
                            true
                          )
                        )
                      }
                      onBlur={(e) => {
                        const lastElement = e.target.value.slice(-1)[0];
                        if (lastElement === '.') {
                          formik.setFieldValue(
                            'subtotal.min',
                            e.target.value.slice(0, -1)
                          );
                        }
                      }}
                      value={formik.values?.subtotal?.min}
                      placeholder=''
                    />
                  </FieldInputDollar>
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.subtotal?.min ? (
                      <ErrorSign>{formik.errors.subtotal?.min}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFields>
            </DashboardField>
            <DashboardField>
              <DashboardInnerDescription className='withIcon'>
                <span>
                  This discount will be available between the following dates,
                  all week, all day, or until the Usage Limit is reached.
                </span>
              </DashboardInnerDescription>
            </DashboardField>
            <DashboardField className='dFlex w100 DiscountDatepicker'>
              <FormControlLabel
                className='Say2EatCheckbox'
                control={
                  <Checkbox
                    labelStyle={{color: '#2F3D48'}}
                    iconStyle={{fill: '#FF2D38'}}
                    inputStyle={{color: '#FF2D38'}}
                    style={{
                      color: '#FF2D38',
                      opacity:
                        discount.status === DISCOUNT_STATUS.ARCHIVED
                          ? '0.3'
                          : null
                    }}
                    onChange={(e) => {
                      const {checked} = e.target;
                      setEnabledDate(checked);
                      if (!checked) {
                        formik.setFieldValue(
                          'availability.activatesAtLocal',
                          null
                        );
                        formik.setFieldValue(
                          'availability.deactivatesAtLocal',
                          null
                        );
                      }
                    }}
                    checked={enabledDate}
                    disabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
                  />
                }
                label='From'
              />
              <DashboardFieldDatepicker>
                <DatepickerS2E
                  hideTextFrom
                  disablePast
                  disabled={
                    !enabledDate || discount.status === DISCOUNT_STATUS.ARCHIVED
                  }
                  value={[
                    formik.values?.availability?.activatesAtLocal
                      ? moment(formik.values.availability.activatesAtLocal)
                          .utc()
                          .format('MM/DD/yy')
                      : null,
                    formik.values?.availability?.deactivatesAtLocal
                      ? moment(formik.values.availability.deactivatesAtLocal)
                          .utc()
                          .format('MM/DD/yy')
                      : null
                  ]}
                  onClose={(value) => {
                    if (value && value.length) {
                      formik.setFieldValue(
                        'availability.activatesAtLocal',
                        moment(value[0])
                          .utcOffset(0, true)
                          .startOf('day')
                          .format()
                      );
                      formik.setFieldValue(
                        'availability.deactivatesAtLocal',
                        moment(value[1])
                          .utcOffset(0, true)
                          .endOf('day')
                          .format()
                      );
                    }
                  }}
                />
              </DashboardFieldDatepicker>
              <ErrorSignWrapper
                className='ErrorSignWrapper'
                leftPosition='93px'
              >
                {formik.errors?.availability?.activatesAtLocal &&
                formik.touched?.availability?.activatesAtLocal ? (
                  <ErrorSign>
                    {formik.errors.availability.activatesAtLocal}
                  </ErrorSign>
                ) : null}
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w100 noWrap'>
              <SwitchS2E
                labelPlacement='start'
                label={`Promo Code ${enabledDiscount ? 'Enabled' : 'Disabled'}`}
                checked={enabledDiscount}
                onChange={(e) => {
                  if (!e.target.checked) {
                    formik.setFieldValue('promoCode', null);
                  } else {
                    formik.setFieldValue('promoCode', {
                      code: '',
                      usageLimit: null
                    });
                  }
                  setEnabledDiscount(e.target.checked);
                }}
                name='checkedActive'
                isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
              />
              <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                  Customers will need to enter code before redeeming discount
                </div>
              </ButtonInfo>
            </DashboardField>
            {enabledDiscount && (
              <>
                <DashboardField className='dFlex w100'>
                  <DashboardFieldLabel>
                    <FieldLabel
                      className={cn('FieldLabelLg inline-label', {
                        isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                      })}
                    >
                      Promo Code
                    </FieldLabel>
                  </DashboardFieldLabel>
                  <DashboardFieldInner className='w60 FieldRelative'>
                    <FieldInput
                      className={cn('', {
                        error: formik.errors?.promoCode?.code,
                        isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                      })}
                      name='promoCode.code'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values?.promoCode?.code
                          ? formik.values.promoCode.code.toUpperCase()
                          : null
                      }
                      placeholder=''
                    />
                    <ErrorSignWrapper className='ErrorSignWrapper'>
                      {formik.errors?.promoCode?.code &&
                      formik.touched?.promoCode?.code ? (
                        <ErrorSign>{formik.errors.promoCode.code}</ErrorSign>
                      ) : null}
                    </ErrorSignWrapper>
                  </DashboardFieldInner>
                </DashboardField>
                {allowedServiceFeatures.includes('one_time_coupon') && (
                  <DashboardField className='w100 noWrap'>
                    <SwitchS2E
                      labelPlacement='start'
                      label='Single Use'
                      checked={
                        formik.values?.promoCode?.usageLimit?.type ===
                          'PerCustomer' &&
                        formik.values?.promoCode?.usageLimit?.maxUsages === 1
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          formik.setFieldValue(
                            'promoCode.usageLimit.type',
                            'PerCustomer'
                          );
                          formik.setFieldValue(
                            'promoCode.usageLimit.maxUsages',
                            1
                          );
                          formik.setFieldValue(
                            'promoCode.usageLimit.activatesAtLocal',
                            discount?.promoCode?.usageLimit?.activatesAtLocal ||
                              moment()
                                .utcOffset(
                                  Number(activeLocation?.Timezone) || 0,
                                  false
                                )
                                .utc(true)
                                .format()
                          );
                        } else {
                          formik.setFieldValue('promoCode.usageLimit', null);
                        }
                      }}
                      name='OneTimeMode.IsEnabled'
                      isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
                    />
                    <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                      <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                        Turning on Single Use will allow your Promo Code to be
                        redeemed only once per customer
                      </div>
                    </ButtonInfo>
                  </DashboardField>
                )}
              </>
            )}
            {/* {allowedServiceFeatures.includes('email_campaigns_active') && (
              <DashboardFieldInner>
                <DashboardField className='w100 noWrap'>
                  <SwitchS2E
                    labelPlacement='start'
                    label='Include in Email Marketing'
                    checked={formik.values?.marketing?.email?.isEnabled}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        dispatch(OPEN_EMAIL_MARKETING_TURN_OFF_MODAL(true));
                      } else {
                        formik.setFieldValue('marketing.email.isEnabled', true);
                      }
                    }}
                    name='marketing.email.isEanbled'
                    isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
                  />
                  <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                    <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                      Turning on Include in Email Marketing will display this
                      discount in your email marketing campaigns.
                    </div>
                  </ButtonInfo>
                  <ErrorSignWrapper className='ErrorSignWrapper noWrap'>
                    {formik.errors?.marketing?.email?.isEnabled &&
                    formik.touched?.marketing?.email?.isEnabled ? (
                      <ErrorSign>
                        {formik.errors.marketing.email.isEnabled}
                      </ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFieldInner>
            )} */}
            <DashboardField className='w100 noWrap'>
              <SwitchS2E
                labelPlacement='start'
                label='Display above Menu'
                checked={formik.values.isPromotional}
                onChange={formik.handleChange}
                name='isPromotional'
                isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
              />
              <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                  Promote discount at the top of your menu for maximum
                  visibility
                </div>
              </ButtonInfo>
            </DashboardField>
          </DashboardFields>
        </DashboardHolder>

        <div className='card-buttons'>
          <ButtonPrimary
            className={cn('ButtonDashboard', {
              disabled:
                buttonStatus || discount.status === DISCOUNT_STATUS.ARCHIVED
            })}
            onClick={(e) => {
              e.stopPropagation();

              if (
                formik.values?.rate?.type === DISCOUNT_TYPE.FIXED &&
                formik?.values?.rate?.value &&
                formik.values?.rate?.value >= formik.values?.subtotal?.min
              ) {
                formik.setFieldError(
                  'subtotal.min',
                  'Discount cannot be bigger than min order!'
                );
                return;
              }

              if (!enabledDiscount) {
                formik.setFieldValue('promoCode', null);
              }

              formik.handleSubmit(e);
            }}
          >
            <span>Apply</span>
          </ButtonPrimary>
        </div>
      </DashboardInner>
      {isEmailMarketingTurnOffModalOpen && (
        <EmailMarketingTurnOffModal
          isOpen={isEmailMarketingTurnOffModalOpen}
          setFieldValue={formik.setFieldValue}
        />
      )}
    </>
  );
};

Settings.propTypes = {
  formik: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
  enabledDiscount: PropTypes.bool.isRequired,
  setEnabledDiscount: PropTypes.func.isRequired,
  enabledDate: PropTypes.bool.isRequired,
  setEnabledDate: PropTypes.func.isRequired,
  buttonStatus: PropTypes.bool.isRequired,
  allowedServiceFeatures: PropTypes.array.isRequired
};

export default Settings;
