import {createReducer} from 'redux-act';
import {
  GET_LOCATION_DATA,
  GET_LOCATION_DATA_SUCCESS,
  GET_LOCATION_ID,
  GET_REPLIED_REVIEWS_SUCCESS,
  GET_TIMEZONE,
  GET_USER_DATA,
  GET_USER_DATA_SUCCESS,
  SET_ACTIVE_LOCATION,
  SET_BYOC_CONFIGURATION_SUCCESS,
  SET_FEEDBACK_STATUS_SUCCESS,
  SET_UBER_CONFIGURATION,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  SET_LOCATION_ID
} from './action';
import {updateObjectInArrayByKey} from '../../../helpers/functionUtils/arrayUtils';
import {clarityIdentify} from '../../../helpers/services/utils/clarityIdentify';

const initialState = () => ({
  locationId: '',
  activeLocation: null,
  isToast: true,
  userData: {},
  locationName: '',
  data: [],
  timezone: '',
  loading: false,
  locations: [],
  repliedReviews: []
});

const user = createReducer(
  {
    [GET_LOCATION_DATA]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_LOCATION_DATA_SUCCESS]: (state, payload) => {
      const [
        firstLocation = {
          _id: null,
          isToast: true,
          Timezone: '',
          FullName: ''
        }
      ] = payload;

      const selectedLocId =
        state.activeLocation?._id || localStorage.getItem('locationId');
      // Is it unnecessary to get activeLoc from state ??

      const activeLoc =
        payload.length &&
        payload.find((loc) =>
          selectedLocId
            ? loc?._id === selectedLocId
            : loc?._id === firstLocation?._id
        );

      const locationName = activeLoc
        ? activeLoc?.FullName
        : firstLocation.FullName;

      clarityIdentify(
        null,
        locationName,
        activeLoc ? activeLoc?._id : firstLocation._id
      );

      return {
        ...state,
        locationId: activeLoc ? activeLoc?._id : firstLocation._id,
        locationName,
        isToast: activeLoc ? activeLoc?.isToast : firstLocation.isToast,
        locations: payload,
        timezone: activeLoc ? activeLoc?.Timezone : firstLocation.Timezone,
        activeLocation: activeLoc || firstLocation,
        loading: false,
        MenuConfigId: activeLoc
          ? activeLoc?.MenuConfigId
          : firstLocation.MenuConfigId
      };
    },
    [GET_LOCATION_ID]: (state, payload) => {
      const loc = state.locations.find(
        (location) => location._id === payload.locationId
      );

      const locationName = loc ? loc.FullName : '';

      return {
        ...state,
        locationId: payload.locationId,
        locationName,
        activeLocation: loc,
        isToast: payload.isToast,
        loading: true
      };
    },
    [GET_TIMEZONE]: (state, payload) => ({
      ...state,
      timezone: payload
    }),
    [GET_USER_DATA]: (state) => ({
      ...state,
      loading: true
    }),
    [SET_ACTIVE_LOCATION]: (state, payload) => {
      localStorage.setItem('locationId', payload._id);

      clarityIdentify(null, payload?.FullName, payload?._id);
      return {
        ...state,
        activeLocation: payload
      };
    },
    [UPDATE_USER_DATA]: (state) => ({
      ...state,
      loading: true
    }),
    [UPDATE_USER_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: updateObjectInArrayByKey(state.data, payload, '_id'),
      loading: false
    }),

    [GET_USER_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      userData: payload,
      loading: false
    }),
    [GET_REPLIED_REVIEWS_SUCCESS]: (state, payload) => ({
      ...state,
      repliedReviews: payload
    }),
    [SET_BYOC_CONFIGURATION_SUCCESS]: (state, payload) => ({
      ...state,
      activeLocation: {
        ...state.activeLocation,
        UberEats: {
          ...state.activeLocation?.UberEats,
          Byoc: {...state.activeLocation?.UberEats.Byoc, IsEnabled: payload}
        }
      }
    }),
    [SET_UBER_CONFIGURATION]: (state, payload) => ({
      ...state,
      activeLocation: {
        ...state.activeLocation,
        UberEats: {...payload}
      }
    }),
    [SET_FEEDBACK_STATUS_SUCCESS]: (state, payload) => ({
      ...state,
      activeLocation: {
        ...state.activeLocation,
        isSurveyActivated: payload
      }
    }),
    [SET_LOCATION_ID]: (state, payload) => ({
      ...state,
      locationId: payload
    })
  },
  initialState()
);
export default user;
