import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {useDispatch, useSelector} from 'react-redux';
import {TableCell} from '@material-ui/core';
import cn from 'classnames';
import {
  TablePagination,
  TablePaginationText
} from 'routes/App/dashboard/styles';
import {FlexContainer, FlexItem, MainPaper} from 'components/layout';
import Headline from 'components/headline2/HeadlineComponentStyle';
import Table from 'components/Table';
import MERCHANT from 'helpers/constants/merchants';
import EditMerchant from 'routes/App/merchants/MerchantsUrls/modals/EditMerchant';
import ButtonMore from 'routes/App/merchants/components/ButtonMore';
import {ButtonOutline} from 'components/buttons';
import SearchField from 'components/fields/SearchField';
import {
  ACTIVATE_MERCHANT,
  CHANGE_NEW_MERCHANT,
  CLEAR_FILTERS,
  CLEAR_MERCHANT,
  GET_MERCHANTS_URLS,
  PAGE_CHANGE,
  SEARCH_CHANGE,
  SORT_CHANGE
} from 'store/merchants/action';
import FacebookInfo from 'routes/App/merchants/MerchantsUrls/modals/FacebookInfo';

const getTableModel = (dispatch) => [
  {
    title: 'Merchant',
    renderer: (item) => <TableCell>{item?.name}</TableCell>,
    width: '25%'
  },
  {
    title: 'Contact Full Name',
    width: '25%',
    renderer: (item) => (
      <TableCell>
        {Array.isArray(item?.users) &&
          `${item?.users[0]?.firstName || ''} ${
            item?.users[0]?.lastName || ''
          }`}
      </TableCell>
    )
  },
  {
    title: 'Email',
    width: '25%',
    renderer: (item) => (
      <TableCell>
        {Array.isArray(item?.users) && item?.users[0]?.email}
      </TableCell>
    )
  },
  {
    renderer: (item) =>
      item?.users[0]?.id && (
        <ButtonMore
          itemsData={[
            {
              title: 'Activate',
              action: () => {
                dispatch(ACTIVATE_MERCHANT(item?.users[0]?.id));
              }
            }
          ]}
        />
      )
  }
];

export default function MerchantsUrls() {
  const dispatch = useDispatch();

  const {
    data,
    paging: {total, limit, pages, page},
    selectedRecord,
    search,
    sort,
    facebookPageInfoRequired,
    facebookBotProcessing
  } = useSelector((state) => state.merchantsReducer);
  const {_id, configName, appName, facebookPage} = facebookPageInfoRequired;

  useEffect(() => {
    return () => dispatch(CLEAR_FILTERS());
  }, []);

  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    dispatch(
      GET_MERCHANTS_URLS({page, search: encodeURIComponent(searchField), sort})
    );
  }, [page, sort]);

  const onPageChange = (newPage) => {
    dispatch(PAGE_CHANGE(newPage.selected));
  };

  const onSearchClick = () => {
    dispatch(SEARCH_CHANGE(searchField));
    dispatch(
      GET_MERCHANTS_URLS({page, search: encodeURIComponent(searchField), sort})
    );
  };

  const onClearSearchClick = () => {
    setSearchField('');
    dispatch(SEARCH_CHANGE(''));
    dispatch(GET_MERCHANTS_URLS({page, search: '', sort}));
  };

  const onChangeS2EUrl = (id, value) => {
    dispatch(CHANGE_NEW_MERCHANT(id, value));
  };

  const closeDialog = () => {
    dispatch(CLEAR_MERCHANT());
  };

  const onTableSort = ({sortBy, sortDirection}) => {
    dispatch(SORT_CHANGE({sortBy, sortDirection}));
  };

  return (
    <>
      <>
        <Headline className='marginBottomXs'>New Merchants</Headline>
        <FlexContainer>
          <FlexItem flex='1 1 auto' className='marginRightXs'>
            <SearchField
              className='marginBottomXs'
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              onClear={onClearSearchClick}
              onKeyPress={(e) => e.key === 'Enter' && onSearchClick()}
            />
          </FlexItem>
          <FlexItem>
            <ButtonOutline
              className={cn('ButtonMedium', {disabled: !searchField})}
              onClick={onSearchClick}
            >
              Search
            </ButtonOutline>
          </FlexItem>
        </FlexContainer>
        <MainPaper>
          {total ? (
            <Table
              tableModel={getTableModel(dispatch)}
              data={data}
              rowKey={MERCHANT.ID}
              sortBy={sort.sortBy}
              sortDirection={sort.sortDirection}
              onSort={onTableSort}
            />
          ) : (
            <div>There&apos;s no merchants here</div>
          )}

          <TablePagination>
            <TablePaginationText>
              Showing {limit > total ? total : limit} out of {total} data
            </TablePaginationText>
            <ReactPaginate
              previousLabel='previous'
              nextLabel='next'
              breakLabel='...'
              breakClassName='break-me'
              pageCount={pages}
              initialPage={page}
              forcePage={page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={onPageChange}
              containerClassName='pagination'
              subContainerClassName='pages pagination'
              activeClassName='active'
            />
          </TablePagination>
        </MainPaper>
      </>

      {selectedRecord && (
        <EditMerchant
          onClose={closeDialog}
          onApply={onChangeS2EUrl}
          initialValues={selectedRecord}
        />
      )}

      {!!Object.keys(facebookPageInfoRequired).length && (
        <FacebookInfo
          loading={facebookBotProcessing}
          initialValues={{
            _id,
            configName,
            appName,
            facebookPage
          }}
        />
      )}
    </>
  );
}
