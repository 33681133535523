import styled from 'styled-components';
import CloseIcon from 'assets/icon-close-white.svg';
import {Body1} from 'components/typography';

export const CountdownBanner = styled.div`
  background-color: #17c981;
  color: white;
  text-align: center;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: calc(100% - 272px);
  left: 272px;
  top: 74px;
  z-index: 10;

  .close-btn {
    position: absolute;
    right: 36px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 100;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    @media (max-width: 599px) {
      right: 10px;
      font-size: 25px;
    }
  }
  @media (max-width: 1250px) {
    top: 96px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    top: 70px;
  }
  @media (max-width: 599px) {
    top: 102px;
    padding: 5px 8px;
  }
`;

export const CountdownTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 599px) {
    font-size: 11px;
  }
`;

export const CountdownTimer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

export const CountdownTimerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
`;

export const CountdownFlip = styled.span`
  position: relative;
  width: 60px;
  height: 60px;
  font-size: 46px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  line-height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .flip-number {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    font-size: 40px;
  }
  @media (max-width: 599px) {
    font-size: 28px;
  }
`;

export const CountdownTimeLabel = styled.span`
  font-size: 12px;
  color: #bcffe4;
  text-transform: uppercase;
  margin-top: -10px;
  font-weight: 400;
  @media (max-width: 599px) {
    font-size: 9px;
  }
`;

export const ExpiredBannerWrapper = styled.div`
  position: fixed;
  top: 73px;
  right: 0;
  width: calc(100% - 272px);
  min-height: 60px;
  z-index: 10;
  background-color: #fca600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 23px;
  @media (max-width: 1250px) {
    top: 96px;
    width: 100%;
  }
  @media (max-width: 1023px) {
    padding: 0 15px;
  }
  @media (max-width: 767px) {
    top: 70px;
    min-height: 40px;
  }
  @media (max-width: 599px) {
    top: 102px;
  }
  @media (max-width: 460px) {
    padding: 0 10px;
  }
`;

export const ExpiredBannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: default;
`;

export const ExpiredBannerClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${CloseIcon});
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  cursor: pointer;
  width: 14px;
  height: 14px;
  transition-property: transform;
  transition-duration: 0.3s;
  &:hover {
    transform: rotate(90deg);
  }
`;
export const ExpiredBannerIcon = styled.img`
  flex: 0 0 auto;
  font-size: 0;
  margin-right: 12px;
  @media (max-width: 460px) {
    margin-right: 8px;
  }
  svg {
    max-width: 44px;
    @media (max-width: 1023px) {
      max-width: 36px;
    }
    @media (max-width: 767px) {
      max-width: 26px;
    }
    @media (max-width: 460px) {
      max-width: 24px;
    }
  }
`;

export const ExpiredBannerUnderlineText = styled(Body1)`
  font-size: 18px;
  color: #fff;
  flex: 0 1 auto;
  text-align: left;
  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: 1023px) {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.1;
  }
  @media (max-width: 599px) {
    font-size: 11px;
  }
`;
