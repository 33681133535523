// eslint-disable-next-line import/prefer-default-export
export const DISCOUNT_V2 = {
  ID: 'id',
  STATUS: 'status',
  TYPE: 'rate.type',
  DISCOUNT_VALUE: 'rate.value',
  NAME: 'name',
  MIN_ORDER: 'subtotal.min',
  START_DATE: 'availability.activatesAtLocal',
  END_DATE: 'availability.deactivatesAtLocal',
  EMAIL_MARKETING: 'EmailMarketing'
};

export const DISCOUNT_MODALS_V2 = {
  SELECT: 'selectDiscount',
  TURN_ON: 'turnOnDiscount',
  DELETE: 'deleteDiscount',
  DISABLE: 'disableDiscount'
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: 'Percentage', // 1
  FIXED: 'Fixed', // 0
  BOGO: 'Bogo',
  SPECIFIC_ITEMS: 'SpecificItems',
  WHOLE_CART: 'WholeCart'
};

export const DISCOUNT_STATUS = {
  ACTIVE: 'Active', // 1
  ARCHIVED: 'Archived' // 0
};

export const FIELDS_TO_REMOVE = [
  'id',
  'createdAt',
  'updatedAt',
  'version',
  'status'
];
