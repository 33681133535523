import React, {memo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import IconDollarType from 'assets/iconDollar';
import IconPercentType from 'assets/iconPercent';
import PopperActions from 'components/PopperActions';
import TableComponent from 'components/TableComponent';
import {setPopperActions} from 'routes/App/discountsV2/helpers';
import {DISCOUNT_TYPE, DISCOUNT_V2} from 'helpers/constants/discountsV2';
import {FlexContainer, FlexItem} from 'components/layout';
import DiscountsTableWrapper from 'routes/App/discountsV2/styles';
import {isEmailMarketingTrialExpired} from 'helpers/functionUtils/emailMarketing';

const DiscountsTable = (props) => {
  const {
    discounts,
    loading,
    onSort,
    discountDirection,
    orderBy = DISCOUNT_V2.NAME,
    status,
    popperActions,
    allowedServiceFeatures,
    emailMarketingTrialDate,
    timezone
  } = props;

  const getFieldsConfig = () => [
    {
      title: 'Name',
      fieldName: DISCOUNT_V2.NAME,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <FlexContainer className='align-center'>
          <FlexItem>{data[DISCOUNT_V2.NAME]}</FlexItem>
        </FlexContainer>
      )
    },
    {
      title: 'Type',
      fieldName: DISCOUNT_V2.TYPE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <div className='IconDiscounts'>
          {data?.type === DISCOUNT_TYPE.WHOLE_CART &&
            data?.rate?.type === DISCOUNT_TYPE.PERCENTAGE && (
              <div className='IconPercentType'>
                <IconPercentType />
              </div>
            )}
          {data?.type === DISCOUNT_TYPE.WHOLE_CART &&
            data?.rate?.type === DISCOUNT_TYPE.FIXED && (
              <div className='IconDollarType'>
                <IconDollarType />
              </div>
            )}
          {data?.type === DISCOUNT_TYPE.BOGO && (
            <div
              className='IconBogoType'
              style={{fontWeight: 700, color: '#929cb9'}}
            >
              BOGO
            </div>
          )}
          {data?.type === DISCOUNT_TYPE.SPECIFIC_ITEMS && (
            <div
              className='IconSpecificItems'
              style={{fontWeight: 700, color: '#929cb9'}}
            >
              ITEM{' '}
              {data?.rate?.type === DISCOUNT_TYPE.FIXED && <IconDollarType />}
              {data?.rate?.type === DISCOUNT_TYPE.PERCENTAGE && (
                <IconPercentType />
              )}
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Discount value',
      fieldName: DISCOUNT_V2.DISCOUNT_VALUE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <>{data?.type === DISCOUNT_TYPE.BOGO ? '' : data?.rate?.value}</>
      )
    },
    {
      title: 'Min Order',
      fieldName: DISCOUNT_V2.MIN_ORDER,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <>{data?.subtotal?.min ? `$ ${data.subtotal.min}` : ''}</>
      )
    },
    {
      title: 'Promo Code',
      fieldName: DISCOUNT_V2.MIN_ORDER,
      sortable: false,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => <>{data?.promoCode?.code || ''}</>
    },
    ...(allowedServiceFeatures.includes('email_campaigns_active')
      ? [
          {
            title: 'Email marketing',
            fieldName: DISCOUNT_V2.EMAIL_MARKETING,
            sortable: false,
            onCellClick: (discount) => popperActions.editAction(discount),
            renderer: (data) => (
              <div className='greenText'>
                {data?.marketing?.email?.isEnabled ? 'Enabled' : ''}
              </div>
            )
          }
        ]
      : []),
    {
      title: 'Start date',
      fieldName: DISCOUNT_V2.START_DATE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <>
          {data?.availability?.activatesAtLocal
            ? moment(data.availability.activatesAtLocal).utc().format('MMMM D')
            : 'Always On'}
        </>
      )
    },
    {
      title: 'End date',
      fieldName: DISCOUNT_V2.END_DATE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <>
          {data?.availability?.deactivatesAtLocal
            ? moment(data.availability.deactivatesAtLocal)
                .utc()
                .format('MMMM D')
            : 'Always On'}
        </>
      )
    },
    {
      renderer: (data) => {
        return (
          <PopperActions
            popperActions={() =>
              setPopperActions(
                popperActions.editAction,
                popperActions.duplicateAction,
                popperActions.disableAction,
                popperActions.deleteAction,
                status
              )
            }
            row={data}
          />
        );
      }
    }
  ];

  return (
    <DiscountsTableWrapper>
      <TableComponent
        fields={getFieldsConfig()}
        data={discounts}
        loading={loading}
        rowKeyPath='id'
        onSort={onSort}
        orderBy={orderBy}
        orderDirection={discountDirection}
      />
    </DiscountsTableWrapper>
  );
};

DiscountsTable.propTypes = {
  orderBy: PropTypes.string,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  discounts: PropTypes.array.isRequired,
  discountDirection: PropTypes.string,
  status: PropTypes.string.isRequired,
  popperActions: PropTypes.objectOf({
    editAction: PropTypes.func,
    duplicateAction: PropTypes.func,
    disableAction: PropTypes.func,
    deleteAction: PropTypes.func
  }).isRequired,
  allowedServiceFeatures: PropTypes.array.isRequired,
  emailMarketingTrialDate: PropTypes.string,
  timezone: PropTypes.string.isRequired
};

DiscountsTable.defaultProps = {
  orderBy: '',
  loading: false,
  onSort: () => {},
  discountDirection: '',
  emailMarketingTrialDate: null
};

export default memo(DiscountsTable);
