import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {nanoid} from 'nanoid';
import {useDispatch, useSelector} from 'react-redux';
import {
  InputCounter,
  InputCounterBtn,
  InputCounterField,
  PartialRefundContainer,
  PartialRefundItem,
  PartialRefundItemRside,
  PartialRefundItems,
  PartialRefundModifiersGroupTitle,
  PartialRefundSectionTitle
} from 'routes/App/Managers/RefundsCalculator/styles';
import CheckboxS2E from 'components/Checkbox';
import Body3 from 'components/typography/Body3';
import Subtitle3 from 'components/typography/Subtitle3';

import {
  RC_ADJUSTMENTS_CHECK,
  RC_FEES_AND_TAXES_CHECK,
  RC_GENERATE_ORDER_REFUND,
  RC_HANDLE_CHECK,
  RC_QUANTITY_CHANGE
} from 'store/refundsCalculator/action';

const getPrice = (item) => {
  return (item.checked
    ? item.selected * item.price
    : item.quantity * item.price
  ).toFixed(2);
};

const taxAndFeesFields = {
  tip: 'Tip',
  deliveryFee: 'Delivery fee'
  // tax: 'Tax'
};

const PartialRefund = ({typeValue, setFaultError}) => {
  const dispatch = useDispatch();
  const {
    orderData,
    partialRefCheckboxes,
    locationFault,
    priceAdjustmentIds
  } = useSelector((state) => state.refundsCalculator);

  const submitChanges = () => {
    if (locationFault) {
      dispatch(RC_GENERATE_ORDER_REFUND(typeValue));
    } else {
      setFaultError('Mandatory field');
    }
  };

  const taxesAndFeesCheck = (key) => {
    dispatch(RC_FEES_AND_TAXES_CHECK(key));
    submitChanges();
  };

  const adjustmentsCheck = (id) => {
    dispatch(RC_ADJUSTMENTS_CHECK(id));
    submitChanges();
  };

  return (
    <PartialRefundContainer>
      <PartialRefundItems>
        {orderData?.items?.map((item, index) => (
          <>
            {item?.sectionName && (
              <PartialRefundSectionTitle>
                {item.sectionName}
              </PartialRefundSectionTitle>
            )}
            {item?.modifierGroupName && (
              <PartialRefundModifiersGroupTitle
                modifierLevel={item.path.length}
                className={cn({
                  modifier: item.path.length > 1
                })}
              >
                {item.modifierGroupName}
              </PartialRefundModifiersGroupTitle>
            )}
            <PartialRefundItem modifierLevel={item.path.length} key={nanoid()}>
              <FormControl
                className={cn({
                  modifier: item.path.length > 1
                })}
              >
                <CheckboxS2E
                  checked={item.checked}
                  onChange={(e) => {
                    dispatch(
                      RC_HANDLE_CHECK({index, checked: e.target.checked})
                    );
                    submitChanges();
                  }}
                  name='item'
                  label={
                    <div>
                      <div>{`${item.quantity}X ${item.name}`}</div>
                      {item?.specialRequest && (
                        <div className='itemSpecialNotes'>
                          ({item?.specialRequest})
                        </div>
                      )}
                    </div>
                  }
                />
              </FormControl>
              <PartialRefundItemRside>
                {item.checked && item.quantity > 1 && (
                  <InputCounter>
                    <InputCounterBtn
                      className={cn('btnPlus', {
                        disabled: item.selected === item.quantity
                      })}
                      onClick={() => {
                        dispatch(
                          RC_QUANTITY_CHANGE({
                            index,
                            value: item.selected + 1
                          })
                        );
                        submitChanges();
                      }}
                    />
                    <InputCounterField value={item.selected} readonly />
                    <InputCounterBtn
                      className={cn('btnMinus', {
                        disabled: item.selected === 1
                      })}
                      onClick={() => {
                        dispatch(
                          RC_QUANTITY_CHANGE({
                            index,
                            value: item.selected - 1
                          })
                        );
                        submitChanges();
                      }}
                    />
                  </InputCounter>
                )}
                <Body3 className='priceBlock'>${getPrice(item)}</Body3>
              </PartialRefundItemRside>
            </PartialRefundItem>
          </>
        ))}
      </PartialRefundItems>
      <PartialRefundItems className='PartialRefundItemsBold'>
        {!!orderData?.priceAdjustments?.length &&
          orderData.priceAdjustments.map((adjustment) => (
            <PartialRefundItem key={nanoid()}>
              <FormControl>
                <CheckboxS2E
                  checked={priceAdjustmentIds.includes(adjustment.id)}
                  onChange={() => {
                    adjustmentsCheck(adjustment.id);
                  }}
                  name={adjustment.id}
                  label={`Adjustment for ${adjustment.reason}`}
                />
              </FormControl>
              <PartialRefundItemRside>
                <Subtitle3>
                  ${Number(adjustment.totalAmount).toFixed(2)}
                </Subtitle3>
              </PartialRefundItemRside>
            </PartialRefundItem>
          ))}
        {Object.keys(taxAndFeesFields).map((key) => (
          <PartialRefundItem key={nanoid()}>
            <FormControl>
              <CheckboxS2E
                checked={partialRefCheckboxes[key]}
                onChange={() => {
                  taxesAndFeesCheck(key);
                }}
                name={key}
                label={taxAndFeesFields[key]}
              />
            </FormControl>
            <PartialRefundItemRside>
              <Subtitle3>${Number(orderData[key]).toFixed(2)}</Subtitle3>
            </PartialRefundItemRside>
          </PartialRefundItem>
        ))}
      </PartialRefundItems>
    </PartialRefundContainer>
  );
};

PartialRefund.propTypes = {
  typeValue: PropTypes.number.isRequired,
  setFaultError: PropTypes.func
};

PartialRefund.defaultProps = {
  setFaultError: () => {}
};

export default PartialRefund;
