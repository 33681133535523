import {createAction} from 'redux-act';

export const CLEAR_STATE = createAction('discountsV2/CLEAR_STATE');
export const CLEAR_DISCOUNTS = createAction('discountsV2/CLEAR_DISCOUNTS');
export const GET_DISCOUNTS = createAction('discountsV2/GET_DISCOUNTS');
export const GET_DISCOUNTS_SUCCESS = createAction(
  'discountsV2/GET_DISCOUNTS_SUCCESS'
);
export const GET_DISCOUNT = createAction('discountsV2/GET_DISCOUNT');
export const GET_DISCOUNT_SUCCESS = createAction(
  'discountsV2/GET_DISCOUNT_SUCCESS'
);
export const CREATE_DISCOUNT = createAction('discountsV2/CREATE_DISCOUNT');
export const CREATE_DISCOUNT_SUCCESS = createAction(
  'discountsV2/CREATE_DISCOUNT_SUCCESS'
);
export const SET_BUTTON_STATUS = createAction('discountsV2/SET_BUTTON_STATUS');
export const SET_LOADING = createAction('discountsV2/SET_LOADING');
export const UPDATE_DISCOUNT = createAction('discountsV2/UPDATE_DISCOUNT');
export const UPDATE_DISCOUNT_SUCCESS = createAction(
  'discountsV2/UPDATE_DISCOUNT_SUCCESS'
);
export const ARCHIVE_DISCOUNT = createAction('discountsV2/ARCHIVE_DISCOUNT');
export const ARCHIVE_DISCOUNT_SUCCESS = createAction(
  'discountsV2/ARCHIVE_DISCOUNT_SUCCESS'
);
export const DELETE_DISCOUNT = createAction('discountsV2/DELETE_DISCOUNT');
export const DELETE_DISCOUNT_SUCCESS = createAction(
  'discountsV2/DELETE_DISCOUNT_SUCCESS'
);
export const PUSH_MODALS_STACK = createAction('discountsV2/PUSH_MODALS_STACK');
export const SHIFT_MODALS_STACK = createAction(
  'discountsV2/SHIFT_MODALS_STACK'
);
export const SET_DISCOUNT = createAction('discountsV2/SET_DISCOUNT');
export const SET_WARNING_MODAL = createAction('discountsV2/SET_WARNING_MODAL');
export const SET_SORTING = createAction('discountsV2/SET_SORTING');

export const SET_EMAIL_MARKETING_TOGGLE_ERROR = createAction(
  'discountsV2/SET_EMAIL_MARKETING_TOGGLE_ERROR'
);

export const OPEN_EMAIL_MARKETING_TURN_OFF_MODAL = createAction(
  'discountsV2/OPEN_EMAIL_MARKETING_TURN_OFF_MODAL'
);

export const GET_BOGO_ITEMS = createAction('discountsV2/GET_BOGO_ITEMS');
export const GET_BOGO_ITEMS_SUCCESS = createAction(
  'discountsV2/GET_BOGO_ITEMS_SUCCESS'
);
export const SET_BOGO_ITEMS_LOADING = createAction(
  'discountsV2/SET_BOGO_ITEMS_LOADING'
);
export const CLEAR_DISCOUNT = createAction('discountsV2/CLEAR_DISCOUNT');

export const GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS = createAction(
  'discountsV2/GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS'
);

export const GET_DISCOUNTS_FOR_ITEMS = createAction(
  'discountsV2/GET_DISCOUNTS_FOR_ITEMS'
);
