import {GET, POST} from 'helpers/services/constants/API_CONSTANTS';
import {
  RC_GET_ORDER,
  RC_GENERATE_ORDER_REFUND,
  RC_MAKE_ORDER_REFUND,
  MERCHANT_CHANGE_USER
} from 'helpers/services/constants/API_ENDPOINTS';
import {request} from 'helpers/services/utils/request';

export const rcGetOrderApi = async (invoiceId) => {
  return request(GET, RC_GET_ORDER(invoiceId), {});
};

export const rcGenerateOrderRefundApi = async (id, data) => {
  return request(POST, RC_GENERATE_ORDER_REFUND(id), {data});
};
export const rcMakeOrderRefundApi = async (id, data) => {
  return request(POST, RC_MAKE_ORDER_REFUND(id), {data});
};
export const rcOpenDashboardApi = async (id) => {
  return request(GET, MERCHANT_CHANGE_USER(id));
};
