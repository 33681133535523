import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import {useDispatch, useSelector} from 'react-redux';
import {DashboardContent} from 'routes/App/dashboard/styles';
import Settings from 'routes/App/discountsV2/DiscountWizard/Settings';
import {
  getButtonStatus,
  getDiscount,
  getDiscountsLoading
} from 'store/discountsV2/selectors';
import Loading from 'components/shared/Utils/Loading';
import DiscountV2Schema from 'helpers/services/formValidationSchemas/DiscountV2Schema';
import {
  CREATE_DISCOUNT,
  SET_WARNING_MODAL,
  UPDATE_DISCOUNT,
  SET_BUTTON_STATUS,
  GET_DISCOUNT
} from 'store/discountsV2/action';
import {usePrevious} from 'hooks/usePrevious';
import {DISCOUNT_TYPE} from 'helpers/constants/discountsV2';
import BogoSettings from './BogoSettings';
import ItemDiscountSettings from './ItemDiscountSettings';

import('./index.css');

const DiscountWizardContainer = () => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const {params, url, path} = useRouteMatch();
  const history = useHistory();
  const discount = getDiscount();
  const buttonStatus = getButtonStatus();
  const loading = getDiscountsLoading();
  const [enabledDiscount, setEnabledDiscount] = useState(
    discount?.id ? discount.promoCode !== null : true
  );
  const [enabledDate, setEnabledDate] = useState(
    discount?.availability?.activatesAtLocal ||
      discount?.availability?.deactivatesAtLocal
  );
  const {AllowedServiceFeatures} = useSelector(
    (state) => state.user.activeLocation
  );

  const {locationId} = useSelector((state) => state.user);
  const prevLocation = usePrevious(locationId);

  const redirectToPreviousRoute = (state = {}) => {
    history.push(
      params.back === 'history'
        ? '/discounts-v2/history'
        : '/discounts-v2/active',
      state
    );
  };

  useEffect(() => {
    if (prevLocation) {
      redirectToPreviousRoute();
    }
  }, [locationId]);

  useEffect(() => {
    dispatch(SET_WARNING_MODAL(false));
    if (params.id) {
      dispatch(GET_DISCOUNT(params.id));
    }
  }, []);

  return (
    <DashboardContent className='DashboardContent'>
      {loading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            ...discount
          }}
          innerRef={formikRef}
          validationSchema={DiscountV2Schema}
          enableReinitialize
          onSubmit={(values, {setFieldError}) => {
            const payload = {...values};

            if (enabledDiscount && !payload?.promoCode?.code) {
              setFieldError(
                'promoCode.code',
                'Promo Code field cannot be empty!'
              );
              return;
            }

            if (
              (enabledDate &&
                (!payload?.availability?.activatesAtLocal ||
                  payload?.availability?.activatesAtLocal ===
                    'Invalid date')) ||
              (enabledDate &&
                (!payload?.availability?.deactivatesAtLocal ||
                  payload?.availability?.deactivatesAtLocal === 'Invalid date'))
            ) {
              setFieldError(
                'availability.activatesAtLocal',
                'Please select start and end date!'
              );
              return;
            }

            if (
              (payload?.rate?.type === DISCOUNT_TYPE.BOGO ||
                payload?.type === DISCOUNT_TYPE.BOGO) &&
              (!payload?.bogo?.discounted || !payload?.bogo?.discounted?.length)
            ) {
              setFieldError(
                'bogo.discounted',
                'Please select items for BOGO discount!'
              );
              return;
            }

            if (
              (payload?.rate?.type === DISCOUNT_TYPE.SPECIFIC_ITEMS ||
                payload?.type === DISCOUNT_TYPE.SPECIFIC_ITEMS) &&
              (!payload?.specificItems?.discounted ||
                !payload?.specificItems?.discounted?.length)
            ) {
              setFieldError(
                'specificItems.discounted',
                'Please select items for discount!'
              );
              return;
            }

            if (payload?.promoCode?.code) {
              payload.promoCode.code = payload?.promoCode?.code?.toUpperCase();
            }
            if (
              payload?.rate?.type === DISCOUNT_TYPE.PERCENTAGE &&
              !payload?.subtotal?.min
            ) {
              payload.subtotal.min = 0;
            }
            if (
              payload?.rate?.type === DISCOUNT_TYPE.BOGO ||
              payload?.type === DISCOUNT_TYPE.BOGO
            ) {
              payload.type = DISCOUNT_TYPE.BOGO;
              payload.rate.type = DISCOUNT_TYPE.PERCENTAGE;
              payload.rate.value = 100;
            }

            dispatch(SET_BUTTON_STATUS(true));
            if (discount.id) {
              dispatch(
                UPDATE_DISCOUNT({
                  payload: {
                    id: discount.id,
                    discount: payload
                  }
                })
              );
            } else {
              dispatch(
                CREATE_DISCOUNT({
                  payload
                })
              );
            }
          }}
        >
          {(formik) => (
            <>
              <ConnectedFocusError />
              {(formik?.values?.type === DISCOUNT_TYPE.BOGO ||
                formik?.values?.bogo?.discounted?.length) && (
                <BogoSettings
                  discount={discount}
                  formik={formik}
                  enabledDiscount={enabledDiscount}
                  setEnabledDiscount={setEnabledDiscount}
                  enabledDate={enabledDate}
                  setEnabledDate={setEnabledDate}
                  buttonStatus={buttonStatus}
                  allowedServiceFeatures={AllowedServiceFeatures}
                />
              )}
              {(formik?.values?.type === DISCOUNT_TYPE.SPECIFIC_ITEMS ||
                formik?.values?.specificItems?.discounted?.length) && (
                <ItemDiscountSettings
                  discount={discount}
                  formik={formik}
                  enabledDiscount={enabledDiscount}
                  setEnabledDiscount={setEnabledDiscount}
                  enabledDate={enabledDate}
                  setEnabledDate={setEnabledDate}
                  buttonStatus={buttonStatus}
                  allowedServiceFeatures={AllowedServiceFeatures}
                />
              )}
              {formik?.values?.type === DISCOUNT_TYPE.WHOLE_CART && (
                <Settings
                  discount={discount}
                  formik={formik}
                  enabledDiscount={enabledDiscount}
                  setEnabledDiscount={setEnabledDiscount}
                  enabledDate={enabledDate}
                  setEnabledDate={setEnabledDate}
                  buttonStatus={buttonStatus}
                  allowedServiceFeatures={AllowedServiceFeatures}
                />
              )}
            </>
          )}
        </Formik>
      )}
    </DashboardContent>
  );
};

export default DiscountWizardContainer;
