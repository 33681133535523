import {takeEvery, put, select} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  POST_CONTACT_FORM,
  GET_MARKETING_STATISTIC,
  GET_MARKETING_STATISTIC_SUCCESS,
  SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER,
  SET_EMAIL_MARKETING_TRIAL_DATE,
  GET_EMAIL_MARKETING_TRIAL_DATE
} from 'store/emailMarketing/action';
import {
  postContactFormApi,
  getStatisticApi,
  getEmailMarketingTrialDateApi
} from 'helpers/services/api/emailMarketing';
import sendGtmEvent from 'helpers/services/utils/gtm';

function* postContactForm({payload}) {
  try {
    const {data} = yield postContactFormApi(payload);
    if (data) {
      toast.success('Form was successfully submitted');
      const {activeLocation} = yield select((state) => state.user);
      sendGtmEvent('submit-marketing-form', {
        location_name: activeLocation?.FullName,
        location_id: activeLocation?._id
      });
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getStatistic() {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const {data} = yield getStatisticApi(locationId);
    if (data) {
      yield put(GET_MARKETING_STATISTIC_SUCCESS(data));
    }
  } catch (error) {
    yield put(SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER(false));
    console.log('SAGA ERROR', error);
  }
}
function* getEmailMarketingTrialDate() {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const {data} = yield getEmailMarketingTrialDateApi(locationId);
    if (data) {
      yield put(
        SET_EMAIL_MARKETING_TRIAL_DATE({
          startDate: data.startDate,
          status: data.status
        })
      );
    }
  } catch (error) {
    yield put(SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER(false));
    console.log('SAGA ERROR', error);
  }
}
function* emailMarketingSaga() {
  yield takeEvery(POST_CONTACT_FORM, postContactForm);
  yield takeEvery(GET_MARKETING_STATISTIC, getStatistic);
  yield takeEvery(GET_EMAIL_MARKETING_TRIAL_DATE, getEmailMarketingTrialDate);
}

export default emailMarketingSaga;
