import styled from 'styled-components';

export const DiscountsSave = styled.div`
  position: relative;
  margin-right: 16px;

  &.PublishOverlay {
    z-index: 13;
  }
  .PublishDrop {
    &::after {
      right: 140px;
      @media (max-width: 1650px) {
        right: 90px;
      }
    }
  }
  .ButtonSave {
    min-width: 180px;
    padding: 10px 5px;
    overflow: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 26px;
      height: 26px;
      z-index: 7;
      flex: 0 0 auto;
      //@media (max-width: 599px) {
      //  width: 24px;
      //  height: 24px;
      //}
    }
    span {
      padding-left: 10px;
      //@media (max-width: 599px) {
      //  display: none;
      //}
    }
    //@media (max-width: 599px) {
    //  width: 44px;
    //  height: 44px;
    //  padding: 9px 5px;
    //}
  }
  @media (max-width: 767px) {
    .PublishDrop {
      right: inherit;
      left: -15px;

      &::after {
        right: inherit;
        left: 80px;
      }
    }
  }
  @media (max-width: 599px) {
    .PublishDrop {
      right: inherit;
      left: 0;

      &::after {
        right: inherit;
        left: 70px;
      }
    }
  }
  @media (max-width: 470px) {
    .PublishDrop {
      left: 50%;
      transform: translateX(-50%);
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .IconSpecificItems {
    display: flex;
    align-items: center;
    svg {
      height: 18px;
    }
  }
`;

export default DiscountsSave;
