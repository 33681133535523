import {toast} from 'react-toastify';
import {put, select, takeEvery} from 'redux-saga/effects';
import {
  ACTIVATE_VN,
  GET_INFO_VN,
  GET_INFO_VN_SUCCESS,
  UPDATE_VN,
  CREATE_VN,
  CREATE_TWILIO,
  SET_SHOW_MODAL,
  SET_LOADING,
  SET_STATUS_FOR_EDIT,
  SET_PHONE_NUMBER_UPDATED,
  DISABLED_VN
} from './action';
import {
  getLocationVNStatus,
  activateVirtualNumber,
  createTwilio,
  createVirtualNumber,
  patchVirtualNumber,
  disableVirtualNumber
} from '../../helpers/services/api/virtualNumber';

const locationId = ({user}) => user.locationId;

const isDataChanged = (newDataObj = {}, storeDataObj = {}) => {
  const keysArr = Object.keys(newDataObj);

  return keysArr.some((key) => {
    if (Array.isArray(newDataObj[key])) {
      return (
        JSON.stringify(newDataObj[key]?.sort()) !==
        JSON.stringify(storeDataObj[key]?.sort())
      );
    }
    return newDataObj[key] !== storeDataObj[key];
  });
};

function* getInfoVNSaga() {
  try {
    yield put(SET_LOADING(true));
    const id = yield select(locationId);
    const {data} = yield getLocationVNStatus(id);
    if (data) {
      yield put(GET_INFO_VN_SUCCESS(data));
      yield put(SET_LOADING(false));
    }
  } catch (error) {
    yield put(SET_LOADING(false));
  }
}

function* activateVNSaga() {
  try {
    yield put(SET_LOADING(true));
    const id = yield select(locationId);
    yield activateVirtualNumber(id);
    yield put(SET_SHOW_MODAL(true));
    yield getInfoVNSaga();
  } catch (error) {
    yield put(SET_LOADING(false));
  }
}

function* disableVNSaga() {
  try {
    yield put(SET_LOADING(true));
    const id = yield select(locationId);
    yield disableVirtualNumber(id);
    yield getInfoVNSaga();
  } catch (error) {
    yield put(SET_LOADING(false));
  }
}

function* createTwilioSaga({payload}) {
  try {
    yield put(SET_LOADING(true));
    const id = yield select(locationId);
    yield createTwilio(id, payload);
    yield getInfoVNSaga();
  } catch (error) {
    yield put(SET_LOADING(false));
  }
}

function* createVNSaga({payload}) {
  try {
    yield put(SET_LOADING(true));
    const id = yield select(locationId);
    yield createVirtualNumber(id, payload);
    yield getInfoVNSaga();
  } catch (error) {
    yield put(SET_LOADING(false));
  }
}

function* updateVNSaga({payload}) {
  try {
    yield put(SET_LOADING(true));
    const id = yield select(locationId);
    const {info} = yield select((state) => state.virtualNumberReducer);
    yield patchVirtualNumber(id, payload);
    const {data} = yield getLocationVNStatus(id);
    if (data) {
      yield put(GET_INFO_VN_SUCCESS(data));
      if (payload.phone) {
        toast.success('The phone number has been updated!');
        yield put(SET_PHONE_NUMBER_UPDATED(true));
        if (data.isActive) {
          yield put(SET_STATUS_FOR_EDIT({isEdit: false}));
        } else {
          yield put(SET_STATUS_FOR_EDIT({status: 'CREATED'}));
        }
      }
      if (payload.slogan) {
        if (isDataChanged(payload, info)) {
          toast.success('The changes have been saved!');
        }

        yield put(SET_STATUS_FOR_EDIT({isEdit: false}));
      }
    }
    yield put(SET_LOADING(false));
  } catch (error) {
    yield put(SET_LOADING(false));
  }
}

export function* virtualNumberSaga() {
  yield takeEvery(GET_INFO_VN, getInfoVNSaga);
  yield takeEvery(ACTIVATE_VN, activateVNSaga);
  yield takeEvery(UPDATE_VN, updateVNSaga);
  yield takeEvery(CREATE_TWILIO, createTwilioSaga);
  yield takeEvery(CREATE_VN, createVNSaga);
  yield takeEvery(DISABLED_VN, disableVNSaga);
}

export default virtualNumberSaga;
