import {request} from 'helpers/services/utils/request';
import {GET, POST} from 'helpers/services/constants/API_CONSTANTS';
import {
  GET_MARKETING_STATISTIC,
  POST_CONTACT_FORM_EMAIL_MARKETING,
  GET_EMAIL_MARKETING_TRIAL_DATE
} from 'helpers/services/constants/API_ENDPOINTS';

export const postContactFormApi = async (data) => {
  return request(POST, POST_CONTACT_FORM_EMAIL_MARKETING(), {data});
};

export const getStatisticApi = async (locationId) => {
  return request(GET, GET_MARKETING_STATISTIC(locationId), null, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_MARKETING_API_TOKEN}`
    }
  });
};

export const getEmailMarketingTrialDateApi = async (locationId) => {
  return request(GET, GET_EMAIL_MARKETING_TRIAL_DATE(locationId), null);
};
