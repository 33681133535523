import * as Yup from 'yup';
import {DISCOUNT_TYPE} from 'helpers/constants/discountsV2';

export default Yup.object().shape({
  subtotal: Yup.object().shape({
    min: Yup.number('Min order field must be a number')
  }),
  rate: Yup.object().shape({
    type: Yup.string().nullable().required('Discount type is required'),
    value: Yup.number('Discount field must be a number')
      .positive('This field must be positive')
      .nullable()
      .when('type', {
        is: (type) =>
          type === DISCOUNT_TYPE.PERCENTAGE || type === DISCOUNT_TYPE.FIXED,
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired().nullable()
      })
  }),
  name: Yup.string()
    .required('Name field is required')
    .trim()
    .max(50, 'Max length of discount name is 50 characters'),
  promoCode: Yup.object()
    .shape({
      code: Yup.string()
        .matches(
          /^([a-zA-Z0-9_\-\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+)$/,
          'Promo code can contain only numbers and letters as well as -, _ symbols'
        )
        .max(20, 'Your promo code is too long, max length is 20 characters!')
    })
    .nullable()
});
