import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import cn from 'classnames';
import Modal from 'components/Modals/Modal';
import {DialogAction} from 'routes/App/dashboard/styles';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import {FlexContainer, FlexItem} from 'components/layout';
import DialogTitle from 'components/DialogTitle/styles';
import {CardsButtonGrid} from 'components/CardsButtonGrid/styles';
import {CardsButton} from 'components/CardsButton/styles';
import {DISCOUNT_TYPE} from 'helpers/constants/discountsV2';

const ModalSelectDiscount = ({
  onCloseAction,
  submitAction,
  handleChange,
  getValue
}) => {
  const selectedDiscountType = getValue('type');
  const selectedRateType = getValue('rate.type');
  const getClassName = (value) =>
    value === selectedDiscountType ||
    (selectedDiscountType === DISCOUNT_TYPE.WHOLE_CART &&
      value === selectedRateType)
      ? 'isActive'
      : null;

  const types = [
    {value: DISCOUNT_TYPE.PERCENTAGE},
    {value: DISCOUNT_TYPE.FIXED},
    {value: DISCOUNT_TYPE.SPECIFIC_ITEMS},
    {value: DISCOUNT_TYPE.BOGO}
  ];

  return (
    <Modal
      classParameter='DialogLg'
      onCloseAction={onCloseAction}
      title={
        <Box>
          <DialogTitle className='marginBottomLg'>
            Select a discount type
          </DialogTitle>
        </Box>
      }
      body={
        <>
          <CardsButtonGrid className='CardsButtonGridSelectDiscountV2'>
            {types.map(({value}) => (
              <CardsButton
                key={`DiscountType_${value}`}
                className={cn('CardsButton', getClassName(value))}
                onClick={() => {
                  if (
                    value === DISCOUNT_TYPE.FIXED ||
                    value === DISCOUNT_TYPE.PERCENTAGE
                  ) {
                    handleChange('type', DISCOUNT_TYPE.WHOLE_CART);
                    handleChange('rate.type', value);
                    handleChange('rate.value', null);
                  } else if (value === DISCOUNT_TYPE.BOGO) {
                    handleChange('type', value);
                    handleChange('rate.type', DISCOUNT_TYPE.PERCENTAGE);
                    handleChange('rate.value', 100);
                  } else {
                    handleChange('type', value);
                    handleChange('rate.type', null);
                    handleChange('rate.value', null);
                  }
                  handleChange(
                    'isPromotional',
                    value === DISCOUNT_TYPE.BOGO ||
                      value === DISCOUNT_TYPE.SPECIFIC_ITEMS
                  );
                }}
              >
                {value === DISCOUNT_TYPE.PERCENTAGE && (
                  <FlexContainer className='FlexContainerDiscountBogo'>
                    <div>Storewide Discount (%)</div>
                  </FlexContainer>
                )}
                {value === DISCOUNT_TYPE.FIXED && (
                  <FlexContainer className='FlexContainerDiscountBogo'>
                    <div>Storewide Discount ($)</div>
                  </FlexContainer>
                )}
                {value === DISCOUNT_TYPE.SPECIFIC_ITEMS && (
                  <FlexContainer className='FlexContainerDiscountBogo'>
                    <div>Discount Items</div>
                  </FlexContainer>
                )}
                {value === DISCOUNT_TYPE.BOGO && (
                  <FlexContainer className='FlexContainerDiscountBogo'>
                    <div>Buy 1, Get 1 Free</div>
                  </FlexContainer>
                )}
              </CardsButton>
            ))}
          </CardsButtonGrid>
          <DialogAction>
            <ButtonPrimary
              type='submit'
              className={!selectedDiscountType && 'disabled'}
              onClick={(e) => {
                // setTouched('subtotal.min');
                submitAction(e);
              }}
            >
              <span>Create Discount</span>
            </ButtonPrimary>
          </DialogAction>
        </>
      }
    />
  );
};

ModalSelectDiscount.propTypes = {
  onCloseAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired
};

export default ModalSelectDiscount;
