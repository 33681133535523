import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import Modal from 'components/Modals/Modal';
import {
  DialogAction,
  DialogItem,
  DialogItemIcon,
  DialogItems
} from 'routes/App/dashboard/styles';
import IconTypeOrderVolume from 'assets/IconOrderVolume';
import IconTypeItem from 'assets/IconItem';
import IconTypeNewCustomers from 'assets/IconNewCustomers';
import IconTypeCustomers from 'assets/IconCustomers';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import DialogTitle from 'components/DialogTitle/styles';
import Body1 from 'components/typography/Body1';

const ModalTurnOn = ({onCloseAction, submitAction, handleChange, getValue}) => {
  const selectedReason = getValue('creationReason');
  const getClassName = (reason) =>
    reason === selectedReason ? 'isActive' : null;

  const reasons = [
    {
      Icon: <IconTypeOrderVolume />,
      value: 'IncreaseOrderVolume',
      title: 'Increase Order Volume'
    },
    {
      Icon: <IconTypeItem />,
      value: 'IncreaseItemSales',
      title: 'Increase Item Sales'
    },
    {
      Icon: <IconTypeNewCustomers />,
      value: 'AttractNewCustomers',
      title: 'Attract New Customers'
    },
    {
      Icon: <IconTypeCustomers />,
      value: 'AttractReturningCustomers',
      title: 'Attract returning Customers'
    }
  ];
  return (
    <Modal
      onCloseAction={onCloseAction}
      width='720px'
      title={
        <Box color='#172751' textAlign='center'>
          <DialogTitle>
            Before you turn on this discount,
            <br />
            Select the <span>discount purpose</span>
          </DialogTitle>
          <Body1 className='marginTopSm'>
            Knowing this will help us provide smarter reports and
            recommendations in the future.
          </Body1>
        </Box>
      }
      body={
        <>
          <DialogItems className='DialogItemsTurnOnDiscount'>
            {reasons.map(({Icon, value, title}) => (
              <DialogItem
                key={`DiscountReason_${value}`}
                className={getClassName(value)}
                onClick={() => handleChange('creationReason', value)}
              >
                <DialogItemIcon>{Icon}</DialogItemIcon>
                <span>{title}</span>
              </DialogItem>
            ))}
          </DialogItems>
          <DialogAction>
            <ButtonPrimary
              className={!selectedReason ? 'disabled' : null}
              onClick={(e) => submitAction(e)}
            >
              <span>Apply</span>
            </ButtonPrimary>
          </DialogAction>
        </>
      }
    />
  );
};

ModalTurnOn.propTypes = {
  onCloseAction: PropTypes.array.isRequired,
  submitAction: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired
};

export default ModalTurnOn;
