import React, {memo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import get from 'lodash/get';
import {getModalsStack} from 'store/discountsV2/selectors';
import {DISCOUNT_MODALS_V2, DISCOUNT_TYPE} from 'helpers/constants/discountsV2';
import {
  PUSH_MODALS_STACK,
  SET_DISCOUNT,
  SHIFT_MODALS_STACK
} from 'store/discountsV2/action';
import ModalTurnOn from 'routes/App/discountsV2/DiscountsList/Modals/ModalTurnOn';
import ModalSelectDiscount from 'routes/App/discountsV2/DiscountsList/Modals/ModalSelectDiscount';
import ModalConfirm from 'routes/App/discountsV2/DiscountsList/Modals/ModalConfirm';
import DiscountV2Schema from 'helpers/services/formValidationSchemas/DiscountV2Schema';
import {getDiscountName} from 'routes/App/discountsV2/helpers';

const Modals = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const modalsStack = getModalsStack();

  const formik = useFormik({
    initialValues: {
      name: '',
      type: null,
      rate: {
        type: null,
        value: ''
      },
      subtotal: {
        min: '',
        max: null
      },
      fulfillment: {
        types: ['Pickup', 'Delivery'],
        delivery: null
      },
      customers: {
        types: ['New', 'Returning']
      },
      availability: {
        activatesAtLocal: null,
        deactivatesAtLocal: null,
        weekly: null
      },
      isPromotional: false,
      isExclusive: false,
      promoCode: {
        code: '',
        usageLimit: null
      },
      specificItems: null,
      combo: null,
      bogo: null,
      marketing: {
        email: {
          isEnabled: false
        }
      },
      creationReason: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formattedName = {...values};
      dispatch(SHIFT_MODALS_STACK());
      dispatch(SET_DISCOUNT(formattedName));
      history.push('/discounts-v2/wizard/create');
    }
  });

  const onCloseAction = () => {
    formik.resetForm();
    dispatch(SHIFT_MODALS_STACK());
  };

  const handleChangeField = (field, value) =>
    formik.setFieldValue(field, value);

  const getValue = (field) => get(formik.values, field);

  const getError = (field) => get(formik.errors, field);

  const getTouched = (field) => get(formik.touched, field);

  const setTouched = (field) => formik.setFieldTouched(field, true);

  const displayModal = (modals) => {
    switch (modals[0].type) {
      case DISCOUNT_MODALS_V2.SELECT:
        return (
          <ModalSelectDiscount
            handleChange={handleChangeField}
            getValue={getValue}
            onCloseAction={onCloseAction}
            submitAction={(e) => {
              e.stopPropagation();

              formik.handleSubmit();
            }}
          />
        );
      case DISCOUNT_MODALS_V2.TURN_ON:
        return (
          <ModalTurnOn
            handleChange={handleChangeField}
            getValue={getValue}
            onCloseAction={onCloseAction}
            submitAction={(e) => {
              e.stopPropagation();
              dispatch(SHIFT_MODALS_STACK());
              dispatch(PUSH_MODALS_STACK({type: DISCOUNT_MODALS_V2.SELECT}));
            }}
          />
        );
      case DISCOUNT_MODALS_V2.DELETE:
        return (
          <ModalConfirm
            onCloseAction={onCloseAction}
            actionType='Delete'
            discount={modals[0]?.discount}
          />
        );
      case DISCOUNT_MODALS_V2.DISABLE:
        return (
          <ModalConfirm
            onCloseAction={onCloseAction}
            actionType='Disable'
            discount={modals[0]?.discount}
          />
        );

      default:
        return null;
    }
  };

  if (!modalsStack.length) {
    return null;
  }
  return displayModal(modalsStack);
};

export default memo(Modals);
