/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import T from 'prop-types';
import {nanoid} from 'nanoid';
import moment from 'moment';
import {Form, Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import {TopLineNav} from '../../../../components/layout';
import LinkBack from '../../../../components/LinkBack/styles';
import DashboardContent from '../../../../components/DashboardContent/styles';
import DashboardInner from '../../../../components/DashboardInner/styles';
import {NavLink, useHistory} from 'react-router-dom';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DashboardField,
  DashboardFields,
  DashboardInnerDescription,
  DashboardText,
  DashboardTimeInner,
  DialogFieldRemove,
  DialogLinkButton,
  FieldInputDollar
} from '../../dashboard/styles';
import FieldLabel from '../../../../components/FieldLabel/styles';
import FieldInput from '../../../../components/FieldInput';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Subtitle6 from '../../../../components/typography/Subtitle6';
import DashboardTitle from '../../../../components/DashboardTitle/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {ButtonInfo, ButtonPrimary} from '../../../../components/buttons';
import {FormControl, RadioGroup, TextField} from '@material-ui/core';
import RadioS2E from '../../../../components/Radiobox';
import {MobileTimePicker} from '@material-ui/pickers';
import SingleDatepickerDrop from '../../../../components/SingleDatepickerDrop';
import cn from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SelectWrapper from '../../../../components/select/SelectComponentStyles';
import AutocompletedMapField from '../../settings/LocationMap/AutocompletedMapField';
import BookADriverSchema from '../../../../helpers/services/formValidationSchemas/BookADriverSchema';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import {useDispatch, useSelector} from 'react-redux';
import {
  HANDLE_FAIL_MODAL,
  POST_BAD_ORDER,
  GET_ADDRESSES,
  CLEAR_BAD_ORDER_DETAIL,
  CHECK_ADDRESS,
  GET_ADDRESSES_SUCCESS
} from '../../../../store/bookADriver/bookADriverCreate/action';
import OrderFail from '../Modals/OrderFail';
import FieldMaskInput from '../../../../components/FieldMaskInput';
import CardsButtonGrid from '../../../../components/CardsButtonGrid/styles';
import CardsButton from '../../../../components/CardsButton/styles';
import IconASAP from '../../../../assets/IconASAP';
import IconFutureDelivery from '../../../../assets/IconFutureDelivery';
import {
  integerFormatter,
  phoneNumberFormatter
} from '../../../../helpers/functionUtils/helpFunctions';
import BADRadioButtons from '../../../../components/BADRadioButtons/styles';
import BADCreateTotal from '../../../../components/BADCreateTotal/styles';
import ButtonClear from '../../../../components/buttons/ButtonClear/styles';
import Loading from '../../../../components/shared/Utils/Loading';
import BADStoreAddressErrorModal from '../Modals/BADStoreAddressErrorModal';
import Body1 from '../../../../components/typography/Body1';
import Subtitle from '../../../../components/typography/Subtitle1';
import Subtitle1 from '../../../../components/typography/Subtitle1';
import Subtitle4 from '../../../../components/typography/Subtitle4';
import AnimatePoints from '../../../../components/AnimatePoints';
import AnimatePointsContainer from '../../../../components/AnimatePoints/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import BADRecommendationText from '../../../../components/BADRecommendationText/styles';
import {getSessionStorageItem} from '../../../../helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../../../helpers/sessionStorage/constants';
import {
  BAD_REASONS,
  LOS_ANGELES_ZIP_CODES,
  NEW_YORK_ZIP_CODES_RANGE1,
  NEW_YORK_ZIP_CODES_RANGE2,
  NEW_YORK_ZIP_CODES_RANGE3,
  NEW_YORK_ZIP_CODES_RANGE4,
  NEW_YORK_ZIP_CODES_RANGE5,
  NEW_YORK_ZIP_CODES_RANGE6
} from '../../../../helpers/constants/bookAdriver';

const DISTANCE = {
  0: 2.0,
  1: 2.0,
  2: 2.0,
  3: 3.0,
  4: 3.0,
  5: 3.0,
  6: 4.0,
  7: 4.0,
  8: 5.0,
  9: 5.0,
  10: 6.0,
  11: 7.0,
  12: 7.0,
  13: 8.0,
  14: 8.0,
  15: 9.0,
  16: 10.0,
  17: 10.0,
  18: 11.0,
  19: 11.0,
  20: 12.0
};

const ASAPFoodTime = [
  {name: 'Now (no ordering delay)', id: 0},
  {name: '5 min', id: 5},
  {name: '10 min', id: 10},
  {name: '15 min', id: 15},
  {name: '20 min', id: 20},
  {name: '25 min', id: 25},
  {name: '30 min', id: 30}
];

const DEFAULT_TIPS = '20';

function CreateOrder() {
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const {order, addresses, checkAddressLoading, checkAddress} = useSelector(
    (state) => state.bookADriverCreateReducer
  );
  const isSuccessOrder = useSelector(
    (state) => state.bookADriverCreateReducer.openSuccessModal
  );
  const isFailOrder = useSelector(
    (state) => state.bookADriverCreateReducer.openFailModal
  );
  const isFailOrderData = useSelector(
    (state) => state.bookADriverCreateReducer.failData
  );
  const {activeLocation, timezone, userData} = useSelector(
    (state) => state.user
  );
  const {AllowedServiceFeatures} = useSelector(
    (state) => state.user.activeLocation
  );
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [expanded, setExpanded] = useState(false);
  const [stopUseEffect, setStopUseEffect] = useState(false);
  const [addressesList, setAddressesList] = useState(addresses);
  const [storeAddressError, setStoreAddressError] = useState(false);
  const [isV2, setIsV2] = useState(false);

  const contentRef = useRef(null);
  const backdropRef = useRef(null);

  useEffect(() => {
    if (contentRef.current && backdropRef.current) {
      backdropRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
  }, [checkAddressLoading]);

  const dateWithoutLocalTimezone = (date) => {
    let newDate = date;
    const currentTimezone = (new Date().getTimezoneOffset() / 60).toString();
    if (currentTimezone && currentTimezone.split('')[0] === '-') {
      newDate = moment(newDate)
        .set(
          'hours',
          moment(newDate).get('hours') - currentTimezone.split('')[1]
        )
        .toDate();
    } else if (
      currentTimezone &&
      currentTimezone.split('')[0] !== '-' &&
      currentTimezone.split('').length === 1
    ) {
      newDate = moment(newDate)
        .set(
          'hours',
          moment(newDate).get('hours') + currentTimezone.split('')[0]
        )
        .toDate();
    }
    return newDate;
  };

  const dateToTimezone = (date) => {
    let newDate = dateWithoutLocalTimezone(date);
    if (timezone && timezone.split('')[0] === '-') {
      newDate = moment(newDate)
        .set(
          'hours',
          moment(newDate).get('hours') - Number(timezone.split('')[1])
        )
        .toDate();
    } else {
      newDate = moment(newDate)
        .set(
          'hours',
          moment(newDate).get('hours') + Number(timezone.split('')[1])
        )
        .toDate();
    }
    return newDate;
  };

  const setItemCount = (items) => {
    if (items) {
      let itemCount = 0;
      items.forEach((item) => {
        itemCount += item.quantity;
      });
      return itemCount.toString();
    }
  };

  useEffect(() => {
    let orderType = 'ASAP';
    if (
      activeLocation.Address &&
      activeLocation.Address.split(', ').length < 3
    ) {
      setStoreAddressError(true);
      return;
    }
    if (activeLocation.Address !== initValues.StoreAddress) {
      const addressLoc = activeLocation?.Address?.trim();
      const address = addressLoc.split(', ');
      let newInitValuesObj = {
        ...initValues,
        StoreAddress: activeLocation?.StructuredAddress?.FormattedAddress
          ? activeLocation?.StructuredAddress?.FormattedAddress
          : activeLocation.Address,
        StoreAddressNumber: activeLocation?.StructuredAddress?.StreetNumber
          ? activeLocation?.StructuredAddress?.StreetNumber
          : address[0].split(' ')[0],
        StoreAddressStreet: activeLocation?.StructuredAddress?.StreetName
          ? activeLocation?.StructuredAddress?.StreetName
          : address[0].split(' ').slice(1).join(' '),
        StoreAddressCity: activeLocation?.StructuredAddress?.City
          ? activeLocation?.StructuredAddress?.City
          : address[1],
        StoreAddressState: activeLocation?.StructuredAddress?.StateCode
          ? activeLocation?.StructuredAddress?.StateCode
          : address[2].split(' ')[0],
        StoreAddressZip: activeLocation?.StructuredAddress?.ZipCode
          ? activeLocation?.StructuredAddress?.ZipCode
          : address[2].split(' ')[1],
        StoreCor: {
          lat:
            activeLocation?.StructuredAddress?.Latitude &&
            Number(activeLocation?.StructuredAddress?.Latitude)
              ? Number(activeLocation?.StructuredAddress?.Latitude)
              : activeLocation.Latitude,
          lng:
            activeLocation?.StructuredAddress?.Longitude &&
            Number(activeLocation?.StructuredAddress?.Longitude)
              ? Number(activeLocation?.StructuredAddress?.Longitude)
              : activeLocation.Longitude
        }
      };
      if (order && Object.keys(order).length) {
        newInitValuesObj = {
          ...newInitValuesObj,
          Email: order?.userEmail || 'orders@getsauce.com',
          FirstName: order?.userName?.split(' ')[0],
          LastName: order?.userName?.split(' ')[1],
          Subtotal: order?.orderInfo?.total?.toFixed(2).toString(),
          ItemCount: setItemCount(order?.orderInfo?.items),
          ContainAlcohol: !!order?.orderInfo?.alcoholTax,
          Address: order?.orderInfo?.deliveryAddress || '',
          FullAddress: order?.orderInfo?.deliveryAddress || '',
          StreetNumber:
            order?.orderInfo?.deliveryAddressDecode?.Address?.Number || '',
          Street:
            order?.orderInfo?.deliveryAddressDecode?.Address?.Street || '',
          City: order?.orderInfo?.deliveryAddressDecode?.Address?.City || '',
          State: order?.orderInfo?.deliveryAddressDecode?.Address?.State || '',
          ZipCode:
            order?.orderInfo?.deliveryAddressDecode?.Address?.ZipCode || '',
          PickupDate:
            order?.orderStatus === 'Future Order' && order?.date
              ? dateWithoutLocalTimezone(order?.date)
              : moment(dateToTimezone(new Date()))
                  .set(
                    'minutes',
                    moment(dateToTimezone(new Date())).get('minutes') +
                      60 +
                      activeLocation.PickupEtaMinutes
                  )
                  .toDate(),
          Phone: order?.userPhone
            ? phoneNumberFormatter(order?.userPhone).substr(-10, 10)
            : '',
          Location: {
            lat: order?.orderInfo?.deliveryAddressDecode?.Сoordinates?.Latitude,
            lng: order?.orderInfo?.deliveryAddressDecode?.Сoordinates?.Longitude
          },
          Notes: {
            notes: order?.orderInfo?.deliveryAddressDetails?.Notes || '',
            apt: order?.orderInfo?.deliveryAddressDetails?.Apt || '',
            entrance: order?.orderInfo?.deliveryAddressDetails?.Entrance || '',
            floor: order?.orderInfo?.deliveryAddressDetails?.Floor || ''
          },
          CourierTip: DEFAULT_TIPS,
          ...(AllowedServiceFeatures?.includes('book_a_driver_v2') && {
            Reason: '',
            AmountOfDrivers: null,
            IsV2: true,
            CoreOrderId: order.id,
            PercentTipAmount: 0,
            InvoiceId: order.invoiceID,
            CourierTip: ''
          })
        };
        if (order.orderStatus === 'Future Order' && order.date) {
          orderType = 'FutureDelivery';
          setActive('FutureDelivery');
        }
        if (AllowedServiceFeatures?.includes('book_a_driver_v2')) {
          setIsV2(true);
        }
      }
      setInitValues(newInitValuesObj);
      checkDeliveryAddress(newInitValuesObj, orderType);
    }
  });
  /*useEffect(() => {
        if (order && Object.keys(order).length) {
          console.log(initValues)
          const newInitValuesObj = {
            ...initValues,
            Email: order?.userEmail,
            FirstName: order?.userName?.split(' ')[0],
            LastName: order?.userName?.split(' ')[1],
            Subtotal: order?.orderInfo?.total?.toFixed(2).toString(),
            ItemCount: setItemCount(order?.orderInfo?.items),
            ContainAlcohol: !!order?.orderInfo?.alcoholTax,
            Address: order?.orderInfo?.deliveryAddress || '',
            FullAddress: order?.orderInfo?.deliveryAddress || '',
            StreetNumber:
                order?.orderInfo?.deliveryAddressDecode?.Address?.Number || '',
            Street:
                order?.orderInfo?.deliveryAddressDecode?.Address?.Street || '',
            City: order?.orderInfo?.deliveryAddressDecode?.Address?.City || '',
            State:
                order?.orderInfo?.deliveryAddressDecode?.Address?.State || '',
            ZipCode:
                order?.orderInfo?.deliveryAddressDecode?.Address?.ZipCode || '',
            PickupDate:
                order?.orderStatus === 'Future Order' && order?.date
                    ? dateWithoutLocalTimezone(order?.date)
                    : moment(dateToTimezone(new Date()))
                        .set(
                            'minutes',
                            moment(dateToTimezone(new Date())).get('minutes') +
                            60 +
                            activeLocation.PickupEtaMinutes
                        )
                        .toDate(),
            Phone: order?.userPhone?.substr(-10, 10),
            Location: {
              lat: order?.orderInfo?.deliveryAddressDecode?.Сoordinates?.Latitude,
              lng: order?.orderInfo?.deliveryAddressDecode?.Сoordinates?.Longitude,
            }
          }
          if (order.orderStatus === 'Future Order' && order.date) {
            setActive('FutureDelivery');
          }
          console.log(newInitValuesObj)
          setInitValues(newInitValuesObj)
          checkDeliveryAddress(newInitValuesObj);
        }
      }, [order])
    */
  useEffect(() => {
    if (addresses) {
      const newInitValuesObj = {
        ...initValues,
        Email: addresses?.customer_email,
        FirstName: addresses?.s2e_meta?.dropoff?.name.split(' ')[0],
        LastName: addresses?.s2e_meta?.dropoff?.name.split(' ')[1],
        StreetNumber: addresses?.s2e_meta?.dropoff?.number,
        Street: addresses?.s2e_meta?.dropoff?.street,
        City: addresses?.s2e_meta?.dropoff?.city,
        State: addresses?.s2e_meta?.dropoff?.state,
        ZipCode: addresses?.s2e_meta?.dropoff?.zip,
        Address: addresses?.s2e_meta?.dropoff?.address,
        FullAddress: addresses?.s2e_meta?.dropoff?.address,
        Phone: addresses?.s2e_meta?.dropoff?.phone?.replace('+1', ''),
        Notes: {
          notes: addresses?.s2e_meta?.dropoff?.notes || '',
          apt: addresses?.s2e_meta?.dropoff?.apt || '',
          entrance: addresses?.s2e_meta?.dropoff?.entrance || '',
          floor: addresses?.s2e_meta?.dropoff?.floor || ''
        },
        Location: {
          lat: addresses?.s2e_meta?.dropoff?.lat,
          lng: addresses?.s2e_meta?.dropoff?.lng
        }
      };
      setInitValues(newInitValuesObj);
      checkDeliveryAddress(newInitValuesObj, isActive);
    }
  }, [addresses]);

  useEffect(() => {
    return () => {
      dispatch(CLEAR_BAD_ORDER_DETAIL());
      dispatch(GET_ADDRESSES({}));
    };
  }, []);

  /*useEffect(() => {
        setCheck(checkAddress)
      }, [checkAddress])*/

  useEffect(() => {
    if (isSuccessOrder) {
      history.push('/book-a-driver/active');
    }
  });

  useEffect(() => {
    if (checkAddress) {
      /*setInitValues({
                    ...initValues,
                    DeliveryPrice: checkAddress?.delivery_cost
                  })*/
      // formikRef?.current?.setFieldValue(
      //   'DeliveryPrice',
      //   checkAddress?.delivery_cost || 0
      // );
      if (
        formikRef?.current?.values.DeliveryDistance !==
        Number(checkAddress?.delivery_distance?.toFixed(2))
      ) {
        if (isV2) {
          formikRef?.current?.setFieldValue('CourierTip', 'Custom');
          formikRef?.current?.setFieldValue(
            'Amount',
            checkAddress?.default_tip / 100 || 0
          );
          formikRef?.current?.setFieldValue(
            'CourierTipNumber',
            checkAddress?.default_tip || 0
          );
        } else {
          calculateDeliveryPrice({
            ...formikRef?.current?.values,
            DeliveryDistance:
              Number(checkAddress?.delivery_distance?.toFixed(2)) || 0
          });
        }
        formikRef?.current?.setFieldValue(
          'DeliveryDistance',
          Number(checkAddress?.delivery_distance?.toFixed(2)) || 0
        );
      }
      formikRef?.current?.setFieldValue(
        'DeliveryPrice',
        checkAddress?.delivery_price ? checkAddress?.delivery_price : 0
      );
    }
  }, [checkAddress]);

  const [initValues, setInitValues] = useState({
    StoreAddress: '',
    StoreAddressNumber: '',
    StoreAddressStreet: '',
    StoreAddressCity: '',
    StoreAddressState: '',
    StoreAddressZip: '',
    StoreCor: {
      lat: null,
      lng: null
    },
    CountryCode: '+1',
    Phone: '',
    Email: '',
    FirstName: '',
    LastName: '',
    FullAddress: '',
    Address: '',
    StreetNumber: '',
    Street: '',
    City: '',
    State: '',
    Country: '',
    Location: {},
    Notes: {
      apt: '',
      floor: '',
      entrance: '',
      notes: ''
    },
    Subtotal: '',
    CourierTip: '',
    CourierTipNumber: 0,
    Amount: '',
    ItemCount: '',
    Catering: false,
    DeliveryPrice: 0,
    DeliveryDistance: 0,
    ContainAlcohol: false,
    PickupDate: moment()
      .set('minutes', moment().get('minutes') + 60)
      .utcOffset(Number(timezone), false)
      .format()
      .substring(0, 19),
    MinDate: moment()
      .set('minutes', moment().get('minutes') + 60)
      .utcOffset(Number(timezone), false)
      .format()
      .substring(0, 19),
    PickupDateNow: moment().utcOffset(Number(timezone), false).format(),
    ASAPFoodPreparationTime: 5
  });
  const fmtDate = function (date) {
    let d = date.toISOString().split(':');
    d.push(d.pop().split('.')[0]);
    return d.join(':') + 'Z';
  };

  const addItem = (itemClass) => {
    const copy = [...items];
    copy.push({name: '', quantity: 1});
    setItems([...copy], itemClass);
  };

  const [disableButton, setDisableButton] = useState(false);

  const [items, setItems] = useState([]);
  useEffect(() => {
    if (items.length > 0) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [items]);

  const handleItemItems = (i, value) => {
    const copy = [...items];
    copy[i].name = value;
    setItems(copy);
  };

  const onChangeInitValues = (e) => {
    setInitValues({
      ...initValues,
      [e.target.name]: e.target.value
    });
  };

  const computedAddItem = (items) => {
    const haveEmptyItem = Boolean(items.find((elem) => elem.name === ''));
    if (haveEmptyItem) {
      return 'disabled';
    }
    return '';
  };

  const disabledSubmitButton = (value) => {
    if (value) {
      return 'ButtonBookADriver disabled';
    }
    return 'ButtonBookADriver';
  };

  const handleItemQuantity = (i, value) => {
    const copy = [...items];
    copy[i].quantity = value;
    setItems(copy);
  };

  const removeItem = (i) => {
    const copy = [...items];

    copy.splice(i, 1);

    setItems(copy);
  };
  const getMinTime = (date) => {
    if (
      moment(date).get('day') === moment(initValues.PickupDateNow).get('day') &&
      moment(date).get('month') ===
        moment(initValues.PickupDateNow).get('month')
    ) {
      return moment()
        .set('minutes', moment().get('minutes') + 60)
        .utcOffset(Number(timezone), false)
        .format()
        .substring(0, 19);
    }
  };

  const getAddresses = (e) => {
    if (e.target.value) {
      dispatch(GET_ADDRESSES(`+1${phoneNumberFormatter(e.target.value)}`));
    }
  };

  const amountFormatter = (newValue, prevValue = '', field, subtotal) => {
    if (newValue === '') {
      return '';
    }

    let formedNewValue = newValue.replace(',', '.').replace('%', '');
    let resValue;
    if (field !== 'ItemCount') {
      resValue =
        formedNewValue.substr(-1) === '0'
          ? (resValue = formedNewValue)
          : (resValue = parseFloat(formedNewValue));
    } else {
      resValue = parseInt(formedNewValue);
    }

    if (!resValue) {
      return prevValue ? resValue : '';
    }

    if (formedNewValue.length > 6 && field === 'amount') {
      return prevValue;
    }

    if (
      formedNewValue.indexOf('.') !== formedNewValue.lastIndexOf('.') &&
      field !== 'ItemCount'
    ) {
      return prevValue;
    }

    if (
      (resValue > 150 && field === 'amount') ||
      (subtotal && resValue > subtotal && field === 'amount') ||
      (resValue > 99 && field === 'ItemCount') ||
      (resValue > 10000 && field === 'subtotal')
    ) {
      return prevValue;
    }

    if (formedNewValue.slice(-1) === '.' && field !== 'ItemCount') {
      resValue = `${resValue}.`;
    }
    if (String(resValue).split('.')[1]?.length > 2 && field !== 'ItemCount') {
      return prevValue;
    }

    return resValue;
  };
  const [isActive, setActive] = useState('ASAP');

  const toggleClass = (buttonName) => {
    if (formikRef?.current?.values?.Catering && buttonName === 'ASAP') {
      return;
    } else {
      setActive(buttonName);
    }
  };

  const getASAPTime = () => {
    let newDate;
    newDate = moment()
      .set('minutes', moment().get('minutes') + activeLocation.PickupEtaMinutes)
      .utcOffset(0, false)
      .toDate();

    return newDate;
  };

  const checkDeliveryAddress = (value, orderType) => {
    if (
      value.FullAddress &&
      value.StoreAddress &&
      activeLocation?.TrackingMapApiKey
    ) {
      if (!value.StreetNumber) {
        formikRef?.current?.setErrors({
          StreetNumber:
            'Your address does not have any street number specified. Please select another address or choose address with street number specified!'
        });
        formikRef?.current?.setFieldTouched('Address');
        return;
      }
      if (!value.Street) {
        formikRef?.current?.setErrors({
          Street:
            'Your address does not have any street specified. Please select another address or choose address with street specified!'
        });
        formikRef?.current?.setFieldTouched('Address');
        return;
      }
      if (!value.City) {
        formikRef?.current?.setErrors({
          City:
            'Your address does not have any city specified. Please select another address or choose address with city specified!'
        });
        formikRef?.current?.setFieldTouched('Address');
        return;
      }
      if (!value.State) {
        formikRef?.current?.setErrors({
          State:
            'Your address does not have any state specified. Please select another address or choose address with state specified!'
        });
        formikRef?.current?.setFieldTouched('Address');
        return;
      }
      if (!value.ZipCode) {
        formikRef?.current?.setErrors({
          ZipCode:
            'Your address does not have any zip code specified. Please select another address or choose address with zip code specified!'
        });
        formikRef?.current?.setFieldTouched('Address');
        return;
      }
      const body = {
        location_token: activeLocation?.TrackingMapApiKey,
        pickup: {
          name:
            activeLocation?.BusinessName?.trim() ||
            activeLocation?.FullName?.trim(),
          phone: phoneNumberFormatter(activeLocation.Phone),
          address: {
            number: value.StoreAddressNumber,
            street: value.StoreAddressStreet,
            city: value.StoreAddressCity,
            state: value.StoreAddressState,
            zip: value.StoreAddressZip
          }
        },
        pickup_loc: value.StoreCor,
        dropoff: {
          name: 'John Doe',
          phone: '+13055865684',
          address: {
            number: value.StreetNumber,
            street: value.Street,
            city: value.City,
            state: value.State,
            zip: value.ZipCode
          }
        },
        pickup_business_name:
          activeLocation?.BusinessName?.trim() ||
          activeLocation?.FullName?.trim(),
        dropoff_loc: value.Location,
        order_value: value.Subtotal
          ? Number((value.Subtotal * 100).toFixed(0))
          : 100,
        is_catering: activeLocation?.IsCatering || false
      };
      if (orderType === 'ASAP') {
        body.food_preparation_time =
          value.ASAPFoodPreparationTime || activeLocation.PickupEtaMinutes;
      }
      if (orderType === 'FutureDelivery') {
        body.dropoff_time = getFutureDate(value);
      }
      dispatch(CHECK_ADDRESS({body, isV2: value.IsV2}));
    }
  };

  function getRestaurantDeliveryArea(distance) {
    const delivery = activeLocation.Delivery || [];
    if (delivery.length) {
      const onlyCustomerCharge = delivery.filter(
        (item) => Number(item.DeliverySay2eatFee) <= Number(item.DeliveryPrice)
      );
      if (onlyCustomerCharge.length) {
        return 'Only customer charge';
      }
      const filterDeliveryArea = delivery.filter(
        (item) => item.RadialDeliveryArea > distance
      );
      return filterDeliveryArea.length
        ? filterDeliveryArea[0]
        : 'Have not delivery area';
    }
  }

  function calcRestaurantSubsidizing(distance) {
    const deliveryArea = getRestaurantDeliveryArea(distance);
    if (deliveryArea === 'Only customer charge') {
      return null;
    } else if (deliveryArea === 'Have not delivery area') {
      const maxMiles = Object.keys(DISTANCE)[Object.keys(DISTANCE).length - 1];
      return Number(distance.toFixed(0)) <= maxMiles
        ? DISTANCE[distance.toFixed(0)].toFixed(2)
        : DISTANCE[maxMiles].toFixed(2);
    } else {
      return (
        Number(deliveryArea.DeliverySay2eatFee) -
        Number(deliveryArea.DeliveryPrice)
      ).toFixed(2);
    }
  }

  const calculateTip = (subtotal, courierTip, amount) => {
    if (subtotal && courierTip !== 'Custom' && courierTip !== 0) {
      return Number((Number(subtotal) * Number(courierTip)).toFixed(0));
    } else if (subtotal && courierTip === 'Custom' && amount) {
      return amount * 100;
    } else if (
      (subtotal && courierTip === 0) ||
      !subtotal ||
      (courierTip === 'Custom' && !amount)
    ) {
      return 0;
    }
  };

  const onCloseStoreAddressErrorModal = () => {
    setStoreAddressError(false);
    history.push('/store-settings');
  };

  const getFutureDate = (values) => {
    let result;
    if (values.PickupDate === values.MinDate) {
      result = moment(values.MinDate)
        .utcOffset(Number(timezone), true)
        .utc()
        .format();
    } else {
      result = moment(moment.utc(values.PickupDate).format())
        .utcOffset(Number(timezone), true)
        .utc()
        .format();
    }
    return result;
  };

  const setDateOrTime = (value, setFieldValue, formikValues) => {
    let date;
    if (moment(value).isBefore(formikValues.MinDate)) {
      date = formikValues.MinDate;
    } else {
      date = moment.utc(value).format();
    }
    setFieldValue('PickupDate', date);
    checkDeliveryAddress({...formikValues, PickupDate: date}, isActive);
  };

  function calculateTipAndAmount(percent, subtotal, currentValue) {
    const feeWithPercent = +(Number(subtotal) * (percent / 100));
    if (getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME)) {
      formikRef?.current?.setFieldValue(
        'CourierTip',
        feeWithPercent
          ? feeWithPercent < currentValue
            ? percent
            : 'Custom'
          : percent
      );
      formikRef?.current?.setFieldValue(
        'CourierTipNumber',
        feeWithPercent < currentValue
          ? +(feeWithPercent * 100).toFixed(2)
          : currentValue * 100
      );
      formikRef?.current?.setFieldValue(
        'Amount',
        feeWithPercent < currentValue ? '' : currentValue
      );
    } else {
      formikRef?.current?.setFieldValue('CourierTip', percent);
      formikRef?.current?.setFieldValue('Amount', '');
    }
  }

  // const checkNewYorkOrLosAngeles = (state, zip) => {
  //   return (
  //     (state === 'NY' || state === 'LA') &&
  //     (LOS_ANGELES_ZIP_CODES.includes(Number(zip)) ||
  //       (Number(zip) >= NEW_YORK_ZIP_CODES_RANGE1[0] &&
  //         Number(zip) <= NEW_YORK_ZIP_CODES_RANGE1[1]) ||
  //       (Number(zip) >= NEW_YORK_ZIP_CODES_RANGE2[0] &&
  //         Number(zip) <= NEW_YORK_ZIP_CODES_RANGE2[1]) ||
  //       (Number(zip) >= NEW_YORK_ZIP_CODES_RANGE3[0] &&
  //         Number(zip) <= NEW_YORK_ZIP_CODES_RANGE3[1]) ||
  //       (Number(zip) >= NEW_YORK_ZIP_CODES_RANGE4[0] &&
  //         Number(zip) <= NEW_YORK_ZIP_CODES_RANGE4[1]) ||
  //       (Number(zip) >= NEW_YORK_ZIP_CODES_RANGE5[0] &&
  //         Number(zip) <= NEW_YORK_ZIP_CODES_RANGE5[1]) ||
  //       (Number(zip) >= NEW_YORK_ZIP_CODES_RANGE6[0] &&
  //         Number(zip) <= NEW_YORK_ZIP_CODES_RANGE6[1]))
  //   );
  // };

  const calculateDeliveryPrice = (values) => {
    calculateTipAndAmount(
      activeLocation?.IsCatering ? 10 : 20,
      values?.Subtotal || 0,
      activeLocation?.IsCatering ? 15 : 3
    );
  };

  return (
    <>
      <BADStoreAddressErrorModal
        open={storeAddressError}
        onClose={onCloseStoreAddressErrorModal}
      />
      <Formik
        initialValues={initValues}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={BookADriverSchema}
        onSubmit={(values) => {
          if (!checkAddress?.errors) {
            setDisableButton(true);
            let body = {
              order_value: Number((values.Subtotal * 100).toFixed(0)),
              delivery_price: values.DeliveryPrice,
              items: [
                {
                  name: 'Package',
                  quantity: Number(values.ItemCount),
                  description: ''
                }
              ],
              customer_email: values.Email,
              contain_alcohol: values.ContainAlcohol,
              dropoff: {
                name: values.FirstName + ' ' + values.LastName,
                phone: values.CountryCode + values.Phone,
                notes_v2: values.Notes,
                address: {
                  number: values.StreetNumber,
                  street: values.Street,
                  city: values.City,
                  state: values.State,
                  zip: values.ZipCode
                }
              },
              dropoff_loc: values.Location,
              pickup: {
                name:
                  activeLocation?.BusinessName?.trim() ||
                  activeLocation?.FullName?.trim(),
                phone: activeLocation.Phone,
                address: {
                  number: values.StoreAddressNumber,
                  street: values.StoreAddressStreet,
                  city: values.StoreAddressCity,
                  state: values.StoreAddressState,
                  zip: values.StoreAddressZip
                }
              },
              pickup_loc: values.StoreCor,
              branch: activeLocation.FullName,
              restraunt: userData.merchant_name,
              pickup_business_name:
                activeLocation?.BusinessName?.trim() ||
                activeLocation?.FullName?.trim(),
              is_catering: activeLocation?.IsCatering || false,
              ...(isV2 && {
                reason: values.Reason,
                isV2: true,
                core_order_id: values.CoreOrderId,
                core_invoice_id: values.InvoiceId,
                is_requested_by_sauce:
                  getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME) || false,
                percent_tip_amount: values.PercentTipAmount || 0,
                delivery_time_asap:
                  isActive === 'ASAP' ? values.ASAPFoodPreparationTime : null,
                delivery_time_future:
                  isActive === 'FutureDelivery' ? getFutureDate(values) : null
              }),
              ...(isV2 &&
                values.AmountOfDrivers && {
                  amount_of_drivers: values.AmountOfDrivers
                })
            };
            if (isActive === 'ASAP') {
              body.food_preparation_time = values.ASAPFoodPreparationTime;
            }
            if (isActive === 'FutureDelivery') {
              // body.dropoff_ready_dt = moment(values.PickupDate).utc().format();
              // body.dropoff_time = moment(values.PickupDate).utc().format();
              body.dropoff_ready_dt = getFutureDate(values);
              body.dropoff_time = getFutureDate(values);
              body.food_preparation_time = activeLocation.PickupEtaMinutes;
            }
            body.tip = Number(values.CourierTipNumber.toFixed(0));

            body.total_value =
              body.order_value + values.DeliveryPrice + values.CourierTipNumber;
            if (!disableButton) {
              sendGtmEvent('bookADriverPost', {
                subtotal: body.order_value,
                tip: body.tip,
                itemCount: values.ItemCount,
                alcohol: body.contain_alcohol,
                dropOff:
                  isActive === 'FutureDelivery' ? 'Future delivery' : 'ASAP'
                // catering: should be taken from location ?
              });
              dispatch(POST_BAD_ORDER({data: body, delivery: isActive}));
            }
          }
        }}
      >
        {({values, handleChange, errors, touched, setFieldValue}) => (
          <Form>
            <ConnectedFocusError />
            <DashboardContent ref={contentRef} className='DashboardContent'>
              <TopLineNav>
                <LinkBack className='NavLink'>
                  <NavLink to='/book-a-driver'>Back to Book a Driver</NavLink>
                </LinkBack>
              </TopLineNav>
              <DashboardInner className='paddingBottomXl'>
                <Subtitle5 className='marginBottomLg'>
                  Book a <span>Driver</span>
                </Subtitle5>
                <Subtitle6 className='marginBottomXs'>
                  1. Pickup Information
                </Subtitle6>
                <DashboardFields>
                  <DashboardField className='w60Plus'>
                    <FieldLabel>Your store address</FieldLabel>
                    <FieldInput
                      onChange={handleChange}
                      type='text'
                      value={values.StoreAddress}
                      name='StoreAddress'
                      className={cn('isDisabled', {
                        error: checkAddress?.errors?.StoreAddress
                      })}
                      readOnly={true}
                    />
                    <ErrorSignWrapper className='maxTwoString'>
                      {checkAddress?.errors?.StoreAddress && (
                        <ErrorSign>
                          {checkAddress?.errors?.StoreAddress}
                        </ErrorSign>
                      )}
                    </ErrorSignWrapper>
                  </DashboardField>
                </DashboardFields>
                <Subtitle6 className='marginTopXl marginBottomXs'>
                  2. Customer Information
                </Subtitle6>
                <DashboardFields>
                  <DashboardField className='w60Plus'>
                    <DashboardFields className='marginMinus'>
                      <DashboardField className='FieldNumber'>
                        <FieldLabel>Country code</FieldLabel>
                        <FieldInput
                          onChange={handleChange}
                          value={values.CountryCode}
                          readOnly={true}
                          name='CountryCode'
                          className={cn('isDisabled', {
                            error:
                              errors.CountryCode &&
                              touched.CountryCode &&
                              'error'
                          })}
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors.CountryCode &&
                              touched.CountryCode &&
                              errors.CountryCode}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                      <DashboardField className='w50Minus'>
                        <FieldLabel>Customer phone number</FieldLabel>
                        <FieldMaskInput
                          onChange={(e) => {
                            const code = phoneNumberFormatter(e.target.value);
                            setFieldValue('Phone', code);
                          }}
                          value={values.Phone}
                          mask='(999) 999-9999'
                          name='Phone'
                          className={errors.Phone && touched.Phone && 'error'}
                          onBlur={getAddresses}
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors.Phone && touched.Phone && errors.Phone}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                    </DashboardFields>
                  </DashboardField>
                  <DashboardField className='w60Plus'>
                    <DashboardFields className='marginMinus'>
                      <DashboardField className='w50'>
                        <FieldLabel>Customer email</FieldLabel>
                        <FieldInput
                          type='text'
                          onChange={handleChange}
                          value={values.Email}
                          name='Email'
                          className={errors.Email && touched.Email && 'error'}
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors.Email && touched.Email && errors.Email}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                    </DashboardFields>
                  </DashboardField>
                  <DashboardField className='w60Plus'>
                    <DashboardFields className='marginMinus'>
                      <DashboardField className='w50'>
                        <FieldLabel>First name</FieldLabel>
                        <FieldInput
                          onChange={handleChange}
                          value={values.FirstName}
                          name='FirstName'
                          className={
                            errors.FirstName && touched.FirstName && 'error'
                          }
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors.FirstName &&
                              touched.FirstName &&
                              errors.FirstName}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                      <DashboardField className='w50'>
                        <FieldLabel>Last name</FieldLabel>
                        <FieldInput
                          onChange={handleChange}
                          value={values.LastName}
                          name='LastName'
                          className={
                            errors.LastName && touched.LastName && 'error'
                          }
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors.LastName &&
                              touched.LastName &&
                              errors.LastName}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                    </DashboardFields>
                  </DashboardField>
                  <DashboardField className='w60Plus withClear'>
                    <FieldLabel>Customer address</FieldLabel>
                    <AutocompletedMapField
                      type='text'
                      value={values.Address}
                      onChange={(e) => {
                        setFieldValue('Address', e.target.value);
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          checkDeliveryAddress(
                            formikRef?.current?.values,
                            isActive
                          );
                        }, 500);
                      }}
                      name='Address'
                      placeholder=' '
                      fieldToClear='FullAddress'
                      setFieldValue={setFieldValue}
                      className={cn('AutoComplete', {
                        error:
                          (errors.Address && touched.Address) ||
                          (errors.StreetNumber && touched.Address) ||
                          (errors.Street && touched.Address) ||
                          (errors.City && touched.Address) ||
                          (errors.State && touched.Address) ||
                          (errors.ZipCode && touched.Address) ||
                          (checkAddress && checkAddress?.errors?.DropoffAddress)
                      })}
                    />
                    {!!values.Address && (
                      <ButtonClear
                        onClick={() => {
                          setFieldValue('Location', {});
                          setFieldValue('FullAddress', '');
                          setFieldValue('Address', '');
                          setFieldValue('Country', '');
                          setFieldValue('Street', '');
                          setFieldValue('StreetNumber', '');
                          setFieldValue('City', '');
                          setFieldValue('State', '');
                          setFieldValue('ZipCode', '');
                          setFieldValue('DeliveryPrice', 0);
                          setFieldValue('DeliveryDistance', 0);
                        }}
                      />
                    )}
                    <ErrorSignWrapper className='maxTwoString'>
                      {errors.Address && touched.Address && (
                        <ErrorSign>{errors.Address}</ErrorSign>
                      )}
                      {errors.StreetNumber &&
                        !errors.Address &&
                        touched.Address && (
                          <ErrorSign>{errors.StreetNumber}</ErrorSign>
                        )}
                      {errors.Street &&
                        !errors.StreetNumber &&
                        !errors.Address &&
                        touched.Address && (
                          <ErrorSign>{errors.Street}</ErrorSign>
                        )}
                      {errors.City &&
                        !errors.StreetNumber &&
                        !errors.Street &&
                        !errors.Address &&
                        touched.Address && <ErrorSign>{errors.City}</ErrorSign>}
                      {errors.State &&
                        !errors.StreetNumber &&
                        !errors.Street &&
                        !errors.City &&
                        !errors.Address &&
                        touched.Address && (
                          <ErrorSign>{errors.State}</ErrorSign>
                        )}
                      {errors.ZipCode &&
                        !errors.StreetNumber &&
                        !errors.Street &&
                        !errors.City &&
                        !errors.State &&
                        !errors.Address &&
                        touched.Address && (
                          <ErrorSign>{errors.ZipCode}</ErrorSign>
                        )}
                      {checkAddress && checkAddress?.errors?.DropoffAddress && (
                        <ErrorSign>
                          {checkAddress?.errors?.DropoffAddress}
                        </ErrorSign>
                      )}
                    </ErrorSignWrapper>
                  </DashboardField>
                  <DashboardField className='w60Plus'>
                    <DashboardFields className='marginMinus'>
                      <DashboardField className='w33'>
                        <FieldLabel>Apartment</FieldLabel>
                        <FieldInput
                          onChange={(e) => {
                            setFieldValue('Notes.apt', e.target.value);
                          }}
                          value={values.Notes.apt}
                          name='Notes.apt'
                          type='text'
                          className={
                            errors?.Notes?.apt && touched?.Notes?.apt && 'error'
                          }
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors?.Notes?.apt &&
                              touched?.Notes?.apt &&
                              errors?.Notes?.apt}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                      <DashboardField className='w33'>
                        <FieldLabel>Floor</FieldLabel>
                        <FieldInput
                          onChange={(e) => {
                            setFieldValue('Notes.floor', e.target.value);
                          }}
                          value={values.Notes.floor}
                          name='Notes.floor'
                          type='text'
                          className={
                            errors?.Notes?.floor &&
                            touched?.Notes?.floor &&
                            'error'
                          }
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors?.Notes?.floor &&
                              touched?.Notes?.floor &&
                              errors?.Notes?.floor}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                      <DashboardField className='w33'>
                        <FieldLabel>Entrance</FieldLabel>
                        <FieldInput
                          onChange={(e) => {
                            setFieldValue('Notes.entrance', e.target.value);
                          }}
                          value={values.Notes.entrance}
                          name='Notes.entrance'
                          type='text'
                          className={
                            errors?.Notes?.entrance &&
                            touched?.Notes?.entrance &&
                            'error'
                          }
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors?.Notes?.entrance &&
                              touched?.Notes?.entrance &&
                              errors?.Notes?.entrance}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                    </DashboardFields>
                  </DashboardField>
                  <DashboardField className='w60Plus'>
                    <FieldLabel>
                      Delivery special instructions (optional)
                    </FieldLabel>
                    <FieldInput
                      onChange={handleChange}
                      value={values.Notes.notes}
                      name='Notes.notes'
                      type='text'
                      className={
                        errors?.Notes?.notes && touched?.Notes?.notes && 'error'
                      }
                    />
                    <ErrorSignWrapper>
                      <ErrorSign>
                        {errors?.Notes?.notes &&
                          touched?.Notes?.notes &&
                          errors?.Notes?.notes}
                      </ErrorSign>
                    </ErrorSignWrapper>
                  </DashboardField>
                </DashboardFields>
                <DashboardTitle className='w60Plus marginTopXl marginBottomXs'>
                  <Subtitle6>3. Delivery Information</Subtitle6>
                  <DashboardInnerDescription>
                    We’ll invoice this store automatically
                  </DashboardInnerDescription>
                </DashboardTitle>
                <DashboardFields>
                  <DashboardField className='w60Plus'>
                    <DashboardFields className='marginMinus'>
                      <DashboardField className='w33'>
                        <FieldLabel>Subtotal (excluding taxes)</FieldLabel>
                        <FieldInputDollar>
                          <FieldInput
                            onChange={(e) => {
                              setFieldValue(
                                'Subtotal',
                                amountFormatter(
                                  e.target.value,
                                  values.Subtotal,
                                  'subtotal'
                                )
                              );
                              setFieldValue(
                                'CourierTipNumber',
                                calculateTip(
                                  amountFormatter(
                                    e.target.value,
                                    values.Subtotal,
                                    'subtotal'
                                  ),
                                  values.CourierTip,
                                  values.Amount
                                )
                              );
                              if (!isV2) {
                                calculateDeliveryPrice({
                                  ...values,
                                  Subtotal: amountFormatter(
                                    e.target.value,
                                    values.Subtotal,
                                    'subtotal'
                                  )
                                });
                              }
                            }}
                            value={values.Subtotal}
                            name='Subtotal'
                            type='text'
                            className={
                              errors.Subtotal && touched.Subtotal && 'error'
                            }
                          />
                        </FieldInputDollar>
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors.Subtotal &&
                              touched.Subtotal &&
                              errors.Subtotal}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                      <DashboardField className='w33'>
                        <DashboardFields className='noWrap marginMinus'>
                          <DashboardField className='wAutoSpace'>
                            <FieldLabel>Courier tip (optional)</FieldLabel>
                            <SelectWrapper className='SelectMd w100'>
                              <FormControl className='select-formcontrol'>
                                <Select
                                  value={values.CourierTip}
                                  name='CourierTip'
                                  onChange={(e) => {
                                    setFieldValue('CourierTip', e.target.value);
                                    setFieldValue(
                                      'CourierTipNumber',
                                      calculateTip(
                                        values.Subtotal,
                                        e.target.value,
                                        values.Amount
                                      )
                                    );
                                    if (values.IsV2) {
                                      if (e.target.value === 'Custom') {
                                        setFieldValue('PercentTipAmount', 0);
                                      } else {
                                        setFieldValue(
                                          'PercentTipAmount',
                                          e.target.value
                                        );
                                      }
                                    }
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                  }}
                                >
                                  <MenuItem value={0}>0</MenuItem>
                                  <MenuItem value={10}>10%</MenuItem>
                                  <MenuItem value={15}>15%</MenuItem>
                                  <MenuItem value={18}>18%</MenuItem>
                                  <MenuItem value={20}>20%</MenuItem>
                                  <MenuItem value={'Custom'}>Custom</MenuItem>
                                </Select>
                              </FormControl>
                            </SelectWrapper>
                          </DashboardField>
                          {values.CourierTip === 'Custom' && (
                            <DashboardField className='wAutoFix'>
                              <FieldLabel>Tip amount</FieldLabel>
                              <FieldInputDollar>
                                <FieldInput
                                  onChange={(e) => {
                                    setFieldValue(
                                      'Amount',
                                      amountFormatter(
                                        e.target.value,
                                        values.Amount,
                                        'amount',
                                        values.Subtotal
                                      )
                                    );
                                    setFieldValue(
                                      'CourierTipNumber',
                                      amountFormatter(
                                        e.target.value,
                                        values.Amount,
                                        'amount',
                                        values.Subtotal
                                      ) * 100
                                    );
                                    // setFieldValue(
                                    //   'CourierTipNumber',
                                    //   amountFormatter(
                                    //     e.target.value,
                                    //     values.Amount,
                                    //     'amount',
                                    //     values.Subtotal
                                    //   ) * 100
                                    // );
                                  }}
                                  name='Amount'
                                  type='text'
                                  value={values.Amount}
                                  className={cn('FieldNumber', {
                                    error: errors.Amount && touched.Amount
                                  })}
                                />
                              </FieldInputDollar>
                              <ErrorSignWrapper>
                                <ErrorSign>
                                  {errors.Amount &&
                                    touched.Amount &&
                                    errors.Amount}
                                </ErrorSign>
                              </ErrorSignWrapper>
                            </DashboardField>
                          )}
                        </DashboardFields>
                      </DashboardField>
                      <DashboardField className='w33'>
                        <FieldLabel>Item count</FieldLabel>
                        <FieldInput
                          onChange={(e) => {
                            setFieldValue(
                              'ItemCount',
                              amountFormatter(
                                e.target.value,
                                values.ItemCount,
                                'ItemCount'
                              )
                            );
                          }}
                          value={values.ItemCount}
                          name='ItemCount'
                          type='text'
                          className={
                            errors.ItemCount && touched.ItemCount && 'error'
                          }
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors.ItemCount &&
                              touched.ItemCount &&
                              errors.ItemCount}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>
                    </DashboardFields>
                  </DashboardField>
                  {/*<DashboardField className='w60Plus dFlex'>
                    <BADRadioButtons>
                      <DashboardText className='marginRightSm'>
                        Is this a catering order?
                      </DashboardText>
                      <ButtonInfo className='Outline ButtonInfoDrop marginRight4l'>
                        <div className='DropInfo DropInfoCenter DropInfoLeftMob Small DropInfoBADCatering'>
                          Sauce also offers catering services. To publish a
                          catering menu contact us at{' '}
                          <a href={'mailto: success@getsauce.com'}>
                            success@getsauce.com
                          </a>
                        </div>
                      </ButtonInfo>
                    </BADRadioButtons>
                    <RadioGroup aria-label='Is this a catering order'>
                      <DashboardFields>
                        <DashboardField className='w100 dFlex'>
                          <RadioS2E
                            label='No'
                            type='radio'
                            name='Catering'
                            checked={values.Catering === false}
                            onChange={() => {
                              setFieldValue('Catering', false);
                            }}
                          />
                          <RadioS2E
                            label='Yes'
                            type='radio'
                            name='Catering'
                            checked={values.Catering === true}
                            onChange={() => {
                              setFieldValue('Catering', true);
                              setActive('FutureDelivery');
                            }}
                          />
                        </DashboardField>
                      </DashboardFields>
                    </RadioGroup>
                  </DashboardField>*/}
                  {AllowedServiceFeatures?.includes('book_a_driver_v2') &&
                    isV2 && (
                      <DashboardField className='w60Plus'>
                        <DashboardFields className='marginMinus'>
                          <DashboardField className='w66'>
                            <FieldLabel>Reason</FieldLabel>
                            <SelectWrapper className='SelectMd w100'>
                              <FormControl className='select-formcontrol'>
                                <Select
                                  value={values.Reason}
                                  name='Reason'
                                  onChange={(e) => {
                                    setFieldValue('Reason', e.target.value);
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                  }}
                                  className={
                                    errors.Reason && touched.Reason && 'error'
                                  }
                                >
                                  {BAD_REASONS.map((reason) => (
                                    <MenuItem value={reason.value}>
                                      {reason.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </SelectWrapper>
                            <ErrorSignWrapper>
                              <ErrorSign>
                                {errors.Reason &&
                                  touched.Reason &&
                                  errors.Reason}
                              </ErrorSign>
                            </ErrorSignWrapper>
                          </DashboardField>
                          {values?.Reason === 'LARGE_ORDER' && (
                            <DashboardField className='w33'>
                              <FieldLabel>Amount of drivers</FieldLabel>
                              <FieldInput
                                onChange={(e) => {
                                  setFieldValue(
                                    'AmountOfDrivers',
                                    integerFormatter(e.target.value)
                                  );
                                }}
                                value={values.AmountOfDrivers}
                                name='AmountOfDrivers'
                                type='text'
                                className={
                                  errors.AmountOfDrivers &&
                                  touched.AmountOfDrivers &&
                                  'error'
                                }
                              />
                              <ErrorSignWrapper>
                                <ErrorSign>
                                  {errors.AmountOfDrivers &&
                                    touched.AmountOfDrivers &&
                                    errors.AmountOfDrivers}
                                </ErrorSign>
                              </ErrorSignWrapper>
                            </DashboardField>
                          )}
                        </DashboardFields>
                      </DashboardField>
                    )}
                  <DashboardField className='w60Plus dFlex'>
                    <BADRadioButtons>
                      <DashboardText className='marginRightSm'>
                        Does order contain alcohol?
                      </DashboardText>
                      <ButtonInfo className='Outline ButtonInfoDrop marginRight4l'>
                        <div className='DropInfo DropInfoCenter pointer-events-none DropInfoLeftMob Small'>
                          If order includes alcoholic beverages, customer ID
                          must be shown to the courier.
                        </div>
                      </ButtonInfo>
                    </BADRadioButtons>
                    <RadioGroup aria-label='Is this order contain Alcohol'>
                      <DashboardFields>
                        <DashboardField className='w100 dFlex'>
                          <RadioS2E
                            label='No'
                            type='radio'
                            name='ContainAlcohol'
                            checked={values.ContainAlcohol === false}
                            onChange={() => {
                              setFieldValue('ContainAlcohol', false);
                            }}
                          />
                          <RadioS2E
                            label='Yes'
                            type='radio'
                            name='ContainAlcohol'
                            checked={values.ContainAlcohol === true}
                            onChange={() => {
                              setFieldValue('ContainAlcohol', true);
                            }}
                          />
                        </DashboardField>
                      </DashboardFields>
                    </RadioGroup>
                  </DashboardField>
                </DashboardFields>
                {/* <DashboardFields>
                  <DashboardField className='w100 Say2EatSelect DashboardFieldAccordion'>
                    <AccordionItem className='AccordionItemModal'>
                      <Accordion
                        onChange={handleAccordionChange('items')}
                        expanded={expanded === 'items' || expanded === true}
                      >
                        <AccordionSummary
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <AccordionItemName className='AccordionItemName marginRightXs'>
                            4. Items including{' '}
                            {items.length > 0 ? ` (${items.length})` : ''}
                          </AccordionItemName>
                          <ButtonInfo className='Outline ButtonInfoDrop'>
                            <div className='DropInfo DropInfoCenter DropInfoLeftMob pointer-events-none'>
                              The delivery company will be responsible only for
                              the items listed. Please add all of them to this
                              order
                            </div>
                          </ButtonInfo>
                        </AccordionSummary>
                        <AccordionItemBody>
                          <DashboardFields items={items}>
                            <>
                              {items.map((item, i) => (
                                <DashboardField className='w60Plus withRemove'>
                                  <DashboardFields className='marginMinus'>
                                    <DashboardField className='wAutoSpace'>
                                      <FieldInput
                                        name='name'
                                        value={items[i].name}
                                        onChange={(e) => {
                                          handleItemItems(i, e.target.value);
                                        }}
                                      />
                                    </DashboardField>
                                    <DashboardField className='FieldNumber'>
                                      <FieldInput
                                        className='TypeNumberArrows'
                                        type='number'
                                        min='1'
                                        step='1'
                                        name='quantity'
                                        value={items[i].quantity}
                                        onChange={(e) => {
                                          handleItemQuantity(
                                            i,
                                            Number(e.target.value)
                                          );
                                        }}
                                      />
                                    </DashboardField>
                                  </DashboardFields>
                                  <DiaFieldRemove
                                    onClick={() => removeItem(i)}
                                  />
                                </DashboardField>
                              ))}
                            </>
                          </DashboardFields>
                        </AccordionItemBody>
                      </Accordion>
                    </AccordionItem>
                  </DashboardField>
                  <DashboardField className='w100'>
                    <DialogLinkButton
                      className={computedAddItem(items)}
                      onClick={() => addItem()}
                    >
                      Add an Item
                    </DialogLinkButton>
                  </DashboardField>
                </DashboardFields> */}
                <DashboardTitle className='marginTopXl marginBottomXs'>
                  <Subtitle6>4. Delivery Dropoff Time</Subtitle6>
                </DashboardTitle>
                <DashboardFields>
                  <DashboardField className='w60Plus'>
                    <CardsButtonGrid className='CardsButtonGridDelivery'>
                      <CardsButton
                        className={cn('', {
                          isActive: isActive === 'ASAP',
                          isDisable: values.Catering
                        })}
                        onClick={() => {
                          toggleClass('ASAP');
                          checkDeliveryAddress(values, 'ASAP');
                        }}
                      >
                        <IconASAP />
                        <span>ASAP</span>
                      </CardsButton>
                      <CardsButton
                        className={
                          isActive === 'FutureDelivery' ? 'isActive' : null
                        }
                        onClick={() => {
                          toggleClass('FutureDelivery');
                          checkDeliveryAddress(values, 'FutureDelivery');
                        }}
                      >
                        <IconFutureDelivery />
                        <span>Future delivery</span>
                      </CardsButton>
                    </CardsButtonGrid>
                  </DashboardField>
                  {isActive === 'ASAP' && (
                    <DashboardField className='wAutoSpace'>
                      <DashboardField className='dFlex'>
                        <DashboardText className='mAround'>
                          Food will be ready in
                        </DashboardText>
                        <SelectWrapper className='SelectMd'>
                          <FormControl className='select-formcontrol'>
                            <Select
                              value={values.ASAPFoodPreparationTime}
                              name='ASAPFoodPreparationTime'
                              onChange={(e) => {
                                setFieldValue(
                                  'ASAPFoodPreparationTime',
                                  e.target.value
                                );
                                const body = {
                                  ...values,
                                  ASAPFoodPreparationTime: e.target.value
                                };
                                checkDeliveryAddress(body, isActive);
                              }}
                              MenuProps={{
                                // disableScrollLock: true,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                                },
                                getContentAnchorEl: null
                              }}
                            >
                              {ASAPFoodTime.map((item) => (
                                <MenuItem key={nanoid()} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </SelectWrapper>
                      </DashboardField>
                    </DashboardField>
                  )}
                  {isActive === 'FutureDelivery' && (
                    <DashboardField className='w60Plus'>
                      <DashboardFields>
                        <DashboardField className='wAuto PickerWidth100 BaDDatepicker'>
                          <FieldLabel>Delivery date</FieldLabel>
                          <SingleDatepickerDrop
                            value={values.PickupDate}
                            disablePast
                            inputFormat={'dd MMMM yyyy'}
                            maxDate={moment(new Date())
                              .set('month', new Date().getMonth() + 1)
                              .toDate()}
                            onAccept={(value) => {
                              setDateOrTime(value, setFieldValue, values);
                            }}
                            name='PickupDate'
                          />
                        </DashboardField>
                        <DashboardField className='wAuto PickerWidth100'>
                          <FieldLabel>Customer dropoff time</FieldLabel>
                          <div className='Say2EatSelect SauceSelectTime'>
                            <FormControl>
                              <MobileTimePicker
                                ampmInClock
                                value={values.PickupDate}
                                onChange={() => {}}
                                onAccept={(value) => {
                                  setDateOrTime(value, setFieldValue, values);
                                }}
                                minTime={getMinTime(values.PickupDate)}
                                name='PickupDate'
                                renderInput={(props) => {
                                  return <TextField {...props}></TextField>;
                                }}
                              />
                            </FormControl>
                          </div>
                        </DashboardField>
                      </DashboardFields>
                    </DashboardField>
                  )}
                  <DashboardField className='w60Plus justifyCenter flexColumn'>
                    <BADCreateTotal>
                      Total (delivery fee + courier tip){' '}
                      <span>
                        {checkAddressLoading ? (
                          <>
                            <Subtitle6>Calculating</Subtitle6>
                            <AnimatePoints className='Black' />
                            {/*<Loading className='LoadingSpinner' />*/}
                          </>
                        ) : (
                          <>
                            $
                            {(
                              (values.DeliveryPrice *
                                (values.AmountOfDrivers || 1) +
                                values.CourierTipNumber) /
                              100
                            ).toFixed(2) || 0}
                          </>
                        )}
                      </span>
                    </BADCreateTotal>
                    <BADRecommendationText>
                      {!AllowedServiceFeatures?.includes('book_a_driver_v2') &&
                      values.DeliveryDistance &&
                      calcRestaurantSubsidizing(values.DeliveryDistance) ? (
                        <>
                          Recommendation: For this distance, you usually
                          subsidize deliveries by $
                          {calcRestaurantSubsidizing(values.DeliveryDistance)}.
                          Charge client $
                          {(
                            (values.DeliveryPrice +
                              values.CourierTipNumber -
                              calcRestaurantSubsidizing(
                                values.DeliveryDistance
                              ) *
                                100) /
                            100
                          ).toFixed(2)}
                        </>
                      ) : (
                        <></>
                      )}
                    </BADRecommendationText>
                  </DashboardField>
                  <DashboardField className='w60Plus justifyCenter'>
                    <ButtonPrimary
                      type='submit'
                      className={disabledSubmitButton(disableButton)}
                    >
                      {disableButton ? (
                        <span>
                          Booking
                          <AnimatePoints className='White' />
                        </span>
                      ) : (
                        <span>Book a Driver</span>
                      )}
                    </ButtonPrimary>
                  </DashboardField>
                </DashboardFields>
              </DashboardInner>
              {checkAddressLoading && (
                <Loading ref={backdropRef} className='CreateBaD' />
              )}
            </DashboardContent>
          </Form>
        )}
      </Formik>

      <OrderFail
        onClose={() => {
          setDisableButton(false);
          dispatch(HANDLE_FAIL_MODAL(false));
        }}
        open={isFailOrder}
        failData={isFailOrderData}
      />
    </>
    /**/
  );
}

CreateOrder.propTypes = {};

CreateOrder.defaultProps = {};

export default CreateOrder;
