import React, {memo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Box} from '@material-ui/core';
import IconDollarType from '../../../../../assets/iconDollar';
import IconPercentType from '../../../../../assets/iconPercent';
import PopperActions from '../../../../../components/PopperActions';
import TableComponent from '../../../../../components/TableComponent';
import {setPopperActions} from '../../helpers';
import {DISCOUNT} from '../../../../../helpers/constants/discounts';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import {DISCOUNT_TYPE} from '../../../../../constants/Discounts';
import DiscountsTableWrapper from './styles';
import {isEmailMarketingTrialExpired} from '../../../../../helpers/functionUtils/emailMarketing';

const DiscountsTable = (props) => {
  const {
    discounts,
    loading,
    onSort,
    discountDirection,
    orderBy = 'Name',
    status,
    popperActions,
    allowedServiceFeatures,
    emailMarketingTrialDate,
    timezone
  } = props;

  const getFieldsConfig = () => [
    {
      title: 'Name',
      fieldName: DISCOUNT.NAME,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <FlexContainer className='align-center'>
          <FlexItem>{data[DISCOUNT.NAME]}</FlexItem>
          {/* <ButtonInfo className='OrangeOutline ButtonInfoDrop'> */}
          {/*  <div className='DropInfo DropInfoLeft'> */}
          {/*    The rank and performance of your items based on Number of Sales */}
          {/*    per Item (Orders) and by Generated Revenue per Item(Sales) */}
          {/*  </div> */}
          {/* </ButtonInfo> */}
        </FlexContainer>
      )
    },
    {
      title: 'Type',
      fieldName: DISCOUNT.TYPE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <div className='IconDiscounts'>
          {data.DiscountType === DISCOUNT_TYPE.percentage ? (
            <div className='IconPercentType'>
              <IconPercentType />
            </div>
          ) : (
            <div className='IconDollarType'>
              <IconDollarType />
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Discount value',
      fieldName: DISCOUNT.DISCOUNT_VALUE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => <>{data?.Discount}</>
    },
    {
      title: 'Min Order',
      fieldName: DISCOUNT.MIN_ORDER,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => <>{data?.MinOrder ? `$ ${data?.MinOrder}` : ''}</>
    },
    {
      title: 'Promo Code',
      fieldName: DISCOUNT.MIN_ORDER,
      sortable: false,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => <>{data?.CouponCode || ''}</>
    },
    ...(allowedServiceFeatures.includes('email_campaigns_active') ||
    (emailMarketingTrialDate &&
      !isEmailMarketingTrialExpired(emailMarketingTrialDate, timezone))
      ? [
          {
            title: 'Email marketing',
            fieldName: DISCOUNT.EMAIL_MARKETING,
            sortable: false,
            onCellClick: (discount) => popperActions.editAction(discount),
            renderer: (data) => (
              <div className='greenText'>
                {data?.EmailMarketing ? 'Enabled' : ''}
              </div>
            )
          }
        ]
      : []),
    {
      title: 'Start date',
      fieldName: DISCOUNT.START_DATE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <>
          {data?.StartTime
            ? moment(data.StartTime).utc().format('MMMM D')
            : 'Always On'}
        </>
      )
    },
    {
      title: 'End date',
      fieldName: DISCOUNT.END_DATE,
      sortable: true,
      onCellClick: (discount) => popperActions.editAction(discount),
      renderer: (data) => (
        <>
          {data?.EndTime
            ? moment(data.EndTime).utc().format('MMMM D')
            : 'Always On'}
        </>
      )
    },
    {
      renderer: (data) => {
        return (
          <PopperActions
            popperActions={() =>
              setPopperActions(
                popperActions.editAction,
                popperActions.duplicateAction,
                popperActions.disableAction,
                popperActions.deleteAction,
                status
              )
            }
            row={data}
          />
        );
      }
    }
  ];

  return (
    <DiscountsTableWrapper>
      <TableComponent
        fields={getFieldsConfig()}
        data={discounts}
        loading={loading}
        rowKeyPath='_id'
        onSort={onSort}
        orderBy={orderBy}
        orderDirection={discountDirection}
      />
    </DiscountsTableWrapper>
  );
};

DiscountsTable.propTypes = {
  orderBy: PropTypes.string,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  discounts: PropTypes.array.isRequired,
  discountDirection: PropTypes.string,
  status: PropTypes.string.isRequired,
  popperActions: PropTypes.objectOf({
    editAction: PropTypes.func,
    duplicateAction: PropTypes.func,
    disableAction: PropTypes.func,
    deleteAction: PropTypes.func
  }).isRequired,
  allowedServiceFeatures: PropTypes.array.isRequired,
  emailMarketingTrialDate: PropTypes.string,
  timezone: PropTypes.string.isRequired
};

DiscountsTable.defaultProps = {
  orderBy: '',
  loading: false,
  onSort: () => {},
  discountDirection: '',
  emailMarketingTrialDate: null
};

export default memo(DiscountsTable);
