import {select} from 'redux-saga/effects';
import {v4 as uuid} from 'uuid';
import axiosAPI from './axios';
import {decodeToken, getStorageToken} from '../../localStorage/authStorage';
import {getAppIp, getAppType, getAppVersion} from '../../app.helper';
import {
  getMethodByCode,
  getOptions,
  newLogger
} from '../../../services/logger/new-logger';
import store from '../../../store';
import {getCurrentSessionIdFromStorage} from '../../localStorage/UserSessionIdStorage';

const getDataForLogs = () => {
  const {user} = store.getState();
  return {
    locationId: user?.locationId,
    locationName: user?.locationName,
    userData: user?.userData
  };
};
axiosAPI.interceptors.request.use(
  (req) => {
    req.headers['x-timestart'] = Date.now().toString();
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosAPI.interceptors.response.use(
  (res) => {
    newLogger(
      getOptions(res, false, getDataForLogs()),
      `Fetch finished ${res.status} ${res.config.method?.toUpperCase()} ${
        res.config.url || 'unknown_url'
      }`,
      getMethodByCode(res.status)
    );
    return res;
  },
  (error) => {
    if (error.response) {
      const res = error.response;
      newLogger(
        getOptions(res, true, getDataForLogs()),
        `Fetch finished ${res.status} ${res.config.method?.toUpperCase()} ${
          res.config.url || 'unknown_url'
        }`,
        getMethodByCode(res.status)
      );
    }
    return Promise.reject(error);
  }
);

export function* configInterseptors() {
  const {token} = getStorageToken();
  const locationId = yield select((s) => s.user.locationId);

  axiosAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
  axiosAPI.defaults.headers.common['X-Merchant-Location'] = `${
    locationId || ''
  }`;
  // axiosAPI.defaults.headers.common['X-Sauce-User-Id'] = `${
  //   decodeToken()?.email || 'unauth'
  // }`;
  axiosAPI.defaults.headers.common['X-Sauce-Request-Id'] = uuid();
  axiosAPI.defaults.headers.common['X-Sauce-Session-Id'] =
    getCurrentSessionIdFromStorage(getDataForLogs()?.userData?.id) || uuid();
  axiosAPI.defaults.headers.common[
    'X-App-Type'
  ] = `${getAppType()} ${getAppVersion()}`;
  axiosAPI.defaults.headers.common['X-App-Ip'] = `${getAppIp()}`;
}

export const configInterseptorsWithToken = (token) => {
  axiosAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default axiosAPI;
