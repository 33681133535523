// eslint-disable-next-line import/prefer-default-export
export const DISCOUNT = {
  ID: '_id',
  STATUS: 'Status',
  TYPE: 'DiscountType',
  DISCOUNT_VALUE: 'Discount',
  NAME: 'Name',
  MIN_ORDER: 'MinOrder',
  START_DATE: 'StartTime',
  END_DATE: 'EndTime',
  EMAIL_MARKETING: 'EmailMarketing'
};

export const DISCOUNT_MODALS = {
  NEW: 'newDiscount',
  SELECT: 'selectDiscount',
  TURN_ON: 'turnOnDiscount',
  DELETE: 'deleteDiscount',
  DISABLE: 'disableDiscount'
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: 1,
  FIXED: 0
};
