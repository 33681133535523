import React, {useState} from 'react';
import T from 'prop-types';
import Cookies from 'js-cookie';
import {useDispatch} from 'react-redux';
import {DashboardField, DashboardTitle} from 'routes/App/dashboard/styles';
import {
  InsightsGrowthFeatureBlock,
  InsightsGrowthFeatureButtons,
  InsightsGrowthFeatureDescription,
  InsightsGrowthFeatureMotivation,
  InsightsGrowthFeatureName,
  InsightsGrowthFeaturesContainer,
  InsightsGrowthFeatureTop
} from 'routes/App/Insights/Growth/styles';
import {
  INSIGHTS_GROWTH_FEATURES_INFO,
  INSIGHTS_GROWTH_SECTION_NAMES,
  INSIGHTS_GROWTH_SECTIONS
} from 'helpers/constants/Insights';
import IconInsightsCheck from 'assets/insights/icon-insights-check.svg';
import sendGA from 'helpers/services/utils/ga';
import {INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL} from 'store/insights/growth/action';
import SwitchS2E from 'components/Switch';

const showLearMoreButton = (feature) => {
  switch (feature) {
    case 'googleMarketing':
      // for POC
      return true;
    case 'catering':
      return false;
    default:
      return true;
  }
};

const showContactUsButton = (feature, allowedServiceFeatures) => {
  switch (feature) {
    case 'googleMarketing':
      // for POC
      return true;
    case 'emailMarketing':
      return !allowedServiceFeatures.includes('show_marketing_result');
    case 'phoneOrdering':
      return true;
    case 'virtualAnswering':
      return true;
    case 'catering':
      return true;
    case 'websiteOptimization':
      return true;
    case 'googlePreferred':
      return true;
    default:
      return false;
  }
};

const InsightsGrowthContainer = ({
  features,
  section,
  allowedServiceFeatures
}) => {
  const COOKIE_NAME = 'insightsGrowthAdvanceMode';
  const COOKIE_EXPIRATION_HOURS = 24;

  const dispatch = useDispatch();
  const [advanceMode, setAdvanceMode] = useState(
    Cookies.get(COOKIE_NAME) || false
  );
  const openContactUsModal = (feature) => {
    dispatch(INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL({isOpen: true, feature}));
  };

  return (
    <>
      <div>
        {section === 'DIGITAL_OPTIMIZATION' && (
          <DashboardField className='w100 padding0 InsightsGrowthAdvanceMode'>
            <SwitchS2E
              labelPlacement='end'
              label='Advanced'
              checked={advanceMode}
              onChange={(e) => {
                setAdvanceMode(e.target.checked);
                if (e.target.checked) {
                  Cookies.set(COOKIE_NAME, true, {
                    expires: COOKIE_EXPIRATION_HOURS / 24
                  });
                } else {
                  Cookies.remove(COOKIE_NAME);
                }
              }}
              name='advanceMode'
              className='red endPlacement'
            />
          </DashboardField>
        )}
        <DashboardTitle className='WithLine title24 titleBold InsightsGrowthTitle'>
          {INSIGHTS_GROWTH_SECTION_NAMES[section]}
        </DashboardTitle>
      </div>
      <InsightsGrowthFeaturesContainer>
        {features
          ?.filter(
            (item) =>
              INSIGHTS_GROWTH_FEATURES_INFO[item?.title]?.section ===
                INSIGHTS_GROWTH_SECTIONS[section] &&
              (advanceMode
                ? true
                : !INSIGHTS_GROWTH_FEATURES_INFO[item?.title]?.forAdvancedMode)
          )
          .map((feature) => (
            <InsightsGrowthFeatureBlock>
              <div>
                <InsightsGrowthFeatureTop>
                  <img
                    className='featureIcon'
                    src={INSIGHTS_GROWTH_FEATURES_INFO[feature?.title]?.icon}
                    alt={INSIGHTS_GROWTH_FEATURES_INFO[feature?.title]?.name}
                  />
                  {feature.status === 'Enabled' && (
                    <img
                      className='featureChecked'
                      src={IconInsightsCheck}
                      alt='checked'
                    />
                  )}
                </InsightsGrowthFeatureTop>
                <InsightsGrowthFeatureName>
                  {INSIGHTS_GROWTH_FEATURES_INFO[feature?.title]?.name}
                </InsightsGrowthFeatureName>
                <InsightsGrowthFeatureDescription>
                  {INSIGHTS_GROWTH_FEATURES_INFO[feature.title]?.description}
                </InsightsGrowthFeatureDescription>
              </div>
              <div>
                <InsightsGrowthFeatureMotivation>
                  {INSIGHTS_GROWTH_FEATURES_INFO[feature.title]?.motivation}
                </InsightsGrowthFeatureMotivation>
                <InsightsGrowthFeatureButtons>
                  {showLearMoreButton(feature.title) && (
                    <div>
                      <a
                        onClick={() => {
                          sendGA(
                            'Insights Growth',
                            'Learn more',
                            `${
                              INSIGHTS_GROWTH_FEATURES_INFO[feature.title].name
                            }`,
                            null
                          );
                        }}
                        href={
                          INSIGHTS_GROWTH_FEATURES_INFO[feature.title]?.learMore
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        Learn more
                      </a>
                    </div>
                  )}

                  {showContactUsButton(
                    feature.title,
                    allowedServiceFeatures
                  ) && (
                    <div>
                      <span
                        onClick={() => {
                          openContactUsModal(feature?.title);
                        }}
                      >
                        Contact us
                      </span>
                    </div>
                  )}
                  {feature.title === 'managePrices' &&
                    feature.priceComparisonUrl && (
                      <div>
                        <span
                          onClick={() => {
                            window.open(feature.priceComparisonUrl, '_blank');
                          }}
                        >
                          Fix now
                        </span>
                      </div>
                    )}
                </InsightsGrowthFeatureButtons>
              </div>
            </InsightsGrowthFeatureBlock>
          ))}
      </InsightsGrowthFeaturesContainer>
    </>
  );
};

InsightsGrowthContainer.propTypes = {
  features: T.array.isRequired,
  section: T.string.isRequired,
  allowedServiceFeatures: T.array.isRequired
};

export default InsightsGrowthContainer;
