import {useSelector} from 'react-redux';

export const getDiscounts = () =>
  useSelector((state) => state.discountsV2Reducer.discounts);

export const getDiscount = () =>
  useSelector((state) => state.discountsV2Reducer.discount);

export const getButtonStatus = () =>
  useSelector((state) => state.discountsV2Reducer.buttonStatus);

export const getHasNextPage = () =>
  useSelector((state) => state.discountsV2Reducer.hasNextPage);

export const getDiscountsPage = () =>
  useSelector((state) => state.discountsV2Reducer.page);

export const getLocationId = () =>
  useSelector((state) => state.user.locationId);

export const getModalsStack = () =>
  useSelector((state) => state.discountsV2Reducer.modalsStack);

export const getDiscountsLoading = () =>
  useSelector((state) => state.discountsV2Reducer.loading);

export const getOrderBySorting = () =>
  useSelector((state) => state.discountsV2Reducer.sorting.sortBy);

export const getSortDirection = () =>
  useSelector((state) => state.discountsV2Reducer.sorting.sort);

export const getTotalDocsCount = () =>
  useSelector((state) => state.discountsV2Reducer.totalDocsCount);

export const getEmailMarketingToggleError = () =>
  useSelector((state) => state.discountsV2Reducer.isEmailMarketingToggleError);

export const getIsEmailMarketingTurnOffModalOpen = () =>
  useSelector(
    (state) => state.discountsV2Reducer.isEmailMarketingTurnOffModalOpen
  );

export const getBogoItems = () =>
  useSelector((state) => state.discountsV2Reducer.bogoItems);
export const getCurrentDiscounts = () =>
  useSelector((state) => state.discountsV2Reducer.currentDiscounts);
export const getLoadingBogoItems = () =>
  useSelector((state) => state.discountsV2Reducer.loadingBogoItems);

export const getEmailMarketingTrialDate = () =>
  useSelector((state) => state.discountsV2Reducer.emailMarketingTrialDate);
