import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import cn from 'classnames';
import Modal from 'components/Modals/Modal';
import {DialogAction} from 'routes/App/dashboard/styles';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import {FlexContainer} from 'components/layout';
import DialogTitle from 'components/DialogTitle/styles';
import {CardsButtonGrid} from 'components/CardsButtonGrid/styles';
import {CardsButton} from 'components/CardsButton/styles';
import {DISCOUNT_TYPE} from 'helpers/constants/discounts';

const ModalSelectDiscount = ({
  onCloseAction,
  submitAction,
  handleChange,
  getValue,
  getError,
  getTouched,
  setTouched
}) => {
  const selectedDiscountType = getValue('DiscountType');
  const getClassName = (value) =>
    value === selectedDiscountType ? 'isActive' : null;

  const types = [
    {value: DISCOUNT_TYPE.PERCENTAGE},
    {value: DISCOUNT_TYPE.FIXED}
  ];

  useEffect(() => {
    handleChange('Discount', '');
    handleChange('MinOrder', '');
  }, [selectedDiscountType]);

  return (
    <Modal
      onCloseAction={onCloseAction}
      title={
        <Box>
          <DialogTitle className='marginBottomLg'>
            Select a discount type
          </DialogTitle>
        </Box>
      }
      body={
        <>
          <CardsButtonGrid className='CardsButtonGridSelectDiscount'>
            {types.map(({value}) => (
              <CardsButton
                key={`DiscountType_${value}`}
                className={cn('CardsButton', getClassName(value))}
                onClick={() => {
                  handleChange('DiscountType', value);
                }}
              >
                {value === DISCOUNT_TYPE.PERCENTAGE && (
                  <FlexContainer className='FlexContainerDiscountNew'>
                    <div>Storewide Discount (%)</div>
                  </FlexContainer>
                )}
                {value === DISCOUNT_TYPE.FIXED && (
                  <FlexContainer className='FlexContainerDiscountNew'>
                    <div>Storewide Discount ($)</div>
                  </FlexContainer>
                )}
              </CardsButton>
            ))}
          </CardsButtonGrid>
          <DialogAction>
            <ButtonPrimary
              type='submit'
              className={selectedDiscountType === null && 'disabled'}
              onClick={(e) => {
                submitAction(e);
              }}
            >
              <span>Create Discount</span>
            </ButtonPrimary>
          </DialogAction>
        </>
      }
    />
  );
};

ModalSelectDiscount.propTypes = {
  onCloseAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  getTouched: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired
};

export default ModalSelectDiscount;
