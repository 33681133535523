import {put, select, takeEvery} from 'redux-saga/effects';
import {
  CREATE_DISCOUNT,
  CREATE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT,
  DELETE_DISCOUNT_SUCCESS,
  GET_DISCOUNT,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNTS,
  GET_DISCOUNTS_SUCCESS,
  SET_LOADING,
  UPDATE_DISCOUNT,
  UPDATE_DISCOUNT_SUCCESS,
  SET_BUTTON_STATUS,
  SET_EMAIL_MARKETING_TOGGLE_ERROR,
  ARCHIVE_DISCOUNT_SUCCESS,
  ARCHIVE_DISCOUNT,
  GET_BOGO_ITEMS_SUCCESS,
  GET_BOGO_ITEMS,
  SET_BOGO_ITEMS_LOADING,
  GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS,
  GET_DISCOUNTS_FOR_ITEMS
} from 'store/discountsV2/action';
import {
  archiveDiscountApiV2,
  createDiscountApiV2,
  deleteDiscountApiV2,
  getBogoItemsApi,
  getDiscountByIdApiV2,
  getDiscountsApiV2,
  getDiscountsForItemsApi,
  updateDiscountApiV2
} from 'helpers/services/api/discountsV2';
import {discountsV2ReplaceZFromDates} from 'helpers/functionUtils/discountsV2';
import history from '../../history';
import {getEmailMarketingTrialDateApi} from '../../helpers/services/api/emailMarketing';

const getLocationId = (state) => state.user.locationId;

function* checkError(error) {
  try {
    if (
      error &&
      error.response?.data?.errors?.find(
        (el) =>
          el.message ===
          'Another discount was already selected for Email Marketing, you can use only one'
      )
    ) {
      yield put(SET_EMAIL_MARKETING_TOGGLE_ERROR(true));
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* checkEmailMarketingTrialDate() {
  const locationId = yield select((state) => state.user.locationId);
  const {activeLocation} = yield select((state) => state.user);
  if (activeLocation?.AllowedServiceFeatures?.includes('email_marketing_pcs')) {
    try {
      const emailMarketingTrialResponse = yield getEmailMarketingTrialDateApi(
        locationId
      );
      if (emailMarketingTrialResponse?.data) {
        yield put(
          GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS(
            emailMarketingTrialResponse.data
          )
        );
      }
    } catch (error) {
      yield put(GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS({startDate: null}));
      console.log('SAGA ERROR', error);
    }
  } else {
    yield put(GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS({startDate: null}));
  }
}

function* getDiscountsSaga({payload}) {
  try {
    yield put(SET_LOADING(true));
    const locationId = yield select(getLocationId);
    yield checkEmailMarketingTrialDate();
    const {data} = yield getDiscountsApiV2(payload, locationId);
    if (data) {
      yield put(GET_DISCOUNTS_SUCCESS(data));
      yield put(SET_LOADING(false));
    }
  } catch (error) {
    yield put(SET_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* getDiscountByIdSaga({payload}) {
  try {
    yield put(SET_LOADING(true));
    yield checkEmailMarketingTrialDate();
    const {data} = yield getDiscountByIdApiV2(payload);
    if (data) {
      yield put(GET_DISCOUNT_SUCCESS(data));
      yield put(SET_LOADING(false));
    }
  } catch (error) {
    yield put(SET_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* createDiscountSaga({
  payload: {payload, postCallback, errorCallback}
}) {
  try {
    const locationId = yield select(getLocationId);
    const formattedDiscount = discountsV2ReplaceZFromDates(payload);
    const data = yield createDiscountApiV2({...formattedDiscount, locationId});

    if (data && data.status === 200) {
      yield put(CREATE_DISCOUNT_SUCCESS(data.data));
      history.push('/discounts-v2/active');
      if (postCallback) postCallback();
    }
    yield put(SET_BUTTON_STATUS(false));
  } catch (error) {
    yield put(SET_BUTTON_STATUS(false));
    if (errorCallback) errorCallback();
    yield checkError(error);
    console.log('SAGA ERROR', error);
  }
}

function* updateDiscountSaga({payload: {payload}}) {
  try {
    const formattedDiscount = discountsV2ReplaceZFromDates(payload.discount);
    const data = yield updateDiscountApiV2(payload.id, formattedDiscount);
    if (data && data.status === 200) {
      yield put(UPDATE_DISCOUNT_SUCCESS(payload));
      history.push('/discounts-v2/active');
    }
    yield put(SET_BUTTON_STATUS(false));
  } catch (error) {
    yield put(SET_BUTTON_STATUS(false));
    yield checkError(error);
    console.log('SAGA ERROR', error);
  }
}

function* archiveDiscountSaga({payload: {payload}}) {
  try {
    const data = yield archiveDiscountApiV2(payload.id, payload.discount);
    if (data && data.status === 200) {
      yield put(ARCHIVE_DISCOUNT_SUCCESS(payload.id));
      history.push('/discounts-v2/active');
    }
    yield put(SET_BUTTON_STATUS(false));
  } catch (error) {
    yield put(SET_BUTTON_STATUS(false));
    yield checkError(error);
    console.log('SAGA ERROR', error);
  }
}

function* deleteDiscountSaga({payload: {payload}}) {
  try {
    const data = yield deleteDiscountApiV2(payload);
    if (data && data.status === 200) {
      yield put(DELETE_DISCOUNT_SUCCESS(payload));
      // history.push('/discounts-v2');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getBogoItems() {
  const locationId = yield select(getLocationId);
  try {
    const {data} = yield getBogoItemsApi(locationId);
    if (data) {
      yield put(GET_BOGO_ITEMS_SUCCESS(data));
      try {
        const {data: currentDiscounts} = yield getDiscountsForItemsApi(
          locationId
        );
        if (currentDiscounts) {
          yield put(GET_DISCOUNTS_FOR_ITEMS(currentDiscounts));
        }
      } catch (error) {
        console.log('SAGA ERROR', error);
      }
    }
    yield put(SET_BOGO_ITEMS_LOADING(false));
  } catch (error) {
    yield put(SET_BOGO_ITEMS_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* discountsV2Saga() {
  yield takeEvery(GET_DISCOUNT, getDiscountByIdSaga);
  yield takeEvery(GET_DISCOUNTS, getDiscountsSaga);
  yield takeEvery(CREATE_DISCOUNT, createDiscountSaga);
  yield takeEvery(DELETE_DISCOUNT, deleteDiscountSaga);
  yield takeEvery(UPDATE_DISCOUNT, updateDiscountSaga);
  yield takeEvery(ARCHIVE_DISCOUNT, archiveDiscountSaga);
  yield takeEvery(GET_BOGO_ITEMS, getBogoItems);
}

export default discountsV2Saga;
