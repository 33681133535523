import {createReducer} from 'redux-act';
import Cookies from 'js-cookie';
import {
  GET_EMAIL_MARKETING_TRIAL_DATE,
  GET_MARKETING_STATISTIC_SUCCESS,
  OPEN_CONTACT_FORM_MODAL,
  OPEN_INFORMATION_MODAL,
  SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER,
  SET_EMAIL_MARKETING_TRIAL_DATE
} from 'store/emailMarketing/action';
import {COOKIE_NAME_EMAIL_MARKETING_COUNTDOWN} from 'helpers/constants/emailMarketing';

const initialState = () => ({
  isOpenModal: false,
  isOpenInformationModal: false,
  statistic: {
    lastMonthSummary: {
      sent: 0,
      delivered: 0,
      salesDriven: 0
    },
    allSummary: {
      sent: 0,
      delivered: 0,
      salesDriven: 0
    }
  },
  spinner: false,
  emailMarketingTrialDate: null,
  emailMarketingTrialDateStatus: null
});

const emailMarketingReducer = createReducer(
  {
    [OPEN_CONTACT_FORM_MODAL]: (state, payload) => ({
      ...state,
      isOpenModal: payload
    }),
    [OPEN_INFORMATION_MODAL]: (state, payload) => ({
      ...state,
      isOpenInformationModal: payload
    }),
    [GET_MARKETING_STATISTIC_SUCCESS]: (state, payload) => ({
      ...state,
      statistic: payload,
      spinner: false
    }),
    [SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER]: (state, payload) => ({
      ...state,
      spinner: payload
    }),
    [SET_EMAIL_MARKETING_TRIAL_DATE]: (state, payload) => {
      const result = Cookies.get(COOKIE_NAME_EMAIL_MARKETING_COUNTDOWN)
        ? null
        : {
            emailMarketingTrialDate: payload.startDate,
            emailMarketingTrialDateStatus: payload.status
          };
      return {
        ...state,
        ...result,
        spinner: false
      };
    },
    [GET_EMAIL_MARKETING_TRIAL_DATE]: (state) => {
      return {
        ...state,
        spinner: true
      };
    }
  },
  initialState()
);

export default emailMarketingReducer;
