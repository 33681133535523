import {createReducer} from 'redux-act';
import {
  GET_DISCOUNTS_SUCCESS,
  SET_LOADING,
  UPDATE_DISCOUNT_SUCCESS,
  CLEAR_STATE,
  DELETE_DISCOUNT_SUCCESS,
  PUSH_MODALS_STACK,
  SHIFT_MODALS_STACK,
  SET_DISCOUNT,
  CLEAR_DISCOUNTS,
  SET_WARNING_MODAL,
  SET_SORTING,
  SET_BUTTON_STATUS,
  SET_EMAIL_MARKETING_TOGGLE_ERROR,
  OPEN_EMAIL_MARKETING_TURN_OFF_MODAL,
  ARCHIVE_DISCOUNT_SUCCESS,
  GET_DISCOUNT_SUCCESS,
  GET_BOGO_ITEMS_SUCCESS,
  CLEAR_DISCOUNT,
  SET_BOGO_ITEMS_LOADING,
  GET_BOGO_ITEMS,
  GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS,
  GET_DISCOUNTS_FOR_ITEMS
} from 'store/discountsV2/action';
import {discountsV2AddZToDates} from 'helpers/functionUtils/discountsV2';

const initialSorting = () => ({
  sort: 'asc',
  sortBy: 'name'
});

const initialState = () => ({
  discounts: [],
  shouldShowWarningModal: true,
  page: 1,
  totalDocsCount: 0,
  total: 0,
  hasNextPage: false,
  loading: false,
  buttonStatus: false,
  sorting: initialSorting(),
  discount: {
    name: '',
    type: null,
    rate: {
      type: null,
      value: ''
    },
    subtotal: {
      min: '',
      max: null
    },
    fulfillment: {
      types: ['Pickup', 'Delivery'],
      delivery: null
    },
    customers: {
      types: ['New', 'Returning']
    },
    availability: {
      activatesAtLocal: null,
      deactivatesAtLocal: null,
      weekly: null
    },
    isPromotional: false,
    isExclusive: false,
    promoCode: {
      code: '',
      usageLimit: null
    },
    specificItems: null,
    combo: null,
    bogo: null,
    marketing: {
      email: {
        isEnabled: false
      }
    },
    creationReason: ''
  },
  modalsStack: [],
  isEmailMarketingToggleError: false,
  isEmailMarketingTurnOffModalOpen: false,
  bogoItems: [],
  currentDiscounts: [],
  loadingBogoItems: false,
  emailMarketingTrialDate: null
});

const discountsV2Reducer = createReducer(
  {
    [GET_DISCOUNTS_SUCCESS]: (state, payload) => {
      const {docs} = payload;
      const updatedDiscounts = [...docs].map((el) =>
        discountsV2AddZToDates(el)
      );
      return {
        ...state,
        discounts: [...state.discounts, ...updatedDiscounts],
        totalDocsCount: payload.totalDocs,
        total: state.total + docs.length,
        hasNextPage: payload.hasNextPage,
        page: payload.page
      };
    },
    [GET_DISCOUNT_SUCCESS]: (state, payload) => {
      const updatedDiscount = discountsV2AddZToDates(payload);
      return {
        ...state,
        discount: updatedDiscount
      };
    },
    [SET_LOADING]: (state, payload) => ({
      ...state,
      loading: payload
    }),
    [SET_BUTTON_STATUS]: (state, payload) => ({
      ...state,
      buttonStatus: payload
    }),
    [SET_DISCOUNT]: (state, payload) => ({
      ...state,
      discount: payload
    }),
    [UPDATE_DISCOUNT_SUCCESS]: (state, payload) => ({
      ...state,
      discounts: state.discounts.map((discount) =>
        discount.id === payload.id ? payload : discount
      )
    }),
    [ARCHIVE_DISCOUNT_SUCCESS]: (state, payload) => ({
      ...state,
      discounts: state.discounts.filter((discount) => discount.id !== payload),
      totalDocsCount: state.totalDocsCount - 1
    }),
    [DELETE_DISCOUNT_SUCCESS]: (state, payload) => ({
      ...state,
      discounts: state.discounts.filter((discount) => discount.id !== payload),
      totalDocsCount: state.totalDocsCount - 1
    }),
    [PUSH_MODALS_STACK]: (state, payload) => {
      const stack = [...state.modalsStack];
      stack.push(payload);
      return {
        ...state,
        modalsStack: stack
      };
    },
    [SHIFT_MODALS_STACK]: (state) => {
      const stack = [...state.modalsStack];
      stack.shift();
      return {
        ...state,
        modalsStack: stack
      };
    },
    [CLEAR_STATE]: () => initialState(),
    [CLEAR_DISCOUNTS]: (state) => ({
      ...state,
      discounts: [],
      total: 0,
      hasNextPage: false,
      page: 1,
      totalDocsCount: 0
    }),
    [SET_SORTING]: (state, payload) => {
      return {
        ...state,
        sorting: {
          ...payload
        }
      };
    },
    [SET_WARNING_MODAL]: (store, payload) => ({
      ...store,
      shouldShowWarningModal: payload
    }),
    [SET_EMAIL_MARKETING_TOGGLE_ERROR]: (state, payload) => {
      return {
        ...state,
        isEmailMarketingToggleError: payload
      };
    },
    [OPEN_EMAIL_MARKETING_TURN_OFF_MODAL]: (state, payload) => {
      return {
        ...state,
        isEmailMarketingTurnOffModalOpen: payload
      };
    },
    [GET_BOGO_ITEMS]: (state) => {
      return {
        ...state,
        loadingBogoItems: true
      };
    },
    [GET_BOGO_ITEMS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        bogoItems: payload,
        loadingBogoItems: false
      };
    },
    [GET_DISCOUNTS_FOR_ITEMS]: (state, payload) => {
      return {
        ...state,
        currentDiscounts: payload || []
      };
    },
    [CLEAR_DISCOUNT]: (state) => {
      return {
        ...state,
        discount: initialState().discount,
        bogoItems: initialState().bogoItems,
        currentDiscounts: initialState().currentDiscounts
      };
    },
    [SET_BOGO_ITEMS_LOADING]: (state, payload) => {
      return {
        ...state,
        loadingBogoItems: payload
      };
    },
    [GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        emailMarketingTrialDate: payload.startDate || null
      };
    }
  },
  initialState()
);

export default discountsV2Reducer;
