import {put, takeEvery, select} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  RC_GET_ORDER,
  RC_GET_ORDER_SUCCESS,
  RC_ORDER_INVOICE_ERROR,
  RC_GENERATE_ORDER_REFUND,
  RC_GENERATE_ORDER_REFUND_SUCCESS,
  RC_MAKE_ORDER_REFUND,
  RC_OPEN_CONFIRMATION_MODAL,
  RC_OPEN_RESULT_MODAL,
  RC_SET_LOADING,
  RC_SET_RESULT_LOADING,
  RC_GET_MERCHANTS,
  RC_OPEN_DASHBOARD
} from 'store/refundsCalculator/action';
import {
  rcGetOrderApi,
  rcGenerateOrderRefundApi,
  rcMakeOrderRefundApi,
  rcOpenDashboardApi
} from 'helpers/services/api/refundsCalculator';
import {sortStepsForRefundCalculator} from 'helpers/functionUtils/refundHelper';
import {getActiveMerchantByIdApi} from 'helpers/services/api/merchant';

const refundInfo = (state) => state.refundsCalculator;

const MODAL_TITLE = {
  success: 'Refund is processed successfully',
  error: 'Refund processing failed.'
};

const generateRefundResponseObject = (data) => {
  let result;
  if (data.status === 'Succeeded') {
    result = {title: MODAL_TITLE.success};
  } else {
    result = {
      title: MODAL_TITLE.error,
      subtitleBold: 'Please complete failed transactions manually!',
      steps: sortStepsForRefundCalculator(data.stepResults)
    };
  }
  return result;
};

const getRequestBody = (type, payload) => {
  switch (type) {
    case 0:
      return {
        type,
        refundResponsibility: payload.refundResponsibility
      };
    case 1:
      return {
        type,
        refundResponsibility: payload.refundResponsibility,
        partialRefund: {
          items:
            payload?.orderData?.items
              ?.filter((item) => item.checked)
              .map((item) => {
                return {path: item.path, quantity: item.selected};
              }) || [],
          tip: payload?.partialRefCheckboxes?.tip,
          deliveryFee: payload?.partialRefCheckboxes?.deliveryFee,
          ...(payload?.priceAdjustmentIds.length && {
            priceAdjustmentIds: payload?.priceAdjustmentIds
          })
        }
      };
    case 2:
      return {
        type,
        refundResponsibility: payload.refundResponsibility,
        subtotalRefund: {
          percent: Number(payload.percent)
        }
      };
    case 3: {
      return {
        type: 4,
        reason:
          payload.fixedAmountReason === 'Other'
            ? payload.otherFreeText || 'Other'
            : payload.fixedAmountReason,
        refundResponsibility: payload.refundResponsibility,
        fixedAmountRefund: {
          customerRefundAmount: Number(payload.customerRefundAmount),
          ...(payload.locationReverseAmount && {
            locationReverseAmount: Number(payload.locationReverseAmount)
          })
        }
      };
    }
    default:
      return null;
  }
};

const isEmptyPartialRefund = (obj) => {
  return Object.keys(obj).every((item) => {
    return (
      obj[item] === false || (Array.isArray(obj[item]) && obj[item].length < 1)
    );
  });
};

function* getOrder({payload}) {
  try {
    const {data} = yield rcGetOrderApi(payload?.trim()?.toUpperCase());

    if (data) {
      yield put(RC_GET_ORDER_SUCCESS(data));
      if (data?.order?.merchantId) {
        const merchant = yield getActiveMerchantByIdApi(data.order.merchantId);
        if (merchant?.data) {
          yield put(RC_GET_MERCHANTS(merchant.data));
        }
      }
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    const {response} = error;
    if (response && response?.data?.detail) {
      yield put(
        RC_ORDER_INVOICE_ERROR(
          response?.data?.status === 404
            ? '* Wrong Invoice ID'
            : `* ${response?.data?.detail}`
        )
      );
    }
  }
}

function* generateOrderRefund({payload}) {
  try {
    const currentOrderRefund = yield select(refundInfo);
    const body = getRequestBody(payload, currentOrderRefund);

    if (
      !body ||
      (body?.partialRefund && isEmptyPartialRefund(body?.partialRefund))
    ) {
      yield put(RC_GENERATE_ORDER_REFUND_SUCCESS({location: 0, total: 0}));
      return;
    }
    const {data} = yield rcGenerateOrderRefundApi(
      currentOrderRefund?.orderData?.id,
      body
    );
    if (data) {
      yield put(RC_GENERATE_ORDER_REFUND_SUCCESS(data));
    }
  } catch (error) {
    toast.error('Something went wrong! Please try again later!');
    yield put(RC_SET_RESULT_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* actionsAfterResponse(invoiceId, data) {
  try {
    yield put(RC_OPEN_CONFIRMATION_MODAL(false));
    yield put(RC_GET_ORDER(invoiceId));
    yield put(
      RC_OPEN_RESULT_MODAL({
        isOpen: true,
        info: generateRefundResponseObject(data)
      })
    );
    yield put(RC_SET_LOADING(false));
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* makeOrderRefund({payload}) {
  const currentOrderRefund = yield select(refundInfo);
  try {
    yield put(RC_SET_LOADING(true));
    const body = getRequestBody(payload, currentOrderRefund);

    const {data} = yield rcMakeOrderRefundApi(
      currentOrderRefund?.orderData?.id,
      body
    );
    if (data) {
      yield actionsAfterResponse(
        currentOrderRefund?.orderData?.invoiceId,
        data
      );
    }
  } catch (error) {
    yield actionsAfterResponse(currentOrderRefund?.orderData?.invoiceId, {
      error: error.response?.data
    });
    console.log('SAGA ERROR', error);
  }
}

function* openDashboard({payload}) {
  try {
    const {data} = yield rcOpenDashboardApi(payload.userId);
    if (data) {
      window.open(
        `${window.location.origin}?token=${data.token}&locationId=${payload.locationId}`,
        '_blank'
      );
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* refundsCalculatorSaga() {
  yield takeEvery(RC_GET_ORDER, getOrder);
  yield takeEvery(RC_GENERATE_ORDER_REFUND, generateOrderRefund);
  yield takeEvery(RC_MAKE_ORDER_REFUND, makeOrderRefund);
  yield takeEvery(RC_OPEN_DASHBOARD, openDashboard);
}

export default refundsCalculatorSaga;
