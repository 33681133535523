import {put, select, takeEvery} from 'redux-saga/effects';
import {
  CREATE_DISCOUNT,
  CREATE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT,
  DELETE_DISCOUNT_SUCCESS,
  GET_DISCOUNT,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNTS,
  GET_DISCOUNTS_SUCCESS,
  PUBLISH_DISCOUNTS,
  SET_LOADING,
  UPDATE_DISCOUNT,
  UPDATE_DISCOUNT_SUCCESS,
  SET_BUTTON_STATUS,
  SET_EMAIL_MARKETING_TOGGLE_ERROR,
  GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS
} from './action';
import {
  getDiscountByIdApi,
  getDiscountsApi,
  postDiscountApi,
  deleteDiscountApi,
  updateDiscountApi,
  publishDiscountsAPi
} from '../../helpers/services/api/discounts';
import history from '../../history';
import {RUN_GET_PUBLISH_STATUS_JOB} from '../global/globalNotifications/action';
import {TASK_TYPE_SETTINGS_DATA_UPDATE} from '../global/globalNotifications/constants';
import {getEmailMarketingTrialDateApi} from '../../helpers/services/api/emailMarketing';

function* checkError(error) {
  try {
    if (
      error &&
      error.response?.data?.errors?.find(
        (el) =>
          el.message ===
          'Another discount was already selected for Email Marketing, you can use only one'
      )
    ) {
      yield put(SET_EMAIL_MARKETING_TOGGLE_ERROR(true));
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* checkEmailMarketingTrialDate() {
  const locationId = yield select((state) => state.user.locationId);
  const {activeLocation} = yield select((state) => state.user);
  if (activeLocation?.AllowedServiceFeatures?.includes('email_marketing_pcs')) {
    try {
      const emailMarketingTrialResponse = yield getEmailMarketingTrialDateApi(
        locationId
      );
      if (emailMarketingTrialResponse?.data) {
        yield put(
          GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS(
            emailMarketingTrialResponse.data
          )
        );
      }
    } catch (error) {
      yield put(GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS({startDate: null}));
      console.log('SAGA ERROR', error);
    }
  } else {
    yield put(GET_EMAIL_MARKETING_TRIAL_DATE_SUCCESS({startDate: null}));
  }
}

function* getDiscountsSaga({payload}) {
  try {
    yield put(SET_LOADING(true));
    const {status, ...rest} = payload;
    yield checkEmailMarketingTrialDate();
    const {data} = yield getDiscountsApi(status, rest);
    if (data) {
      yield put(GET_DISCOUNTS_SUCCESS(data));
      yield put(SET_LOADING(false));
    }
  } catch (error) {
    yield put(SET_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* getDiscountByIdSaga({payload}) {
  try {
    yield put(SET_LOADING(true));
    yield checkEmailMarketingTrialDate();
    const {data} = yield getDiscountByIdApi(payload);
    if (data) {
      yield put(GET_DISCOUNT_SUCCESS(data));
      yield put(SET_LOADING(false));
    }
  } catch (error) {
    yield put(SET_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* createDiscountSaga({
  payload: {payload, postCallback, errorCallback}
}) {
  try {
    const data = yield postDiscountApi(payload);

    if (data && data.status === 200) {
      yield put(CREATE_DISCOUNT_SUCCESS(data.data));
      history.push('/discounts');
      if (postCallback) postCallback();
    }
    yield put(SET_BUTTON_STATUS(false));
  } catch (error) {
    yield put(SET_BUTTON_STATUS(false));
    if (errorCallback) errorCallback();
    yield checkError(error);
    console.log('SAGA ERROR', error);
  }
}

function* updateDiscountSaga({
  payload: {payload, postCallback, errorCallback}
}) {
  try {
    const data = yield updateDiscountApi(payload.id, payload.discount);
    if (data && data.status === 204) {
      yield put(UPDATE_DISCOUNT_SUCCESS(payload));
      history.push('/discounts');
      if (postCallback) postCallback();
    }
    yield put(SET_BUTTON_STATUS(false));
  } catch (error) {
    yield put(SET_BUTTON_STATUS(false));
    if (errorCallback) errorCallback();
    yield checkError(error);
    console.log('SAGA ERROR', error);
  }
}

function* deleteDiscountSaga({
  payload: {payload, postCallback, errorCallback}
}) {
  try {
    const data = yield deleteDiscountApi(payload);
    if (data && data.status === 200) {
      yield put(DELETE_DISCOUNT_SUCCESS(payload));
      history.push('/discounts');
      if (postCallback) postCallback();
    }
  } catch (error) {
    if (errorCallback) errorCallback();
    console.log('SAGA ERROR', error);
  }
}

export function* publishDiscounts({payload}) {
  const {preCallback, errorCallback} = payload;
  try {
    preCallback();
    yield put(
      RUN_GET_PUBLISH_STATUS_JOB({SyncType: TASK_TYPE_SETTINGS_DATA_UPDATE})
    );

    const menuConfig = yield select(
      (state) => state.user.activeLocation?.MenuConfigId
    );

    yield publishDiscountsAPi(menuConfig);
  } catch (error) {
    if (errorCallback) errorCallback();

    console.log('SAGA ERROR', error);
  }
}

function* discountsSaga() {
  yield takeEvery(GET_DISCOUNT, getDiscountByIdSaga);
  yield takeEvery(GET_DISCOUNTS, getDiscountsSaga);
  yield takeEvery(CREATE_DISCOUNT, createDiscountSaga);
  yield takeEvery(DELETE_DISCOUNT, deleteDiscountSaga);
  yield takeEvery(UPDATE_DISCOUNT, updateDiscountSaga);
  yield takeEvery(PUBLISH_DISCOUNTS, publishDiscounts);
}

export default discountsSaga;
