import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import RestrictedArea from 'components/layout/RestrictedArea';
import Dashboard from 'routes/App/dashboard';
import ReportsContainer from 'routes/App/reports';
import Settings from 'routes/App/settings';
import VirtualNumber from 'routes/App/virtualNumber';
import MerchantsRoutes from 'routes/App/merchants';
import TeamContainer from 'routes/App/usersManagement/TeamContainer';
import CreateLanding from 'routes/App/account/MyAccount/createLanding';
import BankAccount from 'routes/App/account/MyAccount/bankAccount';
import TeamMembers from 'routes/App/account/MyAccount/teamMembers';
import GoogleMyBusiness from 'routes/App/googleMyBusiness/GoogleMyBusiness';
import AddLink from 'routes/App/account/addLink';
import UberEats from 'routes/App/uberEats';
import AccountRoutes from 'routes/App/account';
import Discounts from 'routes/App/discounts';
import DiscountsV2 from 'routes/App/discountsV2';
import BYOCWizard from 'routes/App/byocWizard';
import BookADriverRoutes from 'routes/App/BookADriver';
import {
  FEATURE_RESTRICTION_IDS,
  FEATURE_RESTRICTION_PATHS,
  getBookADriverRestriction,
  getInsightsRestriction
} from 'helpers/constants/restrictions';
import removeElementArray from 'helpers/functionUtils/removeElementArray';
import FeedbackRoutes from 'routes/App/feedback';
import NoMatchPage from 'routes/App/noMatchPage';
import HubspotChat from 'routes/App/HubspotChat';
import EmailMarketing from 'routes/App/emailMarketing';
import InsightsRoutes from 'routes/App/Insights';
import MarketingIntegrations from 'routes/App/MarketingIntegrations';
import Flyers from 'routes/App/Flyers';

const OnlineRoutes = () => {
  const status = useSelector((state) => state.user.userData?.status);
  const serviceFeatures = useSelector(
    (state) => state.user.activeLocation.AllowedServiceFeatures
  );
  const user = useSelector((state) => state.user);
  const location = useLocation();

  return (
    <>
      <RestrictedArea
        restrictions={['analyticsAccess']}
        features={[FEATURE_RESTRICTION_IDS.ANALYTICS]}
      >
        <Route exact path='/analytics' component={Dashboard} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.REPORTS]}
      >
        <Route exact path='/reports' component={ReportsContainer} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.STORE_SETTINGS]}
      >
        <Route exact path='/store-settings' component={Settings} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[getInsightsRestriction(serviceFeatures)]}
      >
        <Route path='/insights' component={InsightsRoutes} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.EMAIL_MARKETING]}
      >
        <Route exact path='/marketing' component={EmailMarketing} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.FLYERS]}
      >
        <Route exact path='/flyers' component={Flyers} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={['virtualNumberAccess']}
        features={[FEATURE_RESTRICTION_IDS.VIRTUAL_NUMBERS]}
        extraAccessLogic={() => Boolean(user.activeLocation?.GeneratedLinkURL)}
      >
        <Route exact path='/virtual-number' component={VirtualNumber} />
      </RestrictedArea>
      {!isMobile && (
        <RestrictedArea
          restrictions={[]}
          features={[FEATURE_RESTRICTION_IDS.FEEDBACK]}
        >
          <Route exact path='/feedback' component={FeedbackRoutes} />
        </RestrictedArea>
      )}
      {/* <RestrictedArea restrictions={[]} features={[FEATURE_RESTRICTION_IDS.FEEDBACKS]}> */}
      {/*   <Route exact path='/reviews' component={Reviews} /> */}
      {/* </RestrictedArea> */}
      <RestrictedArea
        restrictions={[]}
        features={[getBookADriverRestriction(serviceFeatures)]}
      >
        <Route path='/book-a-driver' component={BookADriverRoutes} />
      </RestrictedArea>
      <Route path='/merchants' component={MerchantsRoutes} />
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.UBER_EATS]}
      >
        <Route exact path='/uber-eats' component={UberEats} />
        <Route exact path='/uber-eats/byoc-wizard' component={BYOCWizard} />
        <Route exact path='/uber-eats/connect' component={UberEats} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.DISCOUNTS]}
      >
        <Route path='/discounts' component={Discounts} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.DISCOUNTS_V2]}
      >
        <Route path='/discounts-v2' component={DiscountsV2} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.TEAM]}
      >
        <Route exact path='/team' component={TeamContainer} />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.MARKETING_INTEGRATIONS]}
      >
        <Route
          path='/marketing-integrations'
          component={MarketingIntegrations}
        />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.MY_ACCOUNT]}
      >
        <Route path='/my-account' component={AccountRoutes} />
        <Route
          exact
          path='/my-account/create-landing'
          component={CreateLanding}
        />
        <Route
          exact
          path='/my-account/verify-your-bank-account'
          component={BankAccount}
        />
        <Route exact path='/my-account/team-members' component={TeamMembers} />
        <Route exact path='/my-account/add-a-link' component={AddLink} />
      </RestrictedArea>
      <Route
        exact
        path='/google-my-business-redirect'
        component={GoogleMyBusiness}
      />
      <Route
        path='/login_token'
        render={(p) => p.history.push('/my-account')}
      />

      <Route
        path='/'
        render={() => {
          let path;
          if (
            location.pathname === '/' &&
            status &&
            status === 'ACTIVE' &&
            serviceFeatures.includes(FEATURE_RESTRICTION_IDS.ORDERS)
          ) {
            path = '/orders';
          } else if (
            location.pathname !== '/' &&
            status !== 'NEW' &&
            serviceFeatures.find(
              (feature) =>
                feature === location.pathname.split('-').join('_').split('/')[1]
            )
          ) {
            path = location.pathname;
          } else if (
            location.pathname.startsWith('/book-a-driver/') &&
            (serviceFeatures.includes(FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER) ||
              serviceFeatures.includes(
                FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER_V2
              ))
          ) {
            path = location.pathname;
          } else if (
            location.pathname.indexOf('/insights') === 0 &&
            !serviceFeatures.includes(
              FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH
            ) &&
            !serviceFeatures.includes(
              FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE
            )
          ) {
            path = '/';
          } else if (
            (location.pathname.indexOf('/insights/growth') === 0 &&
              serviceFeatures.includes(
                FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH
              )) ||
            (location.pathname.indexOf('/insights/performance') === 0 &&
              serviceFeatures.includes(
                FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE
              ))
          ) {
            path = location.pathname;
          } else if (
            (location.pathname.indexOf('/insights/growth') === 0 &&
              !serviceFeatures.includes(
                FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH
              )) ||
            (location.pathname.indexOf('/insights/performance') === 0 &&
              !serviceFeatures.includes(
                FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE
              ))
          ) {
            path = '/insights';
          } else if (
            location.pathname.startsWith('/marketing-integrations/') &&
            serviceFeatures.includes(
              FEATURE_RESTRICTION_IDS.MARKETING_INTEGRATIONS
            )
          ) {
            path = location.pathname;
          } else if (
            serviceFeatures.includes(FEATURE_RESTRICTION_IDS.MY_ACCOUNT)
          ) {
            path = '/my-account';
          } else {
            path =
              FEATURE_RESTRICTION_PATHS[
                removeElementArray(
                  serviceFeatures,
                  FEATURE_RESTRICTION_IDS.HELP
                )[0]
              ];
          }

          return <Redirect to={path} />;
        }}
      />
      {!serviceFeatures.length && <NoMatchPage />}
    </>
  );
};

export default OnlineRoutes;
