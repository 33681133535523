import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {motion, AnimatePresence} from 'framer-motion';
import {
  CountdownFlip,
  CountdownTimeLabel,
  CountdownTimerBox
} from 'components/EmailMarketingCountdown/styles';

const FlipNumber = ({value, label}) => {
  const [prevValue, setPrevValue] = useState(value);

  useEffect(() => {
    if (value !== prevValue) {
      setPrevValue(value);
    }
  }, [value]);

  return (
    <CountdownTimerBox>
      <CountdownFlip>
        <AnimatePresence mode='wait'>
          <motion.div
            key={value}
            className='flip-number'
            initial={{y: -20, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: 20, opacity: 0}}
            transition={{duration: 0.4, ease: 'easeInOut'}}
          >
            {String(value).padStart(2, '0')}
          </motion.div>
        </AnimatePresence>
      </CountdownFlip>
      <CountdownTimeLabel>{label}</CountdownTimeLabel>
    </CountdownTimerBox>
  );
};

FlipNumber.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
};

export default FlipNumber;
