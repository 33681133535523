import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListSubheader
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import Select from '@material-ui/core/Select';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import {useHistory} from 'react-router-dom';
import {
  DashboardField,
  DashboardFieldDatepicker,
  DashboardFieldInner,
  DashboardFieldLabel,
  DashboardFields,
  DashboardHeader,
  DashboardHolder,
  DashboardInnerDescription,
  DashboardText,
  FieldInput,
  FieldInputDollar,
  FieldLabel
} from 'routes/App/dashboard/styles';
import DatepickerS2E from 'components/Datepicker';
import LinkBack from 'components/LinkBack/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import SwitchS2E from 'components/Switch';
import {DashboardInner} from 'components/DashboardInner/styles';
import Subtitle5 from 'components/typography/Subtitle5';
import Subtitle4 from 'components/typography/Subtitle4';
import {ButtonInfo, ButtonPrimary} from 'components/buttons';
import {DISCOUNT_STATUS, DISCOUNT_TYPE} from 'helpers/constants/discountsV2';
import SelectWrapper from 'components/select/SelectComponentStyles';
import {
  getBogoItems,
  getCurrentDiscounts,
  getEmailMarketingTrialDate,
  getLoadingBogoItems
} from 'store/discountsV2/selectors';
import {FlexContainer, FlexItem} from 'components/layout';
import SearchField from 'components/fields/SearchField';
import {CLEAR_DISCOUNT, GET_BOGO_ITEMS} from 'store/discountsV2/action';
import Loading from 'components/shared/Utils/Loading';
import {isEmailMarketingTrialExpired} from 'helpers/functionUtils/emailMarketing';
import {twoDecimalsFormatter} from 'helpers/functionUtils/helpFunctions';

const ItemDiscountSettings = (props) => {
  const {
    formik,
    discount,
    enabledDiscount,
    setEnabledDiscount,
    enabledDate,
    setEnabledDate,
    buttonStatus,
    allowedServiceFeatures
  } = props;

  const dispatch = useDispatch();
  const {activeLocation} = useSelector((state) => state.user);
  const specificItems = getBogoItems();
  const currentDiscounts = getCurrentDiscounts();
  const loadingSpecificItems = getLoadingBogoItems();
  const [selectedSpecificItems, setSelectedSpecificItems] = useState([]);
  const [filteredSpecificItems, setFilteredSpecificItems] = useState([]);
  const [search, setSearch] = useState('');
  const [openSelect, setOpenSelect] = useState(false);
  const emailMarketingTrialDate = getEmailMarketingTrialDate();
  const {Timezone} = useSelector((state) => state.user.activeLocation);

  const history = useHistory();

  useEffect(() => {
    if (specificItems?.length > 0) {
      setFilteredSpecificItems(specificItems);
      if (formik?.values?.specificItems?.discounted?.length > 0) {
        setSelectedSpecificItems(
          [formik?.values?.specificItems?.discounted[0]?.posId] || []
        );
      } else {
        setSelectedSpecificItems([]);
      }
    }
  }, [specificItems]);

  useEffect(() => {
    if (!specificItems || specificItems.length === 0) {
      dispatch(GET_BOGO_ITEMS());
    }

    return () => {
      dispatch(CLEAR_DISCOUNT());
    };
  }, []);

  const handleSearchSpecificItems = (value) => {
    setSearch(value);
    if (specificItems.length > 0) {
      setFilteredSpecificItems(
        specificItems.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredSpecificItems(specificItems);
    }
  };

  const handleSpecificItemsSelect = (value) => {
    if (value) {
      setSelectedSpecificItems([value.posId]);
      formik.setFieldValue('specificItems.discounted', [
        {...value, type: 'Item'}
      ]);
    }
  };

  const handleOpen = () => {
    setOpenSelect(!openSelect);
  };

  const handleSearchClear = () => {
    setSearch('');
    setFilteredSpecificItems(specificItems);
  };

  const discountMap = useMemo(() => {
    const map = new Map();

    if (!currentDiscounts || currentDiscounts.length === 0) {
      return map;
    }

    currentDiscounts.forEach((currentDiscount) => {
      currentDiscount.items.forEach((item) => {
        if (!map.has(item.posId)) {
          map.set(item.posId, []);
        }
        map.get(item.posId).push({
          discountId: currentDiscount.discountId,
          discountName: currentDiscount.discountName
        });
      });
    });
    return map;
  }, [currentDiscounts]);

  return (
    <>
      <DashboardHeader className='mb10'>
        <LinkBack onClick={() => history.push('/discounts-v2')}>
          Back to all Discounts
        </LinkBack>
      </DashboardHeader>
      <DashboardInner>
        <Subtitle5 className='marginBottomXs'>Discount wizard</Subtitle5>
        <Subtitle4>
          <span>{discount?.name}</span>
        </Subtitle4>
        <DashboardHolder className='marginTopLg'>
          <DashboardFields>
            <DashboardField className='w100 dFlex'>
              <DashboardFieldLabel>
                <FieldLabel
                  className={cn('FieldLabelLg marginBtNull', {
                    isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                  })}
                >
                  Discount Name
                </FieldLabel>
              </DashboardFieldLabel>
              <DashboardFieldInner className='w60FLex FieldRelative'>
                <FieldInput
                  className={cn('', {
                    error: formik?.errors?.name && formik?.touched?.name,
                    isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                  })}
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder='Discount Name'
                />
                <ErrorSignWrapper className='ErrorSignWrapper'>
                  {formik?.errors?.name && formik?.touched?.name ? (
                    <ErrorSign>{formik.errors.name}</ErrorSign>
                  ) : null}
                </ErrorSignWrapper>
              </DashboardFieldInner>
              <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                <div className='DropInfo DropInfoRight DropInfoRightMob'>
                  This name will be available for customers to see
                </div>
              </ButtonInfo>
            </DashboardField>
            <DashboardField className='w100 dFlex'>
              <DashboardFields className='marginMinus'>
                <DashboardField className='wAuto w50mob'>
                  <FieldLabel className='FieldLabelLg'>
                    Discount Type
                  </FieldLabel>
                  <SelectWrapper className='SelectMd w100'>
                    <FormControl className='select-formcontrol'>
                      <Select
                        value={formik.values?.rate?.type}
                        name='rate.type'
                        onChange={(e) => {
                          formik.setFieldValue('rate.type', e.target.value);
                        }}
                        className={cn('', {
                          error:
                            formik.errors?.rate?.type &&
                            formik.touched?.rate?.type,
                          isDisabled:
                            discount.status === DISCOUNT_STATUS.ARCHIVED
                        })}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        <MenuItem value={DISCOUNT_TYPE.FIXED}>$</MenuItem>
                        <MenuItem value={DISCOUNT_TYPE.PERCENTAGE}>%</MenuItem>
                      </Select>
                    </FormControl>
                  </SelectWrapper>
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.rate?.type && formik.touched?.rate?.type ? (
                      <ErrorSign>{formik.errors?.rate?.type}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAuto w50mob'>
                  <FieldLabel
                    className={cn('FieldLabelLg', {
                      isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                    })}
                  >
                    Discount Value
                  </FieldLabel>
                  <FieldInput
                    className={cn('FieldNumberLg', {
                      error:
                        formik.errors?.rate?.value &&
                        formik.touched?.rate?.value,
                      isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                    })}
                    name='rate.value'
                    type='text'
                    onChange={(e) =>
                      formik.setFieldValue(
                        'rate.value',
                        twoDecimalsFormatter(
                          e.target.value,
                          formik.values?.rate?.value,
                          formik.values?.rate?.type === DISCOUNT_TYPE.PERCENTAGE
                            ? 99
                            : 9999.99
                        ) || ''
                      )
                    }
                    onBlur={(e) => {
                      const lastElement = e.target.value.slice(-1)[0];
                      if (lastElement === '.') {
                        formik.setFieldValue(
                          'rate.value',
                          e.target.value.slice(0, -1)
                        );
                      }
                    }}
                    value={formik.values?.rate?.value}
                    placeholder=''
                  />
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.rate?.value &&
                    formik.touched?.rate?.value ? (
                      <ErrorSign>{formik.errors?.rate?.value}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFields>
            </DashboardField>
            <DashboardField className='dFlex w100 DiscountDatepicker'>
              <FormControlLabel
                className='Say2EatCheckbox'
                control={
                  <Checkbox
                    labelStyle={{color: '#2F3D48'}}
                    iconStyle={{fill: '#FF2D38'}}
                    inputStyle={{color: '#FF2D38'}}
                    style={{
                      color: '#FF2D38',
                      opacity:
                        discount.status === DISCOUNT_STATUS.ARCHIVED
                          ? '0.3'
                          : null
                    }}
                    onChange={(e) => {
                      const {checked} = e.target;
                      setEnabledDate(checked);
                      if (!checked) {
                        formik.setFieldValue(
                          'availability.activatesAtLocal',
                          null
                        );
                        formik.setFieldValue(
                          'availability.deactivatesAtLocal',
                          null
                        );
                      }
                    }}
                    checked={enabledDate}
                    disabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
                  />
                }
                label='From'
              />
              <DashboardFieldDatepicker>
                <DatepickerS2E
                  hideTextFrom
                  disablePast
                  disabled={
                    !enabledDate || discount.status === DISCOUNT_STATUS.ARCHIVED
                  }
                  value={[
                    formik.values?.availability?.activatesAtLocal
                      ? moment(formik.values.availability.activatesAtLocal)
                          .utc()
                          .format('MM/DD/yy')
                      : null,
                    formik.values?.availability?.deactivatesAtLocal
                      ? moment(formik.values.availability.deactivatesAtLocal)
                          .utc()
                          .format('MM/DD/yy')
                      : null
                  ]}
                  onClose={(value) => {
                    if (value && value.length) {
                      formik.setFieldValue(
                        'availability.activatesAtLocal',
                        moment(value[0])
                          .utcOffset(0, true)
                          .startOf('day')
                          .format()
                      );
                      formik.setFieldValue(
                        'availability.deactivatesAtLocal',
                        moment(value[1])
                          .utcOffset(0, true)
                          .endOf('day')
                          .format()
                      );
                    }
                  }}
                />
              </DashboardFieldDatepicker>
              <ErrorSignWrapper
                className='ErrorSignWrapper'
                leftPosition='93px'
              >
                {formik.errors?.availability?.activatesAtLocal &&
                formik.touched?.availability?.activatesAtLocal ? (
                  <ErrorSign>
                    {formik.errors.availability.activatesAtLocal}
                  </ErrorSign>
                ) : null}
              </ErrorSignWrapper>
            </DashboardField>
            {/* <DashboardField>
              <DashboardInnerDescription className='withIcon'>
                <span>
                  This discount will be available between the following dates,
                  all week, all day, or until the Usage Limit is reached.
                </span>
              </DashboardInnerDescription>
            </DashboardField> */}
            <DashboardField>
              <DashboardInnerDescription className='withIcon'>
                <span>
                  This discount will be available between the following dates.
                </span>
              </DashboardInnerDescription>
            </DashboardField>
            {/* <DashboardField className='w100 noWrap'>
              <SwitchS2E
                labelPlacement='start'
                label={`Promo Code ${enabledDiscount ? 'Enabled' : 'Disabled'}`}
                checked={enabledDiscount}
                onChange={(e) => {
                  if (!e.target.checked) {
                    formik.setFieldValue('promoCode', null);
                  } else {
                    formik.setFieldValue('promoCode', {
                      code: '',
                      usageLimit: null
                    });
                  }
                  setEnabledDiscount(e.target.checked);
                }}
                name='checkedActive'
                isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
              />
              <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                  Customers will need to enter code before redeeming discount
                </div>
              </ButtonInfo>
            </DashboardField> */}
            {/* {enabledDiscount && (
              <>
                <DashboardField className='dFlex w100'>
                  <DashboardFieldLabel>
                    <FieldLabel
                      className={cn('FieldLabelLg inline-label', {
                        isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                      })}
                    >
                      Promo Code
                    </FieldLabel>
                  </DashboardFieldLabel>
                  <DashboardFieldInner className='w60 FieldRelative'>
                    <FieldInput
                      className={cn('', {
                        error: formik.errors?.promoCode?.code,
                        isDisabled: discount.status === DISCOUNT_STATUS.ARCHIVED
                      })}
                      name='promoCode.code'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values?.promoCode?.code
                          ? formik.values.promoCode.code.toUpperCase()
                          : null
                      }
                      placeholder=''
                    />
                    <ErrorSignWrapper className='ErrorSignWrapper'>
                      {formik.errors?.promoCode?.code &&
                      formik.touched?.promoCode?.code ? (
                        <ErrorSign>{formik.errors.promoCode.code}</ErrorSign>
                      ) : null}
                    </ErrorSignWrapper>
                  </DashboardFieldInner>
                </DashboardField>
                {allowedServiceFeatures.includes('one_time_coupon') && (
                  <DashboardField className='w100 noWrap'>
                    <SwitchS2E
                      labelPlacement='start'
                      label='Single Use'
                      checked={
                        formik.values?.promoCode?.usageLimit?.type ===
                          'PerCustomer' &&
                        formik.values?.promoCode?.usageLimit?.maxUsages === 1
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          formik.setFieldValue(
                            'promoCode.usageLimit.type',
                            'PerCustomer'
                          );
                          formik.setFieldValue(
                            'promoCode.usageLimit.maxUsages',
                            1
                          );
                          formik.setFieldValue(
                            'promoCode.usageLimit.activatesAtLocal',
                            discount?.promoCode?.usageLimit?.activatesAtLocal ||
                              moment()
                                .utcOffset(
                                  Number(activeLocation?.Timezone) || 0,
                                  false
                                )
                                .utc(true)
                                .format()
                          );
                        } else {
                          formik.setFieldValue('promoCode.usageLimit', null);
                        }
                      }}
                      name='OneTimeMode.IsEnabled'
                      isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
                    />
                    <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                      <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                        Turning on Single Use will allow your Promo Code to be
                        redeemed only once per customer
                      </div>
                    </ButtonInfo>
                  </DashboardField>
                )}
              </>
            )} */}
            {/* {(allowedServiceFeatures.includes('email_campaigns_active') ||
             (emailMarketingTrialDate &&
                !isEmailMarketingTrialExpired(
                  emailMarketingTrialDate,
                  Timezone
                ))) && (
              <DashboardFieldInner>
                <DashboardField className='w100 noWrap'>
                  <SwitchS2E
                    labelPlacement='start'
                    label='Include in Email Marketing'
                    checked={formik.values?.marketing?.email?.isEnabled}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        dispatch(OPEN_EMAIL_MARKETING_TURN_OFF_MODAL(true));
                      } else {
                        formik.setFieldValue('marketing.email.isEnabled', true);
                      }
                    }}
                    name='marketing.email.isEanbled'
                    isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
                  />
                  <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                    <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                      Turning on Include in Email Marketing will display this discount in
                      your email marketing campaigns.
                    </div>
                  </ButtonInfo>
                  <ErrorSignWrapper className='ErrorSignWrapper noWrap'>
                    {formik.errors?.marketing?.email?.isEnabled &&
                    formik.touched?.marketing?.email?.isEnabled ? (
                      <ErrorSign>
                        {formik.errors.marketing.email.isEnabled}
                      </ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFieldInner>
            )} */}
            <DashboardField className='w100 noWrap'>
              <SwitchS2E
                labelPlacement='start'
                label='Display above Menu'
                checked={formik.values.isPromotional}
                onChange={formik.handleChange}
                name='isPromotional'
                isDisabled={discount.status === DISCOUNT_STATUS.ARCHIVED}
              />
              <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                  Promote discount at the top of your menu for maximum
                  visibility
                </div>
              </ButtonInfo>
            </DashboardField>
            <DashboardField className='w50 noWrapDesktop'>
              <SelectWrapper className='SelectMd w100Auto AlignCenter'>
                <FormControl className='select-formcontrol'>
                  <Select
                    value={
                      selectedSpecificItems.length > 0
                        ? selectedSpecificItems
                        : []
                    }
                    name='specificItems.discounted'
                    open={openSelect}
                    multiple
                    displayEmpty
                    onOpen={handleOpen}
                    onClose={handleOpen}
                    onChange={() => {}}
                    className={
                      formik?.errors?.specificItems?.discounted && 'error'
                    }
                    renderValue={(selected) => {
                      if (!selected || selected.length === 0) {
                        return <span style={{color: '#aaa'}}>Select item</span>;
                      }

                      return selected
                        .map(
                          (item) =>
                            specificItems.find((el) => item === el.posId)?.name
                        )
                        ?.join(', ');
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 220,
                          overflowY: 'auto',
                          maxWidth: 250
                        }
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {loadingSpecificItems ? (
                      <Loading />
                    ) : (
                      [
                        <div
                          key='search-container'
                          style={{
                            position: 'sticky',
                            top: 0,
                            background: 'white',
                            zIndex: 100
                          }}
                        >
                          <FlexContainer>
                            <FlexItem
                              className='padding10 bogoSearch'
                              flex='1 1 auto'
                            >
                              <SearchField
                                placeholder='Search by item'
                                value={search}
                                onChange={(e) =>
                                  handleSearchSpecificItems(e.target.value)
                                }
                                onClear={handleSearchClear}
                                isClearIconVisible={search?.length > 0}
                              />
                            </FlexItem>
                          </FlexContainer>
                        </div>,
                        ...filteredSpecificItems?.map((item) => {
                          const existingDiscounts =
                            discountMap.get(item.posId) || [];

                          const isUsedInCurrent = existingDiscounts.some(
                            (entry) => entry.discountId === discount?.id
                          );

                          const isUsedInOthers = existingDiscounts.some(
                            (entry) => entry.discountId !== discount?.id
                          );

                          const isDisabled = isUsedInOthers && !isUsedInCurrent;

                          const otherDiscountNames = existingDiscounts
                            .filter(
                              (entry) => entry.discountId !== discount?.id
                            )
                            .map((entry) => entry.discountName)
                            .join(', ');

                          return (
                            <ListItem
                              className='listItemText'
                              onClick={() => {
                                if (!isDisabled) {
                                  handleSpecificItemsSelect(item);
                                  handleOpen();
                                  handleSearchClear();
                                }
                              }}
                              key={item.posId}
                              value={item.posId}
                              disabled={isDisabled}
                              title={
                                otherDiscountNames
                                  ? `Already in: ${otherDiscountNames}`
                                  : ''
                              }
                            >
                              {item.name}
                            </ListItem>
                          );
                        })
                      ]
                    )}
                  </Select>
                </FormControl>
                <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                  <div className='DropInfo DropInfoRight DropInfoRightMob'>
                    Each item can belong to only one discount at a time
                  </div>
                </ButtonInfo>
              </SelectWrapper>
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik?.errors?.specificItems?.discounted &&
                    formik.errors.specificItems.discounted}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
          </DashboardFields>
          <DashboardField className='paddingL0'>
            <DashboardInnerDescription className='withIcon'>
              <span>Guests must add above item to redeem discount.</span>
            </DashboardInnerDescription>
          </DashboardField>
        </DashboardHolder>

        <div className='card-buttons'>
          <ButtonPrimary
            type='button'
            className={cn('ButtonDashboard', {
              disabled:
                buttonStatus || discount.status === DISCOUNT_STATUS.ARCHIVED
            })}
            onClick={(e) => {
              e.stopPropagation();

              setEnabledDiscount(false);

              if (
                formik.values?.rate?.type === DISCOUNT_TYPE.PERCENTAGE &&
                formik.values?.rate?.value > 95
              ) {
                formik.setFieldError(
                  'rate.value',
                  'Discount cannot be bigger than 95%!'
                );
                return;
              }
              formik.setFieldValue('subtotal.min', 0);
              formik.setFieldValue('promoCode', null);

              formik.handleSubmit(e);
            }}
          >
            <span>Apply</span>
          </ButtonPrimary>
        </div>
      </DashboardInner>
    </>
  );
};

ItemDiscountSettings.propTypes = {
  formik: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
  enabledDiscount: PropTypes.bool.isRequired,
  setEnabledDiscount: PropTypes.func.isRequired,
  enabledDate: PropTypes.bool.isRequired,
  setEnabledDate: PropTypes.func.isRequired,
  buttonStatus: PropTypes.bool.isRequired,
  allowedServiceFeatures: PropTypes.array.isRequired
};

export default ItemDiscountSettings;
