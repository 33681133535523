import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import Headline from 'components/Headline/styles';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import UpsellPage from 'routes/App/emailMarketing/components/UpsellPage';
import MarketingContactUsModal from 'routes/App/emailMarketing/components/Modals/MarketingContactUsModal';
import ResultPage from 'routes/App/emailMarketing/components/ResultPage';
import {ButtonInfo} from 'components/buttons';
import InformationModal from 'routes/App/emailMarketing/components/Modals/InformationModal';
import {
  OPEN_INFORMATION_MODAL,
  GET_EMAIL_MARKETING_TRIAL_DATE
} from 'store/emailMarketing/action';
import sendGtmEvent from 'helpers/services/utils/gtm';
import {EMAIL_MARKETING_TRIAL_DATE_EXPIRED} from 'helpers/constants/emailMarketing';

const EmailMarketing = () => {
  const dispatch = useDispatch();
  const {
    isOpenModal,
    isOpenInformationModal,
    spinner,
    emailMarketingTrialDate,
    emailMarketingTrialDateStatus
  } = useSelector((state) => state.emailMarketingReducer);
  const {activeLocation} = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(GET_EMAIL_MARKETING_TRIAL_DATE());
    if (activeLocation?._id) {
      sendGtmEvent('view-marketing-tab', {
        type: activeLocation.AllowedServiceFeatures.includes(
          'show_marketing_results'
        )
          ? 'paying'
          : 'non-paying',
        location_name: activeLocation.FullName,
        location_id: activeLocation._id
      });
    }
  }, [activeLocation._id]);

  return (
    <DashboardContent
      className={cn('DashboardContent', {
        centerSpinner: spinner
      })}
    >
      <DashboardHeader
        className={cn('AlwaysFlex', {
          flex0: spinner
        })}
      >
        <Headline className='mTop5'>Email Marketing</Headline>
        {activeLocation.AllowedServiceFeatures.includes(
          'show_marketing_results'
        ) ||
          (activeLocation.AllowedServiceFeatures.includes(
            'email_marketing_pcs'
          ) &&
            (emailMarketingTrialDate !== null ||
              emailMarketingTrialDateStatus ===
                EMAIL_MARKETING_TRIAL_DATE_EXPIRED) && (
              <ButtonInfo
                onClick={() => dispatch(OPEN_INFORMATION_MODAL(true))}
                className='Outline ButtonInfoDrop'
              />
            ))}
      </DashboardHeader>
      {activeLocation.AllowedServiceFeatures.includes(
        'show_marketing_results'
      ) ||
      (activeLocation.AllowedServiceFeatures.includes('email_marketing_pcs') &&
        (emailMarketingTrialDate !== null ||
          emailMarketingTrialDateStatus ===
            EMAIL_MARKETING_TRIAL_DATE_EXPIRED)) ||
      spinner ? (
        <ResultPage />
      ) : (
        <UpsellPage />
      )}
      <MarketingContactUsModal openModal={isOpenModal} />
      <InformationModal openModal={isOpenInformationModal} />
    </DashboardContent>
  );
};

export default EmailMarketing;
